import React from 'react';
import { useState, useEffect } from 'react';
import './whatsappPage.css'
import cellphone_ellipse from '../../image/Cellphone_ellipse.png';
import { FaRegCopy } from "react-icons/fa";
import Alert from '@mui/material/Alert';
import cellphoneGif from '../../image/Pedidocelular.gif'
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from 'react-router-dom';
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import Modal from '@mui/material/Modal';
import whatsapp_logo from '../../image/whatsapp_logo.png'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import disparomensagens from '../../image/disparodemensagnes.png';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import api from "../../service/api";
import { useNavigate } from "react-router-dom";

const WhatsappPage = () => {
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [openValidatorPage, setOpenValidatorPage] = useState(false)
    const [statusEvolution, setStatusEvolution] = useState('')
    const [qrcodeEvolution, setQrcodeEvolution] = useState('')
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    const navigate = useNavigate();

    const textWhatsapp = `Olá tudo bem? \nFaça seu pedido através do link abaixo 🔽 \nPara consultar os PRODUTOS e VALORES acesse o link abaixo: \n ${currentUser.db == 'expresso_gas_' ? 'https://whatsapp.expressogasbelem.com.br/' : currentUser.db == 'rjaragua_comercio_de_gas_ltda' ? 'https://whatsapp.consigazdiskgas.com.br/' : currentUser.db == 'buritis_gas_e_conveniencia' ? `https://whatsapp.buritisgas.com.br/` : currentUser.db == 'amaral_gas' ? `http://whatsapp.amaralgas.com.br/` : currentUser.db == 'largasvital_' ? `https://whatsapp.largasvital.com.br/` : `https://whatsapp.clubedorevendedordegas.com.br/${currentUser.db}`} \nAgradecemos desde já 😊`;

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key.module)
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            if (typePlan == 'free') {
                api
                    .get("/TodosUsuariosRevenda")
                    .then((response) => {
                        response.data.map(key => {
                            if (key['db_name'] == currentUser.db) {
                                setTypePlan(key['module'])
                            }
                        })
                    })
                    .catch((err) => {
                        console.error("ops! ocorreu um erro" + err);
                    });
            }
        }, 20000);
        return () => clearInterval(interval);
    }, [typePlan]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (typePlan == 'free') {
                setModalAvisoPlan(true)
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [typePlan])

    useEffect(() => {
        axios.get(`https://evolution.clubedorevendedordegas.com.br/instance/connectionState/${currentUser.db}`,
            {
                'headers': {
                    apikey: 'B6D711FCDE4D4FD5936544120E713976'
                }
            }
        )
            .then(response => {
                setStatusEvolution(response.data.instance.state)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(`https://evolution.clubedorevendedordegas.com.br/instance/connect/${currentUser.db}`,
            {
                'headers': {
                    apikey: 'B6D711FCDE4D4FD5936544120E713976'
                }
            }
        )
            .then(response => {
                if (typePlan !== 'free') {
                    setQrcodeEvolution(response.data.base64)
                }
            }).catch(err => {
                console.log(err)
            })
    }, [statusEvolution])



    function copiarTexto() {
        let textoCopiado = document.getElementById("inputTextWhatsapp").value;
        // let btnCopy = document.getElementById("btnCopy");
        // let btnCopyValue = document.getElementById("btnCopyValue");
        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    function copiarLink() {
        let textoCopiado = document.getElementById("inputLinkWhatsapp").value;
        // let btnCopy = document.getElementById("btnCopy");
        // let btnCopyValue = document.getElementById("btnCopyValue");
        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('Texto copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    const handleClose = () => {
        setOpenValidatorPage(false)
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        height: 360,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        width: 607,
        bgcolor: '#3FAF7A',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalValidatorPage(props) {
        return (
            <Modal
                open={openValidatorPage}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='divImageModalValidatorPage'>
                        <img src={whatsapp_logo} className='imgModalValidatorPage' />
                    </div>
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage'>
                            Já fez a configuração do seu link de pedidos via Whatsapp?
                        </span>
                        <div className='containerBtnContectValidatorPage'>
                            <div className='divBtnContectValidatorPage'>
                                <button className='btnContectModalValidatorPage'>Sim</button>
                            </div>
                            <div className='divBtnContectValidatorPage'>
                                <button className='btnContectModalValidatorPage'>Não</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        )
    }

    const style2 = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <ModalValidatorPage />
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='dashClubContainer' style={{ padding: 0 }}>
                <div className='divContanctWhatsapp'>
                    <div className='divTextColumn'>
                        <span className='spanMentoriaBanner'>Seus pedidos via Whatsapp
                            agora serão organizados.</span>
                        <span className='spanRunningText'>Se o cliente precisa comprar gás, a primeira coisa que ele faz é pesquisar no Google.</span>
                        <div className='divBtnPageOrdered'>
                            <a href={currentUser.db == 'expresso_gas_' ? 'https://whatsapp.expressogasbelem.com.br/' : currentUser.db == 'rjaragua_comercio_de_gas_ltda' ? 'https://whatsapp.consigazdiskgas.com.br/' : currentUser.db == 'buritis_gas_e_conveniencia' ? `https://whatsapp.buritisgas.com.br/` : currentUser.db == 'amaral_gas' ? `http://whatsapp.amaralgas.com.br/` : currentUser.db == 'largasvital_' ? `https://whatsapp.largasvital.com.br/` : `https://whatsapp.clubedorevendedordegas.com.br/${currentUser.db}`} className='btnPageOrdered' style={{ padding: "8px 0" }} target='_blank'>Ver minha página de compra</a>
                        </div>
                    </div>
                    <div className='divImageToEllipse'>
                        <img className='imgCellphone' src={cellphone_ellipse} />
                    </div>
                </div>
                <div className='divContanctWhatsappBallumn twelve-step'>
                    <div className='divInputLinkWhatsapp'>
                        <input type='text' className='inputLinkWhatsapp' id='inputLinkWhatsapp' defaultValue={currentUser.db == 'expresso_gas_' ? 'https://whatsapp.expressogasbelem.com.br/' : currentUser.db == 'rjaragua_comercio_de_gas_ltda' ? 'https://whatsapp.consigazdiskgas.com.br/' : currentUser.db == 'buritis_gas_e_conveniencia' ? `https://whatsapp.buritisgas.com.br/` : currentUser.db == 'amaral_gas' ? `http://whatsapp.amaralgas.com.br/` : currentUser.db == 'largasvital_' ? `https://whatsapp.largasvital.com.br/` : `https://whatsapp.clubedorevendedordegas.com.br/${currentUser.db}`} disabled />
                        <button className='btnCopyLink' onClick={() => { copiarLink() }}>
                            <FaRegCopy className='icoCopyLink' />
                        </button>
                    </div>
                    <div className='divTextContanctWhatsapp'>
                        <span className='spanContanctWhatsapp'>Copie seu link e cofigure seu Whatsapp agora mesmo.
                            Confira o passo a passo para realizar a configuração</span>
                    </div>
                </div>
                <div className='divPassToPass'>
                    <span className='titlePassToPass'>Passo a Passo</span>
                    <span className='spanPasstoPass'>
                        1. Toque no ícone <PiDotsThreeOutlineVerticalFill className='icoThreeDot' />
                        <br />
                        2. Toque em <b>Ferramentas comerciais</b> e, em seguida, em <b>Mensagem de saudação</b>.
                        <br />
                        3. Ative a opção <b>Enviar mensagem de saudação</b>.
                        <br />
                        4. Toque em Mensagem de saudação para editar a mensagem e, em seguida, toque em OK.
                        <br />
                        5. Toque em Destinatários e escolha uma das seguintes opções:
                        <br />
                        6. Todos: para enviar a mensagem de saudação a todas as pessoas que enviarem mensagens para a empresa.
                    </span>
                    <div className='containerMessageRecomendate'>
                        <div className='divMessageRecomendate'>
                            <span className='spanTitleMessageRecomendate'> Mensagens que recomendamos que seja usada:</span>
                            <div className='divTextWhatsapp'>
                                <textarea className='inputTextWhatsapp' id='inputTextWhatsapp' defaultValue={textWhatsapp} disabled />
                                <button className='btnCopyLink' onClick={() => { copiarTexto() }}>
                                    <FaRegCopy className='icoCopyLink' />
                                </button>
                            </div>
                        </div>
                        <div>
                            <img src={cellphoneGif} className='imgCellPhoneGif' />
                        </div>
                    </div>
                    <div className='containerCreateProductWhatsapp'>
                        <span className='spanCreteProduct'>Etapa de cadastro de produtos</span>
                        <span className='spanDetailsCreateProduct'>Sua página de produtos já está pronta e configurada, e agora?
                            Basta configurar seus produtos que vão aparecer na página de compra para os seus clientes.</span>
                        <div className='divBtnRedirectCreateProduct'>
                            <Link to='/produtos' className='btnRedirectCreateProduct'>Cadastrar produtos</Link>
                        </div>
                    </div>

                </div>
                <div className='containerCinzinhaDoWallinton'>
                    <div className='containerConnectedWhatsapp'>
                        <div className='divQrcodeWhatsapp' style={qrcodeEvolution == '' || qrcodeEvolution == undefined ? { justifyContent: 'center' } : { justifyContent: 'start' }}>
                            <span className='spanStatusInstancia'>Status da Instância:</span>
                            <div className='divStatusEvolution' style={statusEvolution == 'open' ? { background: '#005B2F' } : { background: '#EE0F0F' }}>
                                <span className='spanStatusEvolution'>{statusEvolution == 'open' ? 'CONECTADO' : 'NÃO CONECTADO'}</span>
                            </div>
                            <img className='qrcodeEvolution' src={qrcodeEvolution} />
                        </div>
                        <div className='divDescriptionConnectedWhatsapp'>
                            <span className='spanMentoriaBanner' style={{ fontSize: 40, marginBottom: 10 }}>Conecte seu whatsapp para envios de mensagens</span>
                            <br />
                            <br />
                            <span className='titlePassToPass'>Passo a Passo</span>
                            <br />
                            <span className='spanPasstoPass'>
                                1. Abra seu whatsapp e vá em <span style={{ fontWeight: 700 }}>Dispositivos Conectados {">"} Conectar Dispositivos</span>
                                <br />
                                2. Leia esse QR code ao lado para conectar.
                                <br />
                                3. A ativação dando certo, o Status vai mudar para conectado
                                <br />
                                4. Tudo ok? Agora você já pode utilizar nossas ferramentas para enviar suas mensagens
                            </span>
                        </div>
                    </div>

                    <div className='divImgDisparoMessagens'>
                        <img src={disparomensagens} className='imgDisparoMessagens' />
                    </div>
                    <div className='footerWhatsappPage'>
                        <span className='spanWhatsappPage'>Aproveite todas as possibilidades de automações </span>
                    </div>
                </div>

            </div >
        </>
    );
}

export default WhatsappPage