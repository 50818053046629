import { useState, useEffect, useRef } from "react";
import React from 'react'
import './editImage.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AiFillLeftCircle, AiOutlineDownload, AiOutlineCopy } from "react-icons/ai"
import ultraLogo from '../../image/ultragaz-logo-0.png'
import liguiLogo from '../../image/liquigas-logo-00.png'
import copaLogo from '../../image/copagaz-logo-0.png'
import nacionalLogo from '../../image/nacional-gas-4096.png'
import superLogo from '../../image/supergasbras-logo-0.png'
import consiLogo from '../../image/consigaz.png'
import html2canvas from "html2canvas"
import InputLabel from '@mui/material/InputLabel';
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { genereteImage } from '../../redux/genereteImage/actions';
import { Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import maskImage from '../../image/overlay_gerarimagem.png'
import whatsappImg from '../../image/whatsapp (logo).png'

const EditImage = () => {

    const [open, setOpen] = useState(false);
    const [imageGas, setImageGas] = useState();
    const [color, setColor] = useState('#4a4a4a');
    const [imgUrl, setImgUrl] = useState()
    const [typeArt, setTypeArt] = useState();
    const [empresa, setEmpresa] = useState('Neutra');
    const [nameFile, setNameFile] = useState();
    const [post, setPost] = useState('');
    const [logo, setLogo] = useState();
    const [reseller, setReseller] = useState([]);
    const [urlCopagas, setUrlCopasgas] = useState();
    const [urlNeutro, setUrlNeutro] = useState();
    const [urlLiquigaz, setUrlLiquigaz] = useState();
    const [urlNascionalgas, setUrlNascionalgas] = useState();
    const [urlSupergasbras, setUrlSupergasbras] = useState();
    const [urlUltragaz, setUrlUltragaz] = useState();
    const [urlConsigaz, setUrlConsigaz] = useState();
    const [userReseller, setUserReseller] = useState([])
    const [countDownload, setCountDownload] = useState(0)
    const [perfilReseller, setPerfilReseller] = useState([])
    const [resellerType, setResellerType] = useState('');
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [modalAvisoMax, setModalAvisoMax] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const [divAddress, setDivAddress] = React.useState(true);
    const [divPhone, setDivPhone] = React.useState(true)
    const [whatsapp, setWhatsapp] = React.useState('')

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { currentGenerete } = useSelector(rootReducer => rootReducer.genereteReducer);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    useEffect(() => {
        if (currentGenerete !== undefined) {
            setImgUrl(currentGenerete.urlImage)
            setTypeArt(currentGenerete.typeArt)
        }
    }, [])

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    useEffect(() => {
        if (countDownload >= 5 && typePlan == 'free') {
            setModalAvisoPlan(true)
        } else if (countDownload >= 30) {
            setModalAvisoMax(true)
        }
    }, {})

    useEffect(() => {
        api
            .get("/Configuracoes/" + currentUser.db)
            .then((response) => {
                setLogo(response.data[0].logoMenu)
                const toDataURL = (url) =>
                    fetch(url)
                        .then((response) => response.blob())
                        .then(
                            (blob) =>
                                new Promise((resolve, reject) => {
                                    const reader = new FileReader();
                                    reader.onloadend = () => resolve(reader.result);
                                    reader.onerror = reject;
                                    reader.readAsDataURL(blob);
                                })
                        ).catch(err => {
                            console.log(err)
                        })

                toDataURL(
                    String(response.data[0].logoMenu)
                ).then((dataUrl) => {
                    console.log("RESULT:", dataUrl);
                    document.getElementById('imgRenderLogo').src = dataUrl
                });
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                setReseller(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key.db_name == currentUser.db) {
                        setCountDownload(key.count_download_image)
                        setResellerType(key.type_reseller)
                        setTypePlan(key.module)
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        Axios
            .get("https://apiadmin.clubedorevendedordegas.com.br/TodasCategorias")
            .then((response) => {
                response.data.map(key => {
                    if (key['id'] == localStorage.getItem('typeArt')) {
                        setTypeArt(key['name']);
                    }
                })
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        Axios
            .get("https://apiadmin.clubedorevendedordegas.com.br/TodasArtes")
            .then((response) => {
                let name = '';
                response.data.map(key => {
                    if (resellerType == 'Ultragaz') {
                        if (key['ultragaz_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Liquigás') {
                        if (key['liquigas_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Supergasbras') {
                        if (key['supergasbras_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Copagaz') {
                        if (key['copagaz_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Consigaz') {
                        if (key['consigaz_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Nacional Gás') {
                        if (key['nacionalGas_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    } else if (resellerType == 'Independente') {
                        if (key['independente_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                            console.log(currentGenerete.urlImage)
                        }
                    } else if (resellerType == 'NGC') {
                        if (key['nacionalGas_url'] == currentGenerete.urlImage) {
                            var linhas = key['post'].split('<br /> ');
                            setPost(linhas.join('\n \n'));
                            name = key['name']
                        }
                    }

                })
                response.data.map(key => {
                    if (key['name'] == name) {
                        if (key['ultragaz_url'] !== null) {
                            setUrlUltragaz(key['ultragaz_url'])
                        }
                        if (key['copagaz_url'] !== null) {
                            setUrlCopasgas(key['copagaz_url'])
                        }
                        if (key['liquigas_url'] !== null) {
                            setUrlLiquigaz(key['liquigas_url'])
                        }
                        if (key['nacionalGas_url'] !== null) {
                            setUrlNascionalgas(key['nacionalGas_url'])
                        }
                        if (key['supergasbras_url'] !== null) {
                            setUrlSupergasbras(key['supergasbras_url'])
                        }
                        if (key['independente_url'] !== null) {
                            setUrlNeutro(key['independente_url'])
                        }
                        if (key['consigaz_url'] !== null) {
                            setUrlConsigaz(key['consigaz_url']);
                        }
                    }
                })
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [resellerType]);

    useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                setUserReseller(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key.db_name == currentUser.db) {
                        setCountDownload(key.count_download_image)
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get("/TodosPerfilRevendas/" + currentUser.db)
            .then((response) => {
                setPerfilReseller(response.data[0])
                setWhatsapp(response.data[0]['whatsapp'].replace('(', '').replace(')', '').replace('-', '').replace(' ', '').replace(' ', '').replace('_', ''))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    function retira_acentos(str) {

        var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

        var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
        let novastr = "";
        for (var i = 0; i < str.length; i++) {
            let troca = false;
            for (var a = 0; a < com_acento.length; a++) {
                if (str.substr(i, 1) == com_acento.substr(a, 1)) {
                    novastr += sem_acento.substr(a, 1);
                    troca = true;
                    break;
                }
            }
            if (troca == false) {
                novastr += str.substr(i, 1);
            }
        }
        return novastr;
    }

    useEffect(() => {
        if (post !== '') {
            let postFormated = post.replace('{nome_da_revenda}', perfilReseller['reseller_name']).replace('{endereço_da_revenda}', perfilReseller['address'] + ', ' + perfilReseller['number'] + ' - ' + perfilReseller['neighborhood']).replace('{tag_revenda}', '#' + retira_acentos(perfilReseller['reseller_name'].replace(' ', '').toLowerCase())).replace('{tag_cidade_revenda}', '#' + retira_acentos(perfilReseller['city'].replace(' ', '').toLowerCase()))

            if (perfilReseller['phone'] !== null && perfilReseller['phone'] !== '') {
                if (perfilReseller['phone'] !== null && perfilReseller['phone'] !== '') {
                    postFormated = postFormated.replace('{telefone_da_revenda}', perfilReseller['phone'] + ' / ' + perfilReseller['whatsapp'])
                } else {
                    postFormated = postFormated.replace('{telefone_da_revenda}', perfilReseller['phone'])
                }
            }

            if (perfilReseller['distributor'] !== 'Independente') {
                postFormated = postFormated.replace('{tag_marca_revenda}', '#' + retira_acentos(perfilReseller['distributor'].replace(' ', '').toLowerCase()))
            } else {
                postFormated = postFormated.replace('{tag_marca_revenda}', '')
            }

            setPost(postFormated)
        }
    }, [post])



    function copiarTexto() {
        let textoCopiado = document.getElementById("postArea").value;
        let btnCopy = document.getElementById("btnCopy");
        let btnCopyValue = document.getElementById("btnCopyValue");
        navigator.clipboard.writeText(textoCopiado).then(() => {
            btnCopy.style.setProperty('background-color', 'green', 'important');
            btnCopy.style.setProperty('border', '1px solid green', 'important');
            btnCopy.style.width = '195px';
            btnCopyValue.textContent = 'Postagem copiado!'

            setTimeout(() => {
                btnCopy.removeAttribute('style');
                btnCopyValue.textContent = 'Copiar postagem'
            }, 3000)
        })
    }

    const exportAsImage = async (el, imageFileName) => {
        document.getElementsByClassName('parent')[0].style.display = 'block';

        const canvas = await html2canvas(el, {
            logging: true,
            letterRendering: 1,
            allowTaint: false,
            useCORS: true
        });
        const image = canvas.toDataURL("image/png");
        downloadImage(image, imageFileName);
    };

    const downloadImage = (blob, fileName) => {
        const fakeLink = window.document.createElement("a");
        fakeLink.style = "display:none;";
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
        document.getElementsByClassName('parent')[0].style.display = 'none';
        document.getElementsByClassName('whiteScreen')[0].style.display = 'none';
        setTimeout(function () {
            setOpen(false)
        }, 2000);
    };

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const exportRef = useRef();

    const handleCountImage = () => {
        if (currentUser.db !== 'clubeApi') {
            userReseller.map(key => {
                if (key['db_name'] === currentUser.db) {
                    api.put('/AtualizarUsuarioRevenda', {
                        id: key['id'],
                        count_download_image: Number(key['count_download_image']) + 1
                    })
                }
            })
        }
        setOpen(true)

        document.getElementsByClassName('whiteScreen')[0].style.display = 'block';
        const file = typeArt + "-" + empresa + "-" + date + "-" + month + "-" + year
        setTimeout(function () {
            exportAsImage(exportRef.current, file)
        }, 2000);
    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    const handleDisableAddress = (e) => {
        var address = e.target.checked

        if (address) {
            setDivAddress(false)
        } else {
            setDivAddress(true)
        }
    }

    const handleDisablePhone = (e) => {
        var address = e.target.checked

        if (address) {
            setDivPhone(false)
        } else {
            setDivPhone(true)
        }
    }

    return (
        <>
            <ModalAvisoPlan />
            <Loading />
            <div className='orderedContainerGeratorImage'>
                <div className="titleAndButton">
                    <div style={{ width: '100%' }}>
                        <Link to="/gerar-imagem" onClick={() => {
                            dispatch(genereteImage({ urlImage: null, typeArt: null }))
                        }} style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar</Link>
                    </div>
                </div>
                <Card className="cardContainerGeratorImage cardContainerEditImage">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                        <div className="divToImageEdit">
                            <div className="divToImageEditGas">
                                {resellerType == 'Ultragaz' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(ultraLogo);
                                        // setColor('blue')
                                        setEmpresa('Ultragaz')
                                        setImgUrl(urlUltragaz)
                                    }}>Ultragaz</button>
                                ) : resellerType == 'Liquigás' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(liguiLogo);
                                        setEmpresa('Liquigaz')
                                        setImgUrl(urlLiquigaz)
                                    }}>Liquigás </button>
                                ) : resellerType == 'Supergasbras' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(superLogo);
                                        setEmpresa('Supergasbras')
                                        setImgUrl(urlSupergasbras)
                                    }}>Supergasbras</button>
                                ) : resellerType == 'Copagaz' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(copaLogo);
                                        setEmpresa('Copagaz')
                                        setImgUrl(urlCopagas)
                                    }}>Copagaz</button>
                                ) : resellerType == 'Consigaz' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(consiLogo);
                                        setEmpresa('Consigaz')
                                        setImgUrl(urlConsigaz)
                                    }}>Consigaz</button>
                                ) : resellerType == 'Nacional Gás' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(nacionalLogo);
                                        setEmpresa('Nacionalgas')
                                        setImgUrl(urlNascionalgas)
                                    }}>Nacionalgás</button>
                                ) : resellerType == 'NGC' ? (
                                    <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                        setImageGas(nacionalLogo);
                                        setEmpresa('Nacionalgas')
                                        setImgUrl(urlNascionalgas)
                                    }}>Nacionalgás</button>
                                ) : (
                                    <>
                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(ultraLogo);
                                            // setColor('blue')
                                            setEmpresa('Ultragaz')
                                            setImgUrl(urlUltragaz)
                                        }}>Ultragaz</button>
                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(liguiLogo);
                                            setEmpresa('Liquigaz')
                                            setImgUrl(urlLiquigaz)
                                        }}>Liquigás </button>

                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(copaLogo);
                                            setEmpresa('Copagaz')
                                            setImgUrl(urlCopagas)
                                        }}>Copagaz</button>

                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(nacionalLogo);
                                            setEmpresa('Nacionalgas')
                                            setImgUrl(urlNascionalgas)
                                        }}>Nacionalgás</button>

                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(superLogo);
                                            setEmpresa('Supergasbras')
                                            setImgUrl(urlSupergasbras)
                                        }}>Supergasbras</button>

                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas(consiLogo);
                                            setEmpresa('Consigaz')
                                            setImgUrl(urlConsigaz)
                                        }}>Consigaz</button>

                                        <button className="me-2 button" style={{ margin: 10 }} onClick={() => {
                                            setImageGas();
                                            setEmpresa('Neutra')
                                            setImgUrl(urlNeutro)
                                        }}>Neutra</button>
                                    </>
                                )}
                                <div className="divBtnDisabledPhoneAddress">
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox onClick={handleDisableAddress} />} label="Desabilitar Endereço" />
                                        <FormControlLabel control={<Checkbox />} onClick={handleDisablePhone} label="Desabilitar Telefone" />
                                    </FormGroup>
                                </div>
                            </div>
                            <div>
                                <div className="divMaskImage" style={{ backgroundImage: `url(${maskImage})` }}></div>
                                <div className="divImgToEdit">
                                    <img
                                        src={imgUrl}
                                        loading="lazy"
                                        className="imgToEdit"
                                    />
                                    <div style={{ background: 'rgba(255,255,255)', height: 103, position: 'relative', bottom: 102, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                        <div style={{ width: 160, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={imageGas} className="imgGasToEditImage"></img>
                                        </div>
                                        <div style={{ width: 235, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                            <div style={divAddress ? { display: 'flex' } : { display: 'none' }}>
                                                <span style={{ color: color, fontSize: 15, textAlign: 'center' }}>{perfilReseller['address'] + ', ' + perfilReseller['number']}</span>
                                            </div>
                                            <div style={divPhone ? { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 5 } : { display: 'none' }}>
                                                <img src={whatsappImg} width={20} height={20} />
                                                <InputMask style={{ backgroundColor: 'transparent', color: color, border: 'none', width: 127, cursor: 'auto', textAlign: 'center' }} mask={whatsapp.length == 11 ? "(99) 9 9999-9999" : "(99) 9999-9999"} value={perfilReseller['whatsapp']} disabled />
                                            </div>

                                        </div>
                                        {currentUser.db == 'atagas_comercio_de_gas_ltda' ? (
                                            <div style={{ width: 160, height: 80, display: 'flex', justifyContent: 'center' }}>
                                                <img src={logo} className="imgLogoToEditImage" style={{ width: '100%' }} />
                                            </div>
                                        ) : (
                                            <div style={{ width: 160, height: 80, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <img src={logo} className="imgLogoToEditImage" style={{ width: '100%' }} />
                                            </div>
                                        )}

                                    </div>
                                    <div className="divToBtnEditImage" style={countDownload >= 5 && typePlan == 'free' ? { display: 'none' } : countDownload >= 30 ? { display: 'none' } : { display: 'flex' }}>
                                        <button className="me-2 button" onClick={handleCountImage}><AiOutlineDownload /> Baixar imagem</button>
                                        <button className="me-2 button" id="btnCopy" onClick={() => { copiarTexto() }}><AiOutlineCopy /> <span id="btnCopyValue">Copiar postagem</span></button>

                                    </div>
                                </div>

                            </div>
                            <div className="divTextareaToEditImage">
                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10, marginTop: 35, fontWeight: 'bold' }} >Texto recomendado para postagem </InputLabel>
                                <textarea rows="4" cols="50" value={post} className="textAreaToImgEdit" id="postArea" disabled />
                            </div>
                        </div>
                        <div className="parent" style={{ display: 'none' }}>
                            <div ref={exportRef} style={{ width: 1080, height: 1080 }}>
                                <img
                                    src={imgUrl}
                                    width={1080}
                                    height={1080}
                                />
                                <div style={{ height: 183, position: 'relative', bottom: 183, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                    <div style={{ width: 239, height: 87, display: 'flex', justifyContent: 'center', marginRight: 45 }}>
                                        <img src={imageGas}></img>
                                    </div>
                                    <div style={{ width: 367, height: 128, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <div style={divAddress ? { display: 'block' } : { display: 'none' }}>
                                            <span style={{ color: color, fontSize: 24 }}>{perfilReseller['address'] + ', ' + perfilReseller['number']}</span>
                                        </div>
                                        <div style={divPhone ? { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 5 } : { display: 'none' }}>
                                            <img src={whatsappImg} width={25} />
                                            <InputMask style={{ backgroundColor: 'transparent', color: color, border: 'none', width: 245, fontSize: 24, cursor: 'auto', textAlign: 'center' }} mask={whatsapp.length == 11 ? "(99) 9 9999-9999" : "(99) 9999-9999"} value={perfilReseller['whatsapp']} disabled />
                                        </div>

                                    </div>
                                    <div style={{ width: 300, height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={logo} width={'100%'} id='imgRenderLogo' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="whiteScreen" style={{ background: 'white', width: '200%', height: '200%', zIndex: '999', position: 'absolute', display: 'none' }}>

                        </div>
                    </div>


                </Card>
            </div >
        </>
    )
}

export default EditImage