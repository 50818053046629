import { useEffect, useState } from 'react';
import React from 'react'
import './header.css'
import { Navbar, Container, Form } from 'react-bootstrap';
import { FiBell } from "react-icons/fi";
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import audio from '../../sound/Bell-Ding-Sound-Effect-_download_.mp3'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { userLogout } from '../../redux/user/actions';
import { accordionClick } from '../../redux/accordion/actions'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from '../../image/clube-do-gas.png'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { GiHamburgerMenu } from "react-icons/gi";
import { StayCurrentLandscapeOutlined } from '@material-ui/icons';
import { useTour } from '@reactour/tour'
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';

const Header = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let user_id = '';
    if (currentUser !== undefined || currentUser !== null) {
        user_id = currentUser.id;
    }

    const navigate = useNavigate();

    const [userName, setUserName] = useState('');
    const [photo, setPhoto] = useState('');
    const [count, setCount] = useState('');
    const sound = document.querySelector('audio')
    const [urlAtual, setUrlAtual] = useState(window.location.pathname)
    const [widthWindow, setWidthWindo] = useState(window.innerWidth)
    const [menuGestao, setMenuGestao] = useState(false);
    const [mobile, setMobile] = useState(false)
    const [greeting, setGreeting] = useState('Bom dia')
    const [openGestao, setOpenGestao] = React.useState('none');
    const [openMarketing, setOpenMarketing] = React.useState('none');
    const [openVendas, setOpenVendas] = React.useState('none');
    const [openRevenda, setOpenRevenda] = React.useState('none')
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const [show, setShow] = useState(false);

    const handleShow = () => {
        if (typePlan !== 'free') {
            setShow(true)
        } else {
            setModalAvisoPlan(true)
        }
    };

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    const handleClickGestao = (event) => {
        if (openGestao == 'none') {
            setOpenGestao('flex');
            var element = document.getElementById("subMenuGestao");
            element.classList.remove("hide");
        } else {
            setOpenGestao('none');
            var element = document.getElementById("subMenuGestao");
            element.classList.add("hide");
        }

    };

    const handleClickMarketing = (event) => {
        if (openMarketing == 'none') {
            setOpenMarketing('flex');
            var element = document.getElementById("subMenuMarketing");
            element.classList.remove("hide");
        } else {
            setOpenMarketing('none');
            var element = document.getElementById("subMenuMarketing");
            element.classList.add("hide");
        }

    };

    const handleClickVendas = (event) => {
        if (openVendas == 'none') {
            setOpenVendas('flex');
            var element = document.getElementById("subMenuVendas");
            element.classList.remove("hide");
        } else {
            setOpenVendas('none');
            var element = document.getElementById("subMenuVendas");
            element.classList.add("hide");
        }

    };

    const handleClickRevenda = (event) => {
        if (openRevenda == 'none') {
            setOpenRevenda('flex');
            var element = document.getElementById("subMenuRevenda");
            element.classList.remove("hide");
        } else {
            setOpenRevenda('none');
            var element = document.getElementById("subMenuRevenda");
            element.classList.add("hide");
        }

    };

    var dateNow = new Date().getHours();
    let newPedido = 0;
    const { setIsOpen, currentStep, steps } = useTour()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openTour, setOpenTour] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuGestao(false)
    };
    const dispatch = useDispatch();


    useEffect(() => {
        if (dateNow >= 0 && dateNow <= 12) {
            setGreeting('Bom dia')
        } else if (dateNow >= 13 && dateNow <= 17) {
            setGreeting('Boa tarde')
        } else if (dateNow >= 18 && dateNow <= 23) {
            setGreeting('Boa noite')
        }
    }, [])

    const MINUTE_MS2 = 3600000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (dateNow >= 0 && dateNow <= 12) {
                setGreeting('Bom dia')
            } else if (dateNow >= 13 && dateNow <= 17) {
                setGreeting('Boa tarde')
            } else if (dateNow >= 18 && dateNow <= 23) {
                setGreeting('Boa noite')
            }
        }, MINUTE_MS2);
        return () => clearInterval(interval);
    }, [])

    setInterval(() => {
        newPedido = 0;
        api.get("/TodosPedidos2" + '/' + currentUser.db).then((response) => {
            response.data.map(key => {
                if (key['status'] == 'Recebido') {
                    newPedido++
                }
            })
            if (newPedido >= 1) {
                sound.play()
            }
        })

    }, 20000);

    useEffect(() => {
        api
            .get("/Colaborador/" + user_id + '/' + currentUser.db)
            .then((response) => {
                let name = response.data[0].name.split(' ')

                setUserName(name[0])
                setPhoto(response.data[0].photo)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        newPedido = 0;
        api.get("/TodosPedidos2" + '/' + currentUser.db).then((response) => {
            response.data.map(key => {
                if (key['status'] == 'Recebido') {
                    newPedido++
                }
            })
            if (newPedido >= 1) {
                sound.play()
            }
            // if (newPedido > 0) {
            //     setCount(newPedido)
            // } else {
            //     setCount(0)
            // }

        })
    }, [])

    // setInterval(() => {
    //     if (count >= 1) {
    //         sound.play()
    //     }
    // }, 10000)

    const MINUTE_MS = 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            setUrlAtual(window.location.pathname)
            setWidthWindo(window.innerWidth)
            if (widthWindow > 500) {
                setMobile(false)
            } else {
                setMobile(true)
            }
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <Navbar className='Navbar' expand="lg">
                <audio src={audio} />
                <Container fluid>
                    <div className="d-flex">
                        <div className='divImgHeader'>
                            <Link to='/'>
                                <img src={logo} className='logoHeader' />
                            </Link>
                        </div>
                        {!mobile ? (
                            <>
                                <div className='divHeaderBtn'>
                                    < Dropdown >
                                        <Dropdown.Toggle className='userContainer-menu seventh-step' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader'>Gestão e Vendas</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }} className='eight-step-menu'>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv ten-step'>
                                                <Link className='linkSubMenu' to="/dashboard-gestao" onClick={e => {
                                                    document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                }}>Visão geral</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv ten-step'>
                                                {typePlan !== 'free' ? (
                                                    <Link className='linkSubMenu' to="/relatorios" onClick={e => {
                                                        document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                    }}>Relatórios</Link>
                                                ) : (
                                                    <Link className='linkSubMenu' onClick={e => {
                                                        setModalAvisoPlan(true)
                                                    }}>Relatórios</Link>
                                                )}

                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv eight-step'>
                                                <Link className='linkSubMenu' to="/pedidos" onClick={e => {
                                                    document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                }}>Pedidos</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv nine-step' >
                                                <Link className='linkSubMenu' to="/clientes" onClick={e => {
                                                    document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                }}>Todos os clientes</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/historico-fechamento" onClick={e => {
                                                    document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                }}>Caixa do entregador</Link>
                                            </div>
                                            {/* <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                            <Link className='linkSubMenu' to="/historico-entregadores" onClick={e => {
                                                document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                            }}>Histórico do entregadores</Link>
                                        </div> */}
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/localization-driver" onClick={e => {
                                                    document.querySelectorAll('.eight-step-menu.dropdown-menu.show')[0].className = 'eight-step-menu dropdown-menu'
                                                }}>Localizações</Link>
                                            </div>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                    <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader'>Marketing</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }}>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/gerar-imagem" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Gerar imagem</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/automacao" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Automações</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/cupons" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Cupons Whatsapp/APP</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/aniversariantes-mes" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Aniversariantes do mês</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/minha-revenda-google" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Minha revenda na Google</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/meu-google-ads" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Meus anúncios no Google</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/raio-x" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Raio-x da rede social</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/app-gas-click" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>APP Gás no Click</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/" onClick={e => {
                                                    document.querySelectorAll('.dropdown-menu.show')[0].className = 'dropdown-menu'
                                                }}>Sorteio (Em Breve)</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader fourthStep'>Cadastrar</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }} className='fourth-step-menu'>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv fourth-step'>
                                                <Link className='linkSubMenu' to="/produtos" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Produtos</Link>
                                            </div>
                                            {/* <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                            <Link className='linkSubMenu' to="/servicos" onClick={e => {
                                                document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                            }}>Serviços</Link>
                                        </div> */}
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-produto" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Categoria dos produtos</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/fornecedores" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Fornecedores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-comércio" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Categoria do comércio</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-despesa" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Categoria da despesa</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/forma-pagamento" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Forma de pagamento</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/forma-pagamento-despesa" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Forma de pagamento de despesa</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv eleven-step'>
                                                <Link className='linkSubMenu' t to="/whatsapp" onClick={e => {
                                                    document.querySelectorAll('.fourth-step-menu.dropdown-menu.show')[0].className = 'fourth-step-menu dropdown-menu'
                                                }}>Whatsapp</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader first-step'>Minha Revenda</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }} className='second-step-menu'>


                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/entregadores" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Entregadores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/colaboradores" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Colaboradores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/despesas" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Despesas</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/veiculos" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Veículos</Link>
                                            </div>

                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/setores" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Setores</Link>
                                            </div>

                                            <div style={{ margin: "10px 0" }} className='subMenuDiv second-step'>
                                                <Link className='linkSubMenu' to="/perfil-revenda" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Perfil de revenda</Link>
                                            </div>

                                            <div style={{ margin: "10px 0" }} className='subMenuDiv second-step'>
                                                <Link className='linkSubMenu' to="/motorista-tech" onClick={e => {
                                                    document.querySelectorAll('.second-step-menu.dropdown-menu.show')[0].className = 'second-step-menu dropdown-menu'
                                                }}>Motorista Tech</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Link className='btnMenuHeader' to="/mentorias">Treinamentos</Link>
                                </div>
                                {/* {urlAtual == '/dashboard-gestao' ? (
                                <>
                                    <div className='iconContainer'>
                                        <div className='iconBell'>
                                            <Link to="/pedidos" style={{ color: 'black' }}>
                                                <Badge badgeContent={count} color="primary">
                                                    <FiBell />
                                                </Badge>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='lineVertical'>
                                        <span >|</span>
                                    </div>
                                </>
                            ) : ('')} */}
                                < Dropdown style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {/* <button onClick={() => {
                                    if (steps[0].selector == '.fourth-step') {
                                        if (currentStep == 0) {
                                            document.getElementsByClassName('fourthStep')[0].click()

                                            setTimeout(() => {
                                                document.querySelectorAll('.fourth-step-menu, .dropdown-menu')[0].className = "fourth-step-menu dropdown-menu show"
                                            }, 100);
                                        }
                                    } else if (steps[0].selector == '.eleven-step') {
                                        if (currentStep == 0) {
                                            document.getElementsByClassName('fourthStep')[0].click()

                                            setTimeout(() => {
                                                document.querySelectorAll('.fourth-step-menu, .dropdown-menu')[0].className = "fourth-step-menu dropdown-menu show"
                                            }, 100);
                                        }
                                    } else if (steps[0].selector == '.seventh-step') {
                                        if (currentStep == 0) {
                                            document.getElementsByClassName('seventh-step')[0].click()

                                            setTimeout(() => {
                                                document.querySelectorAll('.eight-step-menu, .dropdown-menu')[0].className = "eight-step-menu dropdown-menu show"
                                            }, 100);
                                        }
                                    }

                                    setTimeout(() => {
                                        setIsOpen(true)
                                    }, 100)
                                }} style={{ backgroundColor: 'transparent' }}>
                                    <AiOutlineQuestionCircle style={{ fontSize: 30, color: '#FAB63D' }} />
                                </button> */}
                                    <Dropdown.Toggle className='userContainer-menu'>
                                        <img className='img' src={photo} />
                                        <div className='divInfoUser'>
                                            <span className='spanInforMornigh'>{greeting}</span>
                                            <span className='spanInforUserName'>{userName}</span>
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ left: -27, paddingLeft: 23 }}>
                                        <div>
                                            <Link className='linkSubMenu' to="/perfil">Meu perfil</Link>
                                        </div>
                                        <div>
                                            <Link className='linkSubMenu' to="/meu-chamados">Meus chamados</Link>
                                        </div>
                                        <div>
                                            <Link className='linkSubMenu' to="/alterar-senha">Alterar Senha</Link>
                                        </div>
                                        <Dropdown.Divider />
                                        <div>
                                            <Link className='linkSubMenu' to='/login' onClick={() => {
                                                dispatch(userLogout({ token: null, id: null, typeCollaborator: null }));
                                                dispatch(accordionClick(false));
                                                // navigate('/', { replace: true });
                                            }} href="/">Sair</Link>
                                        </div>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        ) : (
                            <div>
                                <Button
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <GiHamburgerMenu className='iconHeader' />
                                </Button>
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='userContainer-menu' onClick={handleClickGestao}>
                                            <button className='btnMenuHeader'>Gestão e Vendas</button>
                                        </div>
                                        <div className='subMenuGestao hide' id="subMenuGestao">
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/dashboard-gestao">Visão geral</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                {typePlan !== 'free' ? (
                                                    <Link className='linkSubMenu' to="/relatorios">Relatórios</Link>
                                                ) : (
                                                    <Link className='linkSubMenu' onClick={e => { setModalAvisoPlan(true) }}>Relatórios</Link>
                                                )}

                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/pedidos">Pedidos</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/clientes">Todos os clientes</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/historico-fechamento">Caixa do entregador</Link>
                                            </div>
                                            {/* <div className='subMenuDiv'>
                                            <Link className='linkSubMenu' to="/historico-entregadores">Histórico do entregadores</Link>
                                        </div> */}

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/localization-driver">Localizações</Link>
                                            </div>
                                        </div>
                                    </MenuItem>
                                    <MenuItem style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='userContainer-menu' onClick={handleClickMarketing}>
                                            <button className='btnMenuHeader'>Marketing</button>
                                        </div>
                                        <div className='subMenuMarketing hide' id="subMenuMarketing">
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/gerar-imagem">Gerar imagem</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/automacao">Automações</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/cupons">Cupons Whatsapp/APP</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/aniversariantes-mes">Aniversariantes do mês</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/minha-revenda-google">Minha revenda no Google</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/meu-google-ads">Meus anúncios no Google</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/raio-x">Raio-x da rede social</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/app-gas-click">APP Gás no Click</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/">Sorteio (Em Breve)</Link>
                                            </div>
                                        </div>
                                        {/* <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader'>Marketing</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }}>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/gerar-imagem">Gerar imagem</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/cupons">Cupons Whatsapp/APP</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/aniversariantes-mes">Aniversariantes do mês</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/">Minha revenda no Google</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/">Meus anúncios no Google</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/">Raio-x da rede social</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/">Sorteio (Em Breve)</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    </MenuItem>
                                    <MenuItem style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='userContainer-menu' onClick={handleClickVendas}>
                                            <button className='btnMenuHeader'>Cadastrar</button>
                                        </div>
                                        <div className='subMenuVendas hide' id="subMenuVendas">
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/produtos">Produtos</Link>
                                            </div>
                                            {/* <div className='subMenuDiv'>
                                            <Link className='linkSubMenu' to="/servicos">Serviços</Link>
                                        </div> */}
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-produto">Categoria dos produtos</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/fornecedores">Fornecedores</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-comércio">Categoria do comércio</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-despesa">Categoria da despesa</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/forma-pagamento">Forma de pagamento</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/forma-pagamento-despesa">Forma de pagamento de despesa</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' t to="/whatsapp">Whatsapp</Link>
                                            </div>
                                        </div>
                                        {/* <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader'>Vendas</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }}>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/produtos">Produtos</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/servicos">Serviços</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-produto">Categoria dos produtos</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/fornecedores">Fornecedores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/categoria-comércio">Categoria do comércio</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/forma-pagamento">Forma de pagamento</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' t to="/whatsapp">Whatsapp</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    </MenuItem>
                                    <MenuItem style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className='userContainer-menu' onClick={handleClickRevenda}>
                                            <button className='btnMenuHeader'>Minha Revenda</button>
                                        </div>
                                        <div className='subMenuRevenda hide' id="subMenuRevenda">
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/entregadores">Entregadores</Link>
                                            </div>
                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/colaboradores">Colaboradores</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/despesas">Despesas</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/veiculos">Veículos</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/setores">Setores</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/perfil-revenda">Perfil de revenda</Link>
                                            </div>

                                            <div className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/motorista-tech">Motorista Tech</Link>
                                            </div>
                                        </div>
                                        {/* <Dropdown>
                                        <Dropdown.Toggle className='userContainer-menu' style={{ padding: 0 }}>
                                            <button className='btnMenuHeader'>Minha Revenda</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ left: -27, paddingLeft: 23, width: 225 }}>


                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/entregadores">Entregadores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/colaboradores">Colaboradores</Link>
                                            </div>
                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/veiculos">Veículos</Link>
                                            </div>

                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/setores">Setores</Link>
                                            </div>

                                            <div style={{ margin: "10px 0" }} className='subMenuDiv'>
                                                <Link className='linkSubMenu' to="/perfil-revenda">Perfil de revenda</Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link className='btnMenuHeader' to="/mentorias">Treinamentos</Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                        <Link className='btnMenuHeader' onClick={() => {
                                            handleClose()
                                            dispatch(userLogout({ token: null, id: null, typeCollaborator: null }));
                                            dispatch(accordionClick(false));
                                            navigate('/', { replace: true });
                                        }}>Sair</Link>
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}

                    </div>
                </Container>
            </Navbar >
        </>

    )
}

export default Header