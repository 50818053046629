import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableVehicles.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { TbEyeSearch } from "react-icons/tb";
import { Link } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'veiculo',
    numeric: false,
    disablePadding: false,
    label: 'Veículo',
  },
  {
    id: 'modelo',
    numeric: false,
    disablePadding: false,
    label: 'Modelo',
  },
  {
    id: 'km',
    numeric: false,
    disablePadding: false,
    label: 'KM',
  },
  {
    id: 'pneu',
    numeric: false,
    disablePadding: false,
    label: 'Pneus',
  },
  {
    id: 'fluido',
    numeric: false,
    disablePadding: false,
    label: 'Fluidos',
  },
  {
    id: 'documento',
    numeric: false,
    disablePadding: false,
    label: 'Documentação',
  },
  {
    id: 'create_date',
    numeric: false,
    disablePadding: false,
    label: 'Data de criação',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [debts, setDebts] = React.useState([])
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

  React.useEffect(() => {
    api
      .get("/TodosVeiculos" + '/' + currentUser.db)
      .then((response) => {
        setCollaborators(response.data);
        setRows(response.data.map(key => ({
          id: key['id'], veiculo: key['vehicle'], modelo: key['model'], km: key['km'], date_km: key['date_km'], pneu: key['pneu'], date_pneu: key['date_pneu'], fluido: key['fluido'], date_fluido: key['date_fluido'], documento: key['documents'], obs: key['obs'], driver_id: key['driver_id'], create_date: key['createdAt']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasDespesas" + '/' + currentUser.db)
      .then(response => {
        setDebts(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      vehicle: data.vehicle,
      model: data.modelVehicle,
      km: data.kmVehicle,
      pneu: data.pneuVehicle,
      fluido: data.fluidoVehicle,
      documents: data.docVehicle,
      obs: data.obsVehicle,
      db: currentUser.db
    }

    api.put("/AtualizarVeiculo", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarVeiculo/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    if (painelModal) {
      setTimeout(() => {
        const divVehicleDebts = document.getElementById('divVehicleDebts')
        if (divVehicleDebts.style !== null) {
          divVehicleDebts.style.display = 'none'
        }

        debts.map(key => {
          if (key['category'] == 'veiculo') {
            if (key['bond'] == idModal) {
              divVehicleDebts.style.display = 'block'
            }
          }
        })
      }, 1000)
    }

    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do veiculo</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Veículo</td>
                        <td>{key['vehicle']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Modelo</td>
                        <td>{key['model']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">KM</td>
                        <td>{key['km']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Pneus</td>
                        <td>{key['pneu']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Fluidos</td>
                        <td>{key['fluido']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Documentação</td>
                        <td>{key['documents']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação</td>
                        <td>{key['obs']}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div style={{ width: '100%', display: 'none' }} id='divVehicleDebts'>
                    <div style={{ margin: '20px 0' }}>
                      <span className="labelConfirm">Despesas vinculadas</span>
                    </div>

                    <Table striped bordered hover className="tableProfile" style={{ margin: '0 10px', width: '98%' }}>
                      <thead>
                        <tr>
                          <th scope="col">Nome da despesa</th>
                          <th scope="col">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          debts.map(k => {
                            if (k['category'] == 'veiculo') {
                              if (k['bond'] == idModal) {
                                return (
                                  <tr>
                                    <td className="labelConfirm">{k['name']}</td>
                                    <td>{k['value']}</td>
                                  </tr>
                                )
                              }
                            }
                          })

                        }
                      </tbody>
                      <tfoot>
                        <span>Veja com mais detalhes na área de <Link to='/despesas'>Despesas</Link></span>
                      </tfoot>
                    </Table>
                  </div>
                </>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do colaborador</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Veículo:</td>
                        <td>
                          <input
                            required
                            name="vehicle"
                            className="inputCadastro"
                            defaultValue={key['vehicle']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Modelo:</td>
                        <td>
                          <input
                            required
                            name="modelVehicle"
                            className="inputCadastro"
                            defaultValue={key['model']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Km:</td>
                        <td>
                          <input
                            required
                            name="kmVehicle"
                            className="inputCadastro"
                            defaultValue={key['km']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Pneus:</td>
                        <td>
                          <input
                            required
                            name="pneuVehicle"
                            className="inputCadastro"
                            defaultValue={key['pneu']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Fluidos:</td>
                        <td>
                          <input
                            required
                            name="fluidoVehicle"
                            className="inputCadastro"
                            defaultValue={key['fluido']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Documentação:</td>
                        <td>
                          <input
                            name="docVehicle"
                            className="inputCadastro"
                            defaultValue={key['documents']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação</td>
                        <td>
                          <Form.Control
                            className="textAreaForm"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='obsVehicle'
                            value={key['obs']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir colaborador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o colaborador selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }


  const columns = [
    {
      title: "Data de criação", field: 'create_date', render: (rowData) => {
        let dateFormat = new Date(rowData.create_date).toLocaleString("pt-br")
        let splitDate = dateFormat.split(',')
        return (
          <span>{splitDate[0]}</span>
        )
      }
    },
    { title: "Veículo", field: "veiculo" },
    { title: "Modelo", field: "modelo" },
    { title: "KM", field: "km" },
    {
      title: "Data informada os Km", field: "date_km", render: (rowData) => {
        let date = new Date(rowData.date_km);
        let dateFormated = date.setDate(date.getDate() + 1)
        return (
          <span>{new Date(dateFormated).toLocaleDateString("pt-br")}</span>
        )
      }
    },
    { title: "Pneus", field: 'pneu' },
    {
      title: "Data da troca dos pneus", field: "date_pneu", render: (rowData) => {
        let date = new Date(rowData.date_pneu);
        let dateFormated = date.setDate(date.getDate() + 1)
        return (
          <span>{new Date(dateFormated).toLocaleDateString("pt-br")}</span>
        )
      }
    },
    { title: "Fluidos", field: 'fluido' },
    {
      title: "Data da troca dos fluidos", field: 'date_fluido', render: (rowData) => {
        let date = new Date(rowData.date_fluido);
        let dateFormated = date.setDate(date.getDate() + 1)
        return (
          <span>{new Date(dateFormated).toLocaleDateString("pt-br")}</span>
        )
      }
    },
    { title: "Documentação", field: 'documento' },

  ]

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
          {/* <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${row['id']}`;
                    let dateDelivery = '';
                    if (row.horarioDaEntrega !== null) {
                      dateDelivery = row.horarioDaEntrega
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row['id']}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className="clientTable"
                        >
                          {row.veiculo}
                        </TableCell>

                        <TableCell align="left">
                          {
                            row.modelo
                          }
                        </TableCell>

                        <TableCell align="left">
                          {
                            row.km
                          }
                        </TableCell>

                        <TableCell align="left">
                          {row.pneu}
                        </TableCell>

                        <TableCell align="left">
                          {row.fluido}
                        </TableCell>

                        <TableCell align="left">
                          {row.doc}
                        </TableCell>

                        <TableCell align="left" >
                          <div className="buttonContainer">
                            <Tooltip title="Perfil" className='buttonActionTable' onClick={() => {
                              setPainelModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineUser className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Editar" className='buttonActionTable' onClick={() => {
                              setEditModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineEdit className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Deletar" className='buttonActionTable' onClick={() => {
                              setModalCancel(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineDelete className='perfilAction' />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </>
  );
}
