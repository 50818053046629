import * as React from 'react';
import './tableAutomation.css'
import { MdModeEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';
import api from "../../service/api";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { ptBR } from '@mui/x-data-grid/locales';

const Automation = () => {
  const [automations, setAutomations] = React.useState([])
  const [historicAutomations, setHistoricAutomations] = React.useState([])
  const [idAutomation, setIdAutomation] = React.useState('')
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalReport, setOpenModalReport] = React.useState(false)
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [clientFisicos, setClientFisicos] = React.useState([])
  const [clientJuridico, setClientJuridico] = React.useState([])

  React.useEffect(() => {
    api
      .get('/TodasAutomacoes')
      .then(response => {
        setAutomations(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])


  React.useEffect(() => {
    api
      .get('/TodosHistoricosAutomacao')
      .then(response => {
        setHistoricAutomations(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  React.useEffect(() => {
    api
      .get('/TodosClientesFisicos/' + currentUser.db)
      .then(response => {
        setClientFisicos(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleModalClose = () => {
    setOpenModalEdit(false)
    setOpenModalDelete(false)
    setOpenModalReport(false)
  };

  const submitEditForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      name: data.nameCreateAutomation,
      type: data.typeCreateAutomation,
      public: data.publicCreateAutomation,
      message: data.messageCreateAutomation,
      repeat: data.reptyCreateAutomation,
      db_name: currentUser.db,
      id: idAutomation
    }

    api.put("/AtualizarAutomacao", dados)
      .then(response => {
        api
          .get('/TodasAutomacoes')
          .then(response => {
            setAutomations(response.data)
            handleModalClose()
          })
          .catch(err => {
            console.log(err)
          })
      })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }


  function ModalEditAutomation(props) {
    return (
      <Modal
        show={openModalEdit}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Configuração de mensagem automática
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="inputNotificationMessage" onSubmit={submitEditForm}>
            {automations.map(key => {
              if (key.id == idAutomation) {
                let messageCompleta = key.message.split('\\n').join('\n');

                return (
                  <>
                    <div className="divModalCreateAutomation">
                      <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                        <InputLabel id="demo-multiple-name-label">Tipo de automação</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="typeCreateAutomation"
                          defaultValue={key.type}
                          required
                          disabled={key.type_automation == 'system' ? true : false}
                        >

                          <MenuItem
                            key={'comunicacao'}
                            value={'comunicacao'}
                          >
                            {'Automação de Comunicação'}
                          </MenuItem>
                          <MenuItem
                            key={'pesquisa'}
                            value={'pesquisa'}
                          >
                            {'Automação de Pesquisa'}
                          </MenuItem>

                        </Select>
                      </FormControl>

                      <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                        <InputLabel id="demo-multiple-name-label">Publicação</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          required
                          defaultValue={key.public}
                          name="publicCreateAutomation"
                          disabled={key.type_automation == 'system' ? true : false}
                        >

                          <MenuItem
                            key={'30a39'}
                            value={'30a39'}
                          >
                            {'Clientes que compraram entre 30 e 39 dias'}
                          </MenuItem>
                          <MenuItem
                            key={'40a60'}
                            value={'40a60'}
                          >
                            {'Clientes que compraram entre 40 e 60 dias'}
                          </MenuItem>
                          <MenuItem
                            key={'aniversario'}
                            value={'aniversario'}
                          >
                            {'Aniversariantes'}
                          </MenuItem>
                          <MenuItem
                            key={'semanaAniversario'}
                            value={'semanaAniversario'}
                          >
                            {'Semana de aniversário'}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="divModalCreateAutomation">
                      <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                        <TextField
                          required
                          id="outlined-required"
                          label="Nome da automação"
                          className='divInputEmail'
                          style={{ width: '100%', margin: 0 }}
                          defaultValue={key.name}
                          name="nameCreateAutomation"
                          disabled={key.type_automation == 'system' ? true : false}
                        />
                      </FormControl>

                      {/* <FormControl sx={{ m: 1 }} className='selectFormPayment' style={{ width: '38.7vw' }}>
                        <InputLabel id="demo-multiple-name-label">Repetir para a mesma pessoa</InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          required
                          defaultValue={key.repeat}
                          name="reptyCreateAutomation"
                          disabled={key.type_automation == 'system' ? true : false}
                        >

                          <MenuItem
                            key={'nunca'}
                            value={'nunca'}
                          >
                            {'Nunca'}
                          </MenuItem>
                          <MenuItem
                            key={'sempre'}
                            value={'sempre'}
                          >
                            {'Sempre'}
                          </MenuItem>
                          <MenuItem
                            key={'apos1'}
                            value={'apos1'}
                          >
                            {'Após 1..'}
                          </MenuItem>
                          <MenuItem
                            key={'apos2'}
                            value={'apos2'}
                          >
                            {'Após 2..'}
                          </MenuItem>
                        </Select>
                      </FormControl> */}
                    </div>

                    <div className="divModalCreateAutomation" style={{ flexDirection: 'column', alignItems: 'start', marginLeft: 15 }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Mensagem que será enviada"
                        multiline
                        rows={7}
                        className='divInputEmail'
                        defaultValue={messageCompleta}
                        style={{ width: '99%', margin: "10px 0", height: '100%' }}
                        name="messageCreateAutomation"
                      />
                      <small style={{ color: '#717171' }}>{`Você pode usar algumas váriaveis como: {nome_do_cliente}, {rua_do_cliente}, {numero_do_cliente}, {bairro_do_cliente}, {produtos_da_compra}, {total_da_compra} e {obs_da_compra} `} </small>
                    </div>

                    <button className="buttonSecundary">
                      Enviar
                    </button>
                  </>
                )
              }
            })}
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const handleActiveAutomation = (e, id) => {
    const checked = e.target.checked;
    let activate = 'Ativado';

    if (!checked) {
      activate = 'Desativado'
    }

    const dados = {
      id: id,
      activate: activate,
      db_name: currentUser.db
    }

    api.put("/AtualizarAutomacao", dados)
      .then(response => {
        api
          .get('/TodasAutomacoes')
          .then(response => {
            setAutomations(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      });

    // setOpen(true)
    // setTimeout(function () {
    //   window.location.reload(1);
    // }, 500);
  }

  const handleDeleteAutomation = () => {
    setOpen(true)
    api.delete("/DeletarAutomacao/" + idAutomation)
      .then(response => {
        api
          .get('/TodasAutomacoes')
          .then(response => {
            setAutomations(response.data)
            setOpenModalDelete(false)
            setOpen(false)
          })
          .catch(err => {
            console.log(err)
          })
      });
  }

  function ModalDelete(props) {
    return (
      <Modal
        show={openModalDelete}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir arte
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir a automação? </h4>
          <div className="cancelOrder">
            <button onClick={e => handleDeleteAutomation()} className="cancelButton true">Sim</button>
            <button onClick={handleModalClose} className="cancelButton false">Não</button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalReportAutomation(props) {
    const columns = [
      { field: 'name', headerName: 'Nome' },
      { field: 'phone', headerName: 'Número' },
      { field: 'create_at', headerName: 'Data' },
      { field: 'status', headerName: 'Status' }
    ];

    const rows = [];

    historicAutomations.reverse().map(key => {
      if (key.automation_id == idAutomation && key.db_name == currentUser.db) {
        if (key.type_client == 'fisico') {
          clientFisicos.map(k => {
            if (k.id == key.client_id) {
              rows.push({
                id: key.id,
                name: k.name,
                phone: k.phone,
                create_at: new Date(key.createdAt).toLocaleDateString('pt-BR'),
                status: 'Enviado'
              })
            }
          })
        }
      }
    })
    return (
      <Modal
        show={openModalReport}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Relatório de envio
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <Paper sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{ border: 0, '.MuiDataGrid-columnHeader': { width: '25% !important' }, '.MuiDataGrid-cell': { width: '25% !important' }, ".MuiDataGrid-cellEmpty": { display: 'none' } }}
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              pagination
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          </Paper>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <>
      <ModalReportAutomation />
      <ModalDelete />
      <ModalEditAutomation />
      <Loading />
      <div className='orderedContainer'>
        <div className='containerAutomations'>
          <div className='headerAutomations'>
            <div className='nameAutomation'>
              Nome da automação
            </div>
            <div className='statusAutomation' style={{ width: "5vw" }}>
              Status
            </div>
            <div style={{ width: "10vw" }}>
              Ativar/Desativar
            </div>
            <div className='actionsAutomation'>
              Ações
            </div>
          </div>
          <div className='bodyAutomations'>
            {automations.map(key => {
              if (key.db_name == currentUser.db) {
                if (key.type_automation !== 'system') {
                  return (
                    <div className='divAutomation'>
                      <div className='nameAutomation'>
                        <span>{key.name}</span>
                      </div>
                      <div className='statusAutomation' style={{ paddingLeft: "11.5%" }}>
                        {key.activate}
                      </div>
                      <div className='switchAutomation' style={{ paddingLeft: "5%" }}>
                        <Switch
                          defaultChecked={key.activate == 'Ativado' ? true : false}
                          onChange={e => {
                            handleActiveAutomation(e, key.id)
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </div>
                      <div className='actionsAutomation'>
                        <button className='divIconsAutomation' onClick={e => {
                          setOpenModalEdit(true)
                          setIdAutomation(key.id)
                        }}>
                          <MdModeEdit className='iconEditAutomation' />
                        </button>
                        <button className='divIconsAutomation2' onClick={e => {
                          setOpenModalDelete(true)
                          setIdAutomation(key.id)
                        }} disabled={key.type_automation == 'system' ? true : false}>
                          <FaTrash className='iconTrashAutomation' />
                        </button>
                        <button className='divIconsAutomation3' onClick={e => {
                          setOpenModalReport(true)
                          setIdAutomation(key.id)
                        }} disabled={key.type_automation == 'system' ? true : false}>
                          <FaSearch className='iconMenuAutomation' />
                        </button>
                      </div>
                    </div>
                  )
                } else {
                  // return (
                  //   <>
                  //     <h3 style={{ marginLeft: 30 }}>Automação do sistema</h3>
                  //     <div className='divAutomation'>
                  //       <div className='nameAutomation'>
                  //         <span>{key.name}</span>
                  //       </div>
                  //       <div className='statusAutomation' style={{ paddingLeft: "11.5%" }}>
                  //         {key.activate}
                  //       </div>
                  //       <div className='switchAutomation' style={{ paddingLeft: "5%" }}>
                  //         <Switch
                  //           defaultChecked={key.activate == 'Ativado' ? true : false}
                  //           onChange={e => {
                  //             handleActiveAutomation(e, key.id)
                  //           }}
                  //           inputProps={{ 'aria-label': 'controlled' }}
                  //         />
                  //       </div>
                  //       <div className='actionsAutomation'>
                  //         <button className='divIconsAutomation' onClick={e => {
                  //           setOpenModalEdit(true)
                  //           setIdAutomation(key.id)
                  //         }}>
                  //           <MdModeEdit className='iconEditAutomation' />
                  //         </button>
                  //         <button className='divIconsAutomation2' onClick={e => {
                  //           setOpenModalDelete(true)
                  //           setIdAutomation(key.id)
                  //         }} disabled={key.type_automation == 'system' ? true : false}>
                  //           <FaTrash className='iconTrashAutomation' />
                  //         </button>
                  //         <button className='divIconsAutomation3' onClick={e => {
                  //           setOpenModalReport(true)
                  //           setIdAutomation(key.id)
                  //         }} disabled={key.type_automation == 'system' ? true : false}>
                  //           <FaSearch className='iconMenuAutomation' />
                  //         </button>
                  //       </div>
                  //     </div>
                  //   </>
                  // )
                }
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default Automation
