import React from 'react'
import { useState, createContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Routes, Route, createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom'
import Header from './pages/Header'
import Dashboard from './pages/Dashboard/index'
import Login from './pages/Login'
import Footer from './pages/Footer'
import Menu from './pages/Menu'
import Ordered from './pages/Ordered'
import Clients from './pages/Clients'
import ClientPhysic from './pages/ClientPhysic'
import ClientBusiness from './pages/ClientBusiness'
import Collaborators from './pages/Collaborators'
import Drivers from './pages/Drivers'
import Vehiclers from './pages/Vehicles'
import CreateProducts from './pages/CreateProducts'
import CategoryProduct from './pages/CategoryProduct'
import Services from './pages/Services'
import CategoryBusiness from './pages/CategoryBusiness'
import Provider from './pages/Providers'
import Payment from './pages/Payment'
import ResellerProfile from './pages/ResellerProfile'
import Profile from './pages/Profile'
import UpdatePassword from './pages/UpdatePassword'
import './default.css'
import Accordion from './contexts/accordion'
import Cupons from './pages/Cupons'
import Sectors from './pages/Sectors'
import ClosingHistory from './pages/ClosingHistory'
import ClosingDriver from './pages/ClosingDriver'
import SalesChannel from './pages/SalesChannel';
import Financeiro from './pages/financeiro'
import LocalizationDriver from './pages/LocalizationDriver'
import BirthdayPerson from './pages/BirthdayPerson'
import NFe from './pages/Nfe';
import NFCe from './pages/Nfce';
import NFSe from './pages/Nfse';
import NFeRecebida from './pages/NfeRecebida/index.js';
import Configuration from './pages/Configuration';
import GenereteImage from './pages/GenereteImage';
import EditImage from './pages/EditImage';
import PageNotFound from './pages/PageNotFound';
import CreatePayReseller from './pages/CreatePayReseller/index.js';
import api from "./service/api";
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from './redux/configurationTheme/actions'
import DashboardClub from './pages/DashboardClub/index.js';
import Mentoring from './pages/Mentoring'
import MentoringVideo from './pages/MentoringVideo'
import WhatsappPage from './pages/WhatsappPage/index.js';
import RaioX from './pages/RaioX';
import MinhaRevendaGoogle from './pages/MinhaRevendaGoogle'
import MeuGoogleAds from './pages/MeuGoogleAds/index.js';
import APPGasClick from './pages/APPGasClick/index.js';
import MotoristaTech from './pages/MotoristaTech/index.js';
import MentoringPlaylist from './pages/MentoringPlaylist/index.js';
import Debt from './pages/Debt/index.js';
import Solicitation from './pages/Solicitations'
import CategoryDebt from './pages/CategoryDebt'
import Report from './pages/Report'
import { TourProvider } from '@reactour/tour'
import Automation from './pages/Automation/index.js';
import UpdatePlan from './pages/UpdatePlan'
import UpdatePlanConfirm from './pages/UpdatePlanConfirm/index.js';
import PromotionPayReseller from './pages/PromotionPayReseller/index.js';
import PaymentDebt from './pages/PaymentDebt/index.js';
import LogStockProduct from './pages/LogStockProduct/index.js';

function Rotas() {
    const [accordion, setAccordion] = useState(true);
    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [step, setStep] = React.useState(0)
    const [urlRedirect, setUrlRedirect] = React.useState('')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const dataNow = new Date();
    const day = dataNow.getDate()
    const month = dataNow.getMonth() + 1
    if (day == 1) {
        if (currentUser !== undefined) {
            if (currentUser.db !== 'clubeApi') {
                api
                    .get("/TodosUsuariosRevenda")
                    .then((response) => {
                        response.data.map(key => {
                            if (key['db_name'] === currentUser.db) {
                                if (key['count_download_image'] !== 0) {
                                    if (key['last_update_download_count'] < month || key['last_update_download_count'] == null) {
                                        api.put('/AtualizarUsuarioRevenda', {
                                            id: key['id'],
                                            count_download_image: 0,
                                            last_update_download_count: month
                                        })
                                    }
                                }
                            }
                        })
                    })
                    .catch((err) => {
                        console.error("ops! ocorreu um erro" + err);
                    });
            }
        }
    }

    const steps = [
        {
            selector: '.first-step',
            content: 'Seja bem vindo ao clube, complemente as informações da sua revenda',
        },
        {
            selector: '.second-step',
            content: 'Cadastre as informações da sua revenda para começar a utilizar a plataforma da melhor maneira',
        },
        {
            selector: '.third-step',
            content: 'Clique aqui para registrar o seus dados de revenda',
        },
    ]

    const steps2 = [
        {
            selector: '.fourth-step',
            content: 'Parabéns, você criou o seu perfil de revenda! Agora é a hora de verificar seu produtos, vamos lá?',
        },
        {
            selector: '.fifth-step',
            content: 'Você selecionou revenda Ultragaz no seu cadastro, então já deixamos os produtos cadastrados e prontos para utilização. Confira nomes e valores, antes de começar a vender',
        },
        {
            selector: '.sixth-step',
            content: `Você também pode cadastrar novos produtos basta clicar no botão "NOVO PRODUTO"`,
        },
    ]

    const steps3 = [
        {
            selector: '.seventh-step',
            content: 'Vamos conhecer a gestão do sistema, é aqui onde você acompanha a visão geral, pedidos e todos os clientes',
        },
        {
            selector: '.eight-step',
            content: 'Nos pedidos, você acompanha os pedidos recebidos e lança novas vendas',
        },
        {
            selector: '.nine-step',
            content: `Veja quem são seus clientes e cadastre novos`,
        },
        {
            selector: '.ten-step',
            content: `Visão geral mostra um panorama geral da situação da sua revenda`,
        }
    ]

    const steps4 = [
        {
            selector: '.eleven-step',
            content: 'Chegou a hora de organizar sua vendas do whatsapp. Nessa tela você encontra seu link de pedidos e o passo a passo para cadastrar o seu link em uma mensagem de resposta automática no seu whatsapp',
        },
        {
            selector: '.twelve-step',
            content: 'Esse é o seu link de pedido do whatsapp, acesse-o e conheça',
        }
    ]

    const steps5 = [
        {
            selector: '.eight-step',
            content: 'Após fazer o pedido no whatsapp, você pode acompanhar o seus pedidos aqui',
        },
        {
            selector: '.Component-horizontalScrollContainer-12',
            content: 'Seus pedidos vão aparecer aqui nessa tela, você pode executar diversas ações com ele, como selecionar mudar o status, selecionar o motorista entrregador',
        },
        {
            selector: '.MTableToolbar-actions-8',
            content: 'Você pode altera-las e executar diversos tipo de filtros para localizar suas vendas',
        }
    ]

    const value = { accordion, setAccordion }
    let storage = '';
    let location = '';
    let db = 'clubeApi'

    let typeCollaborator = '';
    if (currentUser !== undefined && currentUser !== null) {
        typeCollaborator = currentUser.typeCollaborator;
    }

    if (currentUser !== undefined && currentUser !== null) {
        storage = currentUser.token
        location = currentUser.location
        db = currentUser.db
    }

    if (storage !== 'dXN1YXJpb2F1dG9yaXphZG9hY2Vzc2FybmFlcnA=') {
        return (
            <>
                <Router basename='/'>
                    <Routes>
                        <Route exact element={<Login />} path="/" />
                        <Route exact element={<Login />} path="/login" />
                        <Route exact element={<CreatePayReseller />} path="/contratar" />
                        <Route exact element={<CreatePayReseller />} path="/contratar/:plan" />
                        <Route exact element={<PromotionPayReseller />} path='promocao-contratar/:code' />
                        <Route exact element={<Login />} path='*' />
                    </Routes>
                </Router>
            </>

        )
    } else {
        return (
            <TourProvider steps={step == 0 ? steps : step == 1 ? steps2 : step == 2 ? steps3 : step == 3 ? steps4 : steps5} showDots={false} showBadge={false} badgeContent={({ totalSteps, currentStep }) => {

            }} nextButton={({ currentStep, setCurrentStep, steps }) => {
                return (
                    <div className='divBtnNext'>
                        <button
                            className='btnNext'
                            onClick={() => {
                                if (document.getElementsByClassName('btnNext')[0].innerHTML == 'Ver meu link') {
                                    setUrlRedirect(document.querySelectorAll('.divContanctWhatsappBallumn.twelve-step')[0].childNodes[0].childNodes[0].defaultValue)
                                    var btn = document.querySelector("#btn");
                                    document.querySelectorAll('.reactour__mask')[0].style.display = 'none';
                                    document.querySelectorAll('.reactour__popover')[0].style.display = 'none';
                                    setTimeout(() => {
                                        btn.click()
                                    }, 100)
                                }
                                setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))

                                if (step == 0) {

                                    if ((currentStep + 1) == 1) {
                                        document.getElementsByClassName('first-step')[0].click()
                                        setTimeout(() => {
                                            document.querySelectorAll('.second-step-menu, .dropdown-menu')[0].className = "second-step-menu dropdown-menu show"
                                        }, 100);
                                    } else if ((currentStep + 1) == 2) {
                                        document.querySelectorAll('.subMenuDiv.second-step')[0].children[0].click()
                                        document.querySelectorAll('.second-step-menu, .dropdown-menu')[0].className = "second-step-menu dropdown-menu"
                                    } else if ((currentStep + 1) == 3) {
                                        document.querySelectorAll('.third-step')[0].click()
                                        document.querySelectorAll('.reactour__mask')[0].style.display = 'none';
                                        document.querySelectorAll('.reactour__popover')[0].style.display = 'none';
                                        setStep(1)
                                    }
                                } else if (step == 1) {
                                    if ((currentStep + 1) == 1) {
                                        document.querySelectorAll('.reactour__mask')[0].style.opacity = '0';
                                        document.querySelectorAll('.reactour__popover')[0].style.top = '6vh';
                                        document.querySelectorAll('.reactour__popover')[0].style.left = '41vw';
                                        document.querySelectorAll('.fourth-step-menu, .dropdown-menu')[0].className = "fourth-step-menu dropdown-menu"
                                        document.querySelectorAll('.subMenuDiv.fourth-step')[0].children[0].click()
                                    } else if ((currentStep + 1) == 2) {
                                        document.querySelectorAll('.reactour__mask')[0].style.opacity = '0.7';
                                        document.querySelectorAll('.reactour__popover')[0].style.top = '0';
                                        document.querySelectorAll('.reactour__popover')[0].style.left = '0';
                                        setStep(2)
                                    }
                                } else if (step == 2) {
                                    if ((currentStep + 1) == 1) {
                                        document.querySelectorAll('.seventh-step')[0].click()
                                    } else if ((currentStep + 1) == 2) {
                                        setTimeout(() => {
                                            document.querySelectorAll('.eight-step-menu.dropdown-menu')[0].className = "eight-step-menu dropdown-menu show"
                                        }, 100);
                                    } else if ((currentStep + 1) == 2) {
                                        setTimeout(() => {
                                            document.querySelectorAll('.eight-step-menu.dropdown-menu')[0].className = "eight-step-menu dropdown-menu show"
                                        }, 100);
                                        setStep(3)
                                    }
                                } else if (step == 3) {
                                    if ((currentStep + 1) == 1) {
                                        document.querySelectorAll('.subMenuDiv.eleven-step')[0].children[0].click()
                                    }
                                } else if (step == 4) {
                                    if ((currentStep + 1) == 1) {
                                        document.querySelectorAll('.subMenuDiv.eight-step')[0].children[0].click()
                                    } else if ((currentStep + 1) == 2) {
                                        document.querySelectorAll('.eight-step-menu, .dropdown-menu')[0].className = "eight-step-menu dropdown-menu"
                                    } else if ((currentStep + 1) == 3) {
                                        setStep(5)
                                    }
                                }
                            }}
                        >
                            {step == 3 && (currentStep + 1) == 2 ? (
                                'Ver meu link'
                            ) : ('Próximo')}
                        </button>
                        <a id="btn" style={{ display: 'none' }} href={urlRedirect} target='_blank'></a>
                    </div >
                )
            }} prevButton={({ currentStep, setCurrentStep, steps }) => {
            }}>
                <Router basename='/'>
                    <div className='BaseContainer'>
                        {/* <Menu /> */}
                        <div className='CenterContainer'>
                            <Header />
                            <Routes>
                                <Route exact element={<DashboardClub />} path='/' />
                                <Route exact element={<DashboardClub />} path='/dashboard' />
                                <Route exact element={<Dashboard />} path='/dashboard-gestao' />
                                <Route exact element={<Ordered />} path='/pedidos' />
                                <Route exact element={<Clients />} path='/clientes' />
                                <Route exact element={<ClientPhysic />} path='/clientes-fisico' />
                                <Route exact element={<ClientBusiness />} path='/clientes-juridico' />
                                <Route exact element={<Collaborators />} path='/colaboradores' />
                                <Route exact element={<Drivers />} path='/entregadores' />
                                <Route exact element={<Vehiclers />} path='/veiculos' />
                                <Route exact element={<CreateProducts />} path='/produtos' />
                                <Route exact element={<CategoryProduct />} path='/categoria-produto' />
                                <Route exact element={<Services />} path='/servicos' />
                                <Route exact element={<CategoryBusiness />} path='/categoria-comércio' />
                                <Route exact element={<Provider />} path='/fornecedores' />
                                <Route exact element={<Payment />} path='/forma-pagamento' />
                                <Route exact element={<PaymentDebt />} path='/forma-pagamento-despesa' />
                                <Route exact element={<ResellerProfile />} path='/perfil-revenda' />
                                <Route exact element={<Profile />} path='/perfil' />
                                <Route exact element={<UpdatePassword />} path='/alterar-senha' />
                                <Route exact element={<Cupons />} path='/cupons' />
                                <Route exact element={<Sectors />} path='/setores' />
                                <Route exact element={<ClosingHistory />} path='/historico-fechamento' />
                                <Route exact element={<ClosingDriver />} path='/historico-entregadores' />
                                <Route exact element={<SalesChannel />} path='/canal-venda' />
                                <Route exact element={<Financeiro />} path='/financeiro' />
                                <Route exact element={<DashboardClub />} path='*' />
                                <Route exact element={<Mentoring />} path='/mentorias' />
                                <Route exact element={<MentoringVideo />} path='/video/:id' />
                                <Route exact element={<WhatsappPage />} path='/whatsapp' />
                                <Route exact element={<RaioX />} path='/raio-x' />
                                <Route exact element={<MinhaRevendaGoogle />} path='/minha-revenda-google' />
                                <Route exact element={<MeuGoogleAds />} path='/meu-google-ads' />
                                <Route exact element={<APPGasClick />} path='/app-gas-click' />
                                <Route exact element={<MotoristaTech />} path='/motorista-tech' />
                                <Route exact element={<MentoringPlaylist />} path='/playlist/:id' />
                                <Route exact element={<Debt />} path='/despesas' />
                                <Route exact element={<Solicitation />} path='/meu-chamados' />
                                <Route exact element={<CategoryDebt />} path='/categoria-despesa' />
                                <Route exact element={<Report />} path='/relatorios' />
                                <Route exact element={<Automation />} path='/automacao' />
                                <Route exact element={<UpdatePlan />} path='/atualizar-plano/:plan' />
                                <Route exact element={<UpdatePlanConfirm />} path='/plano-atualizado' />
                                <Route exact element={<LogStockProduct />} path='/historico-estoque-produto' />
                                {localizacao == 1 || typeCollaborator == 'superadmin' || typeCollaborator == 'admin' ? (
                                    <Route exact element={<LocalizationDriver />} path='/localization-driver' />
                                ) : (
                                    <Route exact element={<DashboardClub />} path='*' />
                                )}
                                <Route exact element={<BirthdayPerson />} path='/aniversariantes-mes' />
                                {notas == 1 || typeCollaborator == 'superadmin' || typeCollaborator == 'admin' ? (
                                    <>
                                        <Route exact element={<NFe />} path='/nfe' />
                                        <Route exact element={<NFCe />} path='/nfce' />
                                        <Route exact element={<NFSe />} path='/nfse' />
                                        <Route exact element={<NFeRecebida />} path='/nfe-recebida' />
                                    </>
                                ) : (
                                    <Route exact element={<DashboardClub />} path='*' />
                                )}

                                <Route exact element={<Configuration />} path='/configuration' />
                                {geradorImagem == 1 || typeCollaborator == 'superadmin' || typeCollaborator == 'admin' ? (
                                    <>
                                        <Route exact element={<GenereteImage />} path='/gerar-imagem' />
                                        <Route exact element={<EditImage />} path='/editar-imagem' />
                                    </>
                                ) : (
                                    <Route exact element={<DashboardClub />} path='*' />
                                )}
                            </Routes>
                            <Footer />
                        </div>
                    </div>
                </Router>
            </TourProvider>
        )
    }
}

export default Rotas