import { useState, useEffect } from "react";
import React from 'react'
import './genereteImage.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { BsFire } from "react-icons/bs"
import { BiEdit } from "react-icons/bi"
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { genereteImage } from '../../redux/genereteImage/actions'
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { IoMdClose } from "react-icons/io";

const GenereteImage = () => {

    const [open, setOpen] = useState(false);
    const [showMore, setShowMore] = useState(21);
    const [heightCard, setHeightCard] = useState(180);
    const [emAlta, setEmAlta] = useState([]);
    const [redesSociais, setRedesSociais] = useState([]);
    const [stories, setStories] = useState([]);
    const [reels, setReels] = useState([]);
    const [impresso, setImpresso] = useState([]);
    const [imageFilter, setImageFilter] = useState([]);
    const [itemData, setImtemData] = useState([]);
    const [categorys, setCategorys] = React.useState([]);
    const [subcategorys, setSubcategorys] = React.useState([]);
    const [age, setAge] = React.useState('');
    const [rows, setRows] = React.useState([])
    const [categoryId, setCategoryId] = React.useState(0);
    const [subCategory, setSubCategory] = React.useState(false)
    const [countDownload, setCountDownload] = useState(0)
    const [resellerType, setResellerType] = useState('');
    const [limitDownload, setLimiteDownload] = useState(5)

    const dispatch = useDispatch();

    const { currenteGenerete } = useSelector(rootReducer => rootReducer.genereteReducer);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    useEffect(() => {
        Axios
            .get("https://apiadmin.clubedorevendedordegas.com.br/TodasArtes")
            .then((response) => {
                setImageFilter(response.data.sort());
                setImtemData(response.data.sort());
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        Axios
            .get('https://apiadmin.clubedorevendedordegas.com.br/TodasCategorias')
            .then(response => {
                setCategorys(response.data);
            })
    }, [])

    React.useEffect(() => {
        Axios
            .get('https://apiadmin.clubedorevendedordegas.com.br/TodasSubcategorias')
            .then(response => {
                setSubcategorys(response.data);
            })
    }, [])

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key.db_name == currentUser.db) {
                        setCountDownload(key.count_download_image)
                        setResellerType(key.type_reseller)
                        if (key.module !== 'free') {
                            setLimiteDownload(30)
                        }
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }



    function downloadImage(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            if (this.status == 200) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    callback.call(callback, e.target.result);
                }
                reader.readAsDataURL(this.response);
            }
        };
        xhr.send();
    }

    const handleChange = (event) => {
        let valueText = document.getElementsByClassName('filterArtName')[0].childNodes[1].childNodes[0].value;
        let searchValue = event.target.value
        let filterCategory = [];
        let filterName = []
        let filterSubCategory = [];
        if (valueText == '') {
            if (typeof categoryId == 'number') {
                setAge(searchValue)
                let filters = {
                    category: String(categoryId),
                    subcategory: String(age)
                };
                filterCategory = itemData.filter(obj =>
                    Object.entries(filters).every(([key, filterArr]) => (
                        filterArr.length === 0 || filterArr.includes(obj[key])
                    )))
                const concatArray = filterName.concat(filterCategory)
                setImageFilter(concatArray.filter((este, i) => concatArray.indexOf(este) === i))
            } else {
                setImageFilter(itemData)
            }

        } else {
            if (typeof categoryId == 'number') {
                setAge(searchValue)
                let filters = {
                    category: String(categoryId),
                    subcategory: String(age)
                };
                filterCategory = imageFilter.filter(obj =>
                    Object.entries(filters).every(([key, filterArr]) => (
                        filterArr.length === 0 || filterArr.includes(obj[key])
                    )))
                const concatArray = filterName.concat(filterCategory)
                setImageFilter(concatArray.filter((este, i) => concatArray.indexOf(este) === i))
            }
            if (searchValue !== 0) {
                filterName = imageFilter.filter(value => value.name.toLowerCase().includes(valueText.toLowerCase()))
                setImageFilter(imageFilter.filter(value => value.name.toLowerCase().includes(valueText.toLowerCase())))
            }
        }

    };

    const handleClearFilter = () => {
        setImageFilter(itemData)
        setAge('')
        setCategoryId('')
    }

    return (
        <>
            <Loading />
            <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div>
                        <h5>Gerar imagem</h5>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '100%', borderRadius: '10px', padding: '10px' }}>
                    <div className="divFilterCountDownload">
                        <div className='filterArts'>
                            <TextField id="outlined-basic" label="Buscar imagem" variant="outlined" className='filterArtName' onChange={handleChange} />
                            <FormControl fullWidth className="filterSubCategoryForm">
                                <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className='filterArtCategory'
                                    value={categoryId}
                                    label="Categoria"
                                    onChange={e => {
                                        let value = e.target.value;
                                        if (value !== '') {
                                            setCategoryId(value);
                                            setSubCategory(true)
                                        } else {
                                            setCategoryId(0);
                                            setSubCategory(false)
                                        }
                                    }}
                                >
                                    {categorys.map(key => {
                                        return (
                                            <MenuItem value={key.id}>{key.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            {subCategory ? (
                                <FormControl fullWidth style={{ marginLeft: 10 }} className="filterSubCategory">
                                    <InputLabel id="demo-simple-select-label">Sub categoria</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='filterArtCategory filterSubCategory'
                                        value={age}
                                        label="Sub categoria"
                                        onChange={e => {
                                            setAge(e.target.value)
                                            handleChange(e)
                                        }}
                                    >
                                        {subcategorys.map(key => {
                                            if (key.category_id == categoryId) {
                                                return (
                                                    <MenuItem value={key.id}>{key.name}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                            ) : ("")}
                            <div className="btnClearFilterGenerteImage">
                                <button className="btnClearFilter me-2 button" onClick={handleClearFilter}>Limpar filtro</button>
                            </div>
                        </div>
                        <div className="containerDownloadCount">
                            <div className="divDownloadCount">
                                <div className="divBallunDownload">
                                    <span className="spanBallunDownload">Downloads</span>
                                    <div className="divCountBallunDownload">
                                        <span className="spanBallunDownload">{countDownload}</span>
                                    </div>
                                </div>
                                <div className="divBallunDownload">
                                    <span className="spanBallunDownload">Disponível</span>
                                    <div className="divCountBallunDownload">
                                        <span className="spanBallunDownload">{limitDownload - countDownload}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="divInfoDonwloadCount">
                                <span className="spanInfoDonwloadCount">Contrate o plano e tenha até <span className="span2InfoDonwloadCount">30 downloads</span> no mês! <Link className="span2InfoDonwloadCount">Upgrade agora.</Link></span>
                            </div> */}
                        </div>

                    </div>

                    <div className='containerAllArts'>
                        {imageFilter.reverse().slice(0, showMore).map((key) => {
                            return (
                                <div className='containerArt'>
                                    <div className='imageArt'>
                                        <img src={resellerType == 'Ultragaz' ? key.ultragaz_url : resellerType == 'Liquigás' ? key.liquigas_url : resellerType == 'Supergasbras' ? key.supergasbras_url : resellerType == 'Copagaz' ? key.copagaz_url : resellerType == 'Consigaz' ? key.consigaz_url : resellerType == 'Nacional Gás' ? key.nacionalGas_url : resellerType == 'NGC' ? key.nacionalGas_url : key.independente_url} className='imgArt' />
                                    </div>
                                    <div className='containerContect'>
                                        <span className='titleArt'>
                                            {key.name}
                                        </span>
                                        <Link to='/editar-imagem' className='btnEditArt' onClick={e => {
                                            dispatch(genereteImage({ urlImage: resellerType == 'Ultragaz' ? key.ultragaz_url : resellerType == 'Liquigás' ? key.liquigas_url : resellerType == 'Supergasbras' ? key.supergasbras_url : resellerType == 'Copagaz' ? key.copagaz_url : resellerType == 'Consigaz' ? key.consigaz_url : resellerType == 'Nacional Gás' ? key.nacionalGas_url : resellerType == 'NGC' ? key.nacionalGas_url : key.independente_url, typeArt: key.category }))
                                        }}>
                                            <BiEdit style={{ color: '#FAB63D' }} />
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <ImageList sx={{ height: 'auto', width: "100%" }}>
                        <ImageListItem key="Subheader" cols={3}>
                            <ListSubheader component="div">Lista de imagens</ListSubheader>
                        </ImageListItem>
                        {imageFilter.slice(0, showMore).map((item) => {
                            console.log(item);
                            return (
                                <ImageListItem key={item.url} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        srcSet={`${item.url}`}
                                        src={`${item.url}`}
                                        alt={item.name}
                                        width={500}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={item.name}
                                        subtitle={item.category == '1' ? ('Redes Sociais') : item.category == '2' ? ('Stories') : item.category == '3' ? ('Reels') : ('Impresso')}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.name}`}
                                            >
                                                <Button bsPrefix="button-menu edit-button" href="/editar-imagem" onClick={() => {
                                                    dispatch(genereteImage({ urlImage: item.url, typeArt: item.category }))
                                                }
                                                }>
                                                    <div>
                                                        <BiEdit style={{ color: 'var(--primaryColor)' }} />
                                                    </div>
                                                </Button>

                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            )
                        })}
                    </ImageList> */}
                    {itemData.length >= showMore ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <button className="me-2 button" onClick={() => {
                                setShowMore(showMore + 21)
                            }}>Carregar mais</button>
                        </div>
                    ) : ""}

                </Card>
            </div >
        </>
    )
}

export default GenereteImage