import { useState } from "react";
import React from 'react'
import './createProducts.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableCreateProducts from '../TableCreateProducts'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import { CurrencyInput } from 'react-currency-mask';
import { Link } from "react-router-dom";

const CreateProducts = () => {

    const [category, setCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    React.useEffect(() => {
        api
            .get("/TodasCategoriasProdutos" + '/' + currentUser.db)
            .then((response) => {
                setCategory(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosProdutos" + '/' + currentUser.db)
            .then((response) => {
                setProducts(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let value = 0;
        var splitValue = data.valueProduct;
        var arrayTest = [];
        let dados = '';

        if (data.imageProduct.name !== '') {
            dados = {
                image: data.imageProduct,
                name: data.product,
                category_id: Number(data.categoryProduct),
                value: splitValue,
                description: data.descriptionProduct,
                stock: 0,
                stock_empty: 0,
                stock_full: 0,
                stock_complete: 0,
                imagem: "https://api.clubedorevendedordegas.com.br/files/products/" + data.imageProduct.name,
                whatsapp: data.whatsappProduct,
                order: Number(data.orderProduct),
                db: currentUser.db
            }
        } else {
            dados = {
                name: data.product,
                category_id: Number(data.categoryProduct),
                value: splitValue,
                description: data.descriptionProduct,
                stock: 0,
                stock_empty: 0,
                stock_full: 0,
                stock_complete: 0,
                whatsapp: data.whatsappProduct,
                order: Number(data.orderProduct),
                db: currentUser.db
            }
        }

        products.map(key => {
            if (key['order'] >= Number(data.orderProduct)) {
                arrayTest.push({
                    id: key['id'],
                    order: Number(key['order']) + 1,
                    db: currentUser.db
                })
            }
        })

        for (var i = 0; i <= arrayTest.length; i++) {
            let product = arrayTest.sort((a, b) => a.order - b.order)[i];
            if (product !== undefined) {
                api.put("/AtualizarProduto", {
                    id: product.id,
                    order: Number(data.orderProduct) + i + 1,
                    db: currentUser.db
                }, {
                    'headers': {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            }
        }

        api.post("/CadastrarProduto", dados, {
            'headers': {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.log(err)
            });

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 5000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button sixth-step">
                    {'Novo produto'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo produto</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="product">Nome do produto *</InputLabel>
                                <input
                                    required
                                    id="product"
                                    name="product"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Categoria *</InputLabel>
                                <Form.Select
                                    name='categoryProduct'
                                    className="inputCadastro"
                                >
                                    <option value='' >Selecione uma categoria de produto</option>
                                    {category.map(k => {
                                        return (
                                            <option value={k['id']}>{k['name']}</option>
                                        )
                                    })}
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="valueProduct">Valor *</InputLabel>
                                <CurrencyInput
                                    required
                                    id="valueProduct"
                                    name="valueProduct"
                                    className="inputCadastro"
                                    onChangeValue={(event, originalValue, maskedValue) => {
                                        // console.log(event, originalValue, maskedValue);
                                    }}
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="descriptionProduct">Descrição *</InputLabel>
                                <input
                                    required
                                    id="descriptionProduct"
                                    name="descriptionProduct"
                                    className="inputCadastro"
                                />
                            </div>
                            {/* <div className="divCadastro">
                                <InputLabel htmlFor="stockProduct">Estoque</InputLabel>
                                <input
                                    name="stockProduct"
                                    id="stockProduct"
                                    className="inputCadastro"
                                />
                            </div> */}

                            <div className="divCadastro">
                                <InputLabel htmlFor="docVehicle">Imagem *</InputLabel>
                                <input
                                    type="file"
                                    name="imageProduct"
                                    className="inputImage"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="stockProduct">Ordem *</InputLabel>
                                <input
                                    name="orderProduct"
                                    required
                                    id="stockProduct"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="modelVehicle">Aparecerá no whatsapp? *</InputLabel>
                                <Form.Select
                                    name='whatsappProduct'
                                    className="inputCadastro"
                                >
                                    <option value='' >Selecione uma resposta</option>
                                    <option value='1'>Sim</option>
                                    <option value='0'>Não</option>
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar produto
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Produtos</h5>
                    </div>
                    <div>
                        <Link to='/historico-estoque-produto' variant="primary" className="me-2 button sixth-step" style={{width: '50%'}}>Histórico de movimentação</Link>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer tableCreateProduct" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableCreateProducts />
                </Card>
            </div >
        </>
    )
}

export default CreateProducts