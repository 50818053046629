import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableCollaborators.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineDollar } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { NumbersTwoTone } from '@mui/icons-material';
import { CurrencyInput } from 'react-currency-mask';
import { Link } from 'react-router-dom';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [salaryModal, setSalaryModal] = React.useState(false);
  const [debts, setDebts] = React.useState([])

  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

  React.useEffect(() => {
    api
      .get("/TodasDespesas" + '/' + currentUser.db)
      .then((response) => {
        setDebts(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosColaboradores" + '/' + currentUser.db)
      .then((response) => {
        if (currentUser.db == 'clubeApi') {
          let array = [];
          response.data.map(key => {
            if (key['reseller_db'] == 'clubeApi') {
              array.push({ id: key['id'], type: key['type'], collaborator: key['name'], endereco: key['id'], telefone: key['phone'] })
            }
          })
          setRows(array)
        } else {
          setRows(response.data.map(key => ({
            id: key['id'], type: key['type'], collaborator: key['name'], endereco: key['id'], telefone: key['phone']
          })))
        }
        setCollaborators(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setSalaryModal(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let phone = data.celularCollaboratorEdit.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
    const dados = {
      id: idModal,
      name: data.nameCollaboratorEdit,
      number: Number(data.numeroCollaboratorEdit),
      street: data.enderecoCollaboratorEdit,
      city: data.cidadeCollaboratorEdit,
      state: data.estadoCollaboratorEdit,
      neighborhood: data.bairroCollaboratorEdit,
      complement: data.complementoCollaboratorEdit,
      reference_point: data.referenciaCollaboratorEdit,
      cpf: data.cpfCollaboratorEdit,
      email: data.emailCollaboratorEdit,
      phone: phone,
      birthdate: data.dataNascimentoCollaboratorEdit,
      obs: data.obsCollaboratorEdit,
      type: data.typeCollaboratorEdit,
      db: currentUser.db
    }

    api.put("/AtualizarCadastroColaborador", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarColaborador/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitModalSalary = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let monthNow = new Date().getMonth() + 1;
    let dayNow = new Date().getDate();
    const yearNow = new Date().getFullYear();
    let payDay = data.salaryDay
    let createDebts = false
    let arrayIdsDebts = []

    if (Number(dayNow) >= Number(data.salaryDay)) {
      monthNow = monthNow + 1;
    }

    if (Number(monthNow) < 10) {
      monthNow = `0${monthNow}`
    }

    if (Number(data.salaryDay) < 10) {
      payDay = `0${data.salaryDay}`
    }

    debts.map(key => {
      if (key['category'] == 'folhaPagamento') {
        if (key['name'] == data.nameCollaborator) {
          arrayIdsDebts.push(key['id'])
        }
      }
    })

    if (arrayIdsDebts.length == 0) {
      createDebts = true
    }

    const dados = {
      id: idModal,
      salary: data.salary.replace('R$', '').replace(' ', ''),
      pay_day: data.salaryDay,
      db: currentUser.db
    }

    api.put("/AtualizarCadastroColaborador", dados);

    var due_date = `${yearNow}-${monthNow}-${payDay}`

    if (createDebts) {
      for (var i = 1; 6 >= i; i++) {
        let dateSplit = due_date.split('-');
        let month = Number(dateSplit[1]) + i - 1;
        let year = Number(dateSplit[0])
        let day = Number(dateSplit[2])

        if (month < 10) {
          month = "0" + month;
        }
        if (month > 12) {
          month = "0" + (month - 12);
          year = year + 1;
        }

        let date = year + '-' + month + '-' + day;
        const dados2 = {
          name: data.nameCollaborator,
          type: 'fixa',
          category: 'folhaPagamento',
          due_date: date,
          value: data.salary.replace('R$', '').replace(' ', ''),
          status: 'Aberto',
          db: currentUser.db
        }
        api.post('/CadastrarDespesas', dados2)
      }
    } else {
      for (var a = 0; arrayIdsDebts.length >= a; a++) {
        let dateSplit = due_date.split('-');
        let month = Number(dateSplit[1]) + a;
        let year = Number(dateSplit[0])
        let day = Number(dateSplit[2])

        if (month < 10) {
          month = "0" + month;
        }
        if (month > 12) {
          month = "0" + (month - 12);
          year = year + 1;
        }

        let date = year + '-' + month + '-' + day;
        const dados2 = {
          name: data.nameCollaborator,
          type: 'fixa',
          category: 'folhaPagamento',
          due_date: date,
          value: data.salary.replace('R$', '').replace(' ', ''),
          status: 'Aberto',
          db: currentUser.db,
          id: arrayIdsDebts[a]
        }

        api.put('/AtualizarDespesa', dados2)
      }

    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    if (painelModal) {
      setTimeout(() => {
        const divVehicleDebts = document.getElementById('divVehicleDebts')
        if (divVehicleDebts !== null) {
          divVehicleDebts.style.display = 'none'
        }

        debts.map(key => {
          if (key['category'] == 'colaborador') {
            if (key['bond'] == idModal) {
              divVehicleDebts.style.display = 'block'
            }
          }
        })
      }, 1000)
    }
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do colaborador</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cliente</td>
                        <td>{key['name']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Tipo de colaborador</td>
                        <td>{key['type']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">CPF</td>
                        <td>
                          <InputMask className='inputMaskPainel' mask="999.999.999-**" defaultValue={key['cpf']} disabled />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço</td>
                        <td>{key['street']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Número</td>
                        <td>{key['number']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Bairro</td>
                        <td>{key['neighborhood']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Complemento</td>
                        <td>{key['complement']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Ponto de referência</td>
                        <td>{key['reference_point']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Cidade</td>
                        <td>{key['city']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estado</td>
                        <td>{key['state']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail</td>
                        <td>{key['email']}</td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Celular</td>
                        <td> <InputMask className='inputMaskPainel' mask="(99) 99999-9999" defaultValue={key['phone']} disabled /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de nascimento</td>
                        <td><InputMask className='inputMaskPainel' mask="99/99/9999" defaultValue={key['birthdate']} disabled /></td>
                      </tr>
                    </tbody>
                  </Table>
                  <div style={{ width: '100%', display: 'none' }} id='divVehicleDebts'>
                    <div style={{ margin: '20px 0' }}>
                      <span className="labelConfirm">Despesas vinculadas</span>
                    </div>

                    <Table striped bordered hover className="tableProfile" style={{ margin: '0 10px', width: '98%' }}>
                      <thead>
                        <tr>
                          <th scope="col">Nome da despesa</th>
                          <th scope="col">Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          debts.map(k => {
                            if (k['category'] == 'colaborador') {
                              if (k['bond'] == idModal) {
                                return (
                                  <tr>
                                    <td className="labelConfirm">{k['name']}</td>
                                    <td>{k['value']}</td>
                                  </tr>
                                )
                              }
                            }
                          })

                        }
                      </tbody>
                      <tfoot>
                        <span>Veja com mais detalhes na área de <Link to='/despesas'>Despesas</Link></span>
                      </tfoot>
                    </Table>
                  </div>
                </>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  const handleMask = (e) => {
    let inputPhone = document.getElementById('celularCollaboratorEdit');
    let phoneMask = e.target.value
    phoneMask = phoneMask.replace(/\D/g, '')
    phoneMask = phoneMask.replace(/(\d{2})(\d)/, "($1) $2")
    phoneMask = phoneMask.replace(/(\d)(\d{4})$/, "$1-$2")

    inputPhone.value = phoneMask
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do colaborador</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              let phoneMask = key['phone'].replace(/\D/g, '').replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2")
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cliente:</td>
                        <td>
                          <input
                            required
                            name="nameCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      {key['type'] !== "superadmin" ? (
                        <tr>
                          <td className="labelConfirm">Tipo de colaborador:</td>
                          <td>
                            <Form.Select
                              name='typeCollaboratorEdit'
                              className="inputCadastro"
                            >
                              <option value='' >Selecione um colaborador</option>
                              <option value='admin' selected={key['type'] == 'admin'}>Administrador</option>
                              <option value='recepcao' selected={key['type'] == 'recepcao'}>Recepcionista</option>
                            </Form.Select>
                          </td>
                        </tr>
                      ) : ("")}
                      <tr>
                        <td className="labelConfirm">CPF:</td>
                        <td>
                          <InputMask className='inputCadastro' name="cpfCollaboratorEdit" mask="999.999.999-**" defaultValue={key['cpf']} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço</td>
                        <td>
                          <input
                            required
                            name="enderecoCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['street']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Número</td>
                        <td>
                          <input
                            required
                            name="numeroCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['number']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Bairro</td>
                        <td>
                          <input
                            required
                            name="bairroCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['neighborhood']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Complemento</td>
                        <td>
                          <input
                            name="complementoCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['complement']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Ponto de referência</td>
                        <td>
                          <input

                            name="referenciaCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['reference_point']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Cidade</td>
                        <td>
                          <input
                            required
                            name="cidadeCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['city']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estado</td>
                        <td>
                          <input
                            required
                            name="estadoCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['state']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail</td>
                        <td>
                          <input
                            required
                            name="emailCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Celular</td>
                        <td> <InputMask className='inputCadastro' name="celularCollaboratorEdit" id="celularCollaboratorEdit" onChange={handleMask} defaultValue={phoneMask} required /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de nascimento</td>
                        <td><InputMask className='inputCadastro' name="dataNascimentoCollaboratorEdit" mask="99/99/9999" defaultValue={key['birthdate']} /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação</td>
                        <td>
                          <Form.Control
                            className="textAreaForm"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='obsCollaboratorEdit'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir colaborador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o colaborador selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalSalary(props) {
    return (
      <Modal
        show={salaryModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Salário
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Mudar salário do colaborador</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalSalary}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Salário do colaborador:</td>
                        <td>
                          <CurrencyInput
                            required
                            name="salary"
                            className="inputCadastro"
                            defaultValue={key['salary']}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Dia de pagamento</td>
                        <td>
                          <input
                            required
                            name="salaryDay"
                            className="inputCadastro"
                            defaultValue={key['pay_day']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <input type='hidden' name="nameCollaborator" value={key['name']} />
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Confirmar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Colaborador", field: "collaborator" },
    { title: "Tipo", field: "type" },
    { title: "Endereço", field: "endereco" },
    { title: "Telefone", field: 'telefone', render: (rowData) => <InputMask className='inputMaskPainel' mask={rowData.telefone.length == 10 ? "(99) 9999-9999" : rowData.telefone.length == 11 ? "(99) 9 9999-9999" : ""} defaultValue={rowData.telefone} /> }
  ]

  rows.forEach(item => {
    collaborators.map(key => {
      if (key['id'] == item.endereco) {
        item.endereco = key['street'] + ", " + key['number'] + " - " + key['neighborhood'];
      }
    })
  })

  return (
    <>
      <ModalSalary />
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineUser className='perfilAction' />,
                tooltip: 'Perfil',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDollar className='perfilAction' />,
                tooltip: 'Vincular salário',
                onClick: (event, rowData) => {
                  setSalaryModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
          {/* <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row['id']);
                    const labelId = `enhanced-table-checkbox-${row['id']}`;
                    let dateDelivery = 'Ainda não foi entregue';
                    if (row.horarioDaEntrega !== null) {
                      dateDelivery = row.horarioDaEntrega
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row['id']}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className="clientTable"
                        >
                          {row.collaborator}
                        </TableCell>

                        <TableCell align="left">
                          {
                            row.tipo
                          }
                        </TableCell>

                        <TableCell align="left">
                          {
                            collaborators.map(key => {
                              if (key['id'] == row.endereco) {
                                return key['street'] + ", " + key['number'] + " - " + key['neighborhood']
                              }
                            })
                          }
                        </TableCell>

                        <TableCell align="left">
                          <InputMask className='inputMask' mask="(99) 9999-9999" value={row.telefone} disabled="disabled" />

                        </TableCell>

                        <TableCell align="left" >
                          <div className="buttonContainer">
                            <Tooltip title="Perfil" className='buttonActionTable' onClick={() => {
                              setPainelModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineUser className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Editar" className='buttonActionTable' onClick={() => {
                              setEditModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineEdit className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Vincular salário" className="buttonActionTable" onClick={() => {
                              setSalaryModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineDollar className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Deletar" className='buttonActionTable' onClick={() => {
                              setModalCancel(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineDelete className='perfilAction' />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </>
  );
}
