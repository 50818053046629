import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableResellerProfile.css'
import { Modal } from "react-bootstrap";
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { TbPhotoEdit } from "react-icons/tb";
import InputLabel from '@mui/material/InputLabel';
import { configurationTheme } from '../../redux/configurationTheme/actions';
import {
  Alert,
  Stack
} from '@mui/material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'resellerProfile',
    numeric: false,
    disablePadding: false,
    label: 'Perfil de revenda',
  },
  {
    id: 'installationType',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de instalação',
  },
  {
    id: 'distributor',
    numeric: false,
    disablePadding: false,
    label: 'Distribuidora',
  },
  {
    id: 'capacityKg',
    numeric: false,
    disablePadding: false,
    label: 'Capacidade em quilo',
  },
  {
    id: 'registry',
    numeric: false,
    disablePadding: false,
    label: 'Registro ANP',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [modalLogo, setModalLogo] = React.useState(false);
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [loginLogo, setLoginLogo] = useState();
  const [menuLogo, setMenuLogo] = useState();
  const [inputFileName, setInputFileName] = useState("");
  const [inputFileName2, setInputFileName2] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('error');

  const dispatch = useDispatch();

  React.useEffect(() => {
    api
      .get("/Configuracoes/" + currentUser.db)
      .then((response) => {
        setLoginLogo(response.data[0].logoLogin)
        setMenuLogo(response.data[0].logoMenu)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosPerfilRevendas" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data)
        setRows(response.data.map(key => ({
          id: key['id'], resellerName: key['reseller_name'], resellerProfile: key['name'], installationType: key['installation_type'], distributor: key['distributor'], capacityKg: key['capacity_kg'], registry: key['registry'], addressComplete: key['address'] + ', ' + key['number'] + ' - ' + key['neighborhood'] + ' - ' + key['city'] + '/' + key['state'], phone: key['phone'], whatsapp: key['whatsapp']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setModalLogo(false)
    setText("");
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let operationWeek = data.segSexOpen + ' - ' + data.segSexClose
    let operationWeekend = data.fimSemOpen + ' - ' + data.fimSemClose
    let operationHoliday = data.domFerOpen + ' - ' + data.domFerClose

    const dados = {
      id: idModal,
      name: data.resellerNameEdit,
      reseller_name: data.resellerEdit,
      installation_type: data.installationEdit,
      distributor: data.distributorEdit,
      capacity_kg: data.capacityKgEdit,
      registry: data.registryEdit,
      cep: data.cepEdit,
      address: data.addressEdit,
      number: data.numberEdit,
      neighborhood: data.neighborhoodEdit,
      city: data.cityEdit,
      state: data.stateEdit,
      phone: data.phoneEdit,
      whatsapp: data.whatsappEdit,
      email: data.emailEdit,
      site: data.siteEdit,
      social_media: data.socialMediaEdit,
      operation_week: operationWeek,
      operation_weekend: operationWeekend,
      operation_holiday: operationHoliday,
      db: currentUser.db
    }

    api.put("/AtualizarPerfilRevenda", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarPerfilRevenda/' + idModal + '/' + currentUser.db)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do perfil de revenda</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do responsável</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Perfil de revenda</td>
                      <td>{key['reseller_name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Distribuidora</td>
                      <td>{key['distributor']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Capacidade por quilo</td>
                      <td>{key['capacity_kg']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Registro ANP</td>
                      <td>{key['registry']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Horário de funcionamento semana</td>
                      <td>{key['operation_week']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Horário de funcionamento fim de semana</td>
                      <td>{key['operation_weekend']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Horário de funcionamento feriados</td>
                      <td>{key['operation_holiday']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">CEP</td>
                      <td>{key['cep']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Endereço</td>
                      <td>{key['address']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Número</td>
                      <td>{key['number']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Bairro</td>
                      <td>{key['neighborhood']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Cidade</td>
                      <td>{key['city']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Estado</td>
                      <td>{key['state']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Telefone Fixo</td>
                      <td>{key['phone']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Whatsapp</td>
                      <td>{key['whatsapp']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail</td>
                      <td>{key['email']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Site</td>
                      <td>{key['site']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Rede Social</td>
                      <td>{key['social_media']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  const checkCEP = (e) => {
    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length === 8) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => res.json()).then(data => {
          let estadoApi = '';
          if (data.uf == "AC") {
            estadoApi = "Acre";
          } else if (data.uf == "AL") {
            estadoApi = "Alagoas";
          } else if (data.uf == "AP") {
            estadoApi = "Amapá";
          } else if (data.uf == "AM") {
            estadoApi = "Amazonas";
          } else if (data.uf == "BA") {
            estadoApi = "Bahia";
          } else if (data.uf == "CE") {
            estadoApi = "Ceará";
          } else if (data.uf == "DF") {
            estadoApi = "Destrito Federal";
          } else if (data.uf == "ES") {
            estadoApi = "Espírito Santo";
          } else if (data.uf == "GO") {
            estadoApi = "Goiás";
          } else if (data.uf == "MA") {
            estadoApi = "Maranhão";
          } else if (data.uf == "MT") {
            estadoApi = "Mato Grosso";
          } else if (data.uf == "MS") {
            estadoApi = "Mato Grosso do Sul";
          } else if (data.uf == "MG") {
            estadoApi = "Minas Gerais";
          } else if (data.uf == "PA") {
            estadoApi = "Pará";
          } else if (data.uf == "PB") {
            estadoApi = "Paraíba";
          } else if (data.uf == "PR") {
            estadoApi = "Paraná";
          } else if (data.uf == "PE") {
            estadoApi = "Pernambuco";
          } else if (data.uf == "PI") {
            estadoApi = "Piauí";
          } else if (data.uf == "RJ") {
            estadoApi = "Rio de Janeiro";
          } else if (data.uf == "RN") {
            estadoApi = "Rio Grande do Norte";
          } else if (data.uf == "RS") {
            estadoApi = "Rio Grande do Sul";
          } else if (data.uf == "RO") {
            estadoApi = "Rondônia";
          } else if (data.uf == "RR") {
            estadoApi = "Roraima";
          } else if (data.uf == "SC") {
            estadoApi = "Santa Catarina";
          } else if (data.uf == "SP") {
            estadoApi = "São Paulo";
          } else if (data.uf == "SE") {
            estadoApi = "Sergipe";
          } else if (data.uf == "TO") {
            estadoApi = "Tocantins";
          }

          document.getElementById("addressEdit").value = data.logradouro
          document.getElementById("neighborhoodEdit").value = data.bairro
          document.getElementById("cityEdit").value = data.localidade
          document.getElementById("stateEdit").value = estadoApi
        })
    }

  }

  const handleMaskPhone = (e) => {
    if (e.target.value.length <= 14) {
      e.target.value = e.target.value.replace(/\D/g, "")
      e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
      e.target.value = e.target.value.replace(/(\d{4})(\d)/, "$1-$2")
      // setPhone(e.target.value.replace(/\D/g, ""))
    } else {
      e.target.value = e.target.value.replace(/\D/g, "")
      e.target.value = e.target.value.replace(/^(\d\d)(\d)/g, "($1) $2")
      e.target.value = e.target.value.replace(/(\d{1})(\d{4})(\d)/, "$1 $2-$3")
      // setPhone(e.target.value.replace(/\D/g, ""))
    }
  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil de revenda
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do perfil de revenda</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              let operationWeek = ['', ''];
              let operationWeekend = ['', ''];
              let operationHoliday = ['', ''];
              if (key['operation_week'] !== null && key['operation_weekend'] !== null && key['operation_holiday'] !== null) {
                operationWeek = key['operation_week'].split(' - ');
                operationWeekend = key['operation_weekend'].split(' - ');
                operationHoliday = key['operation_holiday'].split(' - ');
              }

              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do responsável da revenda:</td>
                        <td>
                          <input
                            required
                            name="resellerNameEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Perfil de revenda:</td>
                        <td>
                          <input
                            required
                            name="resellerEdit"
                            className="inputCadastro"
                            defaultValue={key['reseller_name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Tipo de instalação:</td>
                        <td>
                          <input
                            required
                            name="installationEdit"
                            className="inputCadastro"
                            defaultValue={key['installation_type']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Distribuidora:</td>
                        <td>
                          <input
                            required
                            name="distributorEdit"
                            className="inputCadastro"
                            defaultValue={key['distributor']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Capacidade por quilo:</td>
                        <td>
                          <input
                            required
                            name="capacityKgEdit"
                            className="inputCadastro"
                            defaultValue={key['capacity_kg']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Registro ANP:</td>
                        <td>
                          <input
                            required
                            name="registryEdit"
                            className="inputCadastro"
                            defaultValue={key['registry']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Horário de funcionamento semana:</td>
                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="segSexOpen" mask="99:99" name="segSexOpen" defaultValue={operationWeek[0].toString() !== '00:__' ? operationWeek[0].toString() : ''} required />
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="segSexClose" mask="99:99" name="segSexClose" defaultValue={operationWeek[1].toString() !== '00:__' ? operationWeek[1].toString() : ''} required />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Horário de funcionamento fim de semana:</td>
                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="fimSemOpen" mask="99:99" name="fimSemOpen" defaultValue={operationWeekend[0].toString() !== '00:__' ? operationWeekend[0].toString() : ''} required />
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="fimSemClose" mask="99:99" name="fimSemClose" defaultValue={operationWeekend[1].toString() !== '00:__' ? operationWeekend[1].toString() : ''} required />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Horário de funcionamento domingos e feriados:</td>
                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="domFerOpen" mask="99:99" name="domFerOpen" defaultValue={operationHoliday[0].toString() !== '00:__' ? operationHoliday[0].toString() : ''} required />
                          <InputMask className="inputCadastro" style={{ marginBottom: 10, width: '48%' }} id="domFerClose" mask="99:99" name="domFerClose" defaultValue={operationHoliday[1].toString() !== '00:__' ? operationHoliday[1].toString() : ''} required />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">CEP:</td>
                        <td>
                          <InputMask className="inputCadastro" id="cepEdit" mask="99999-999" name="cepEdit" defaultValue={key['cep']} onChange={checkCEP} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço:</td>
                        <td>
                          <input
                            required
                            id="addressEdit"
                            name="addressEdit"
                            className="inputCadastro"
                            defaultValue={key['address']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Número:</td>
                        <td>
                          <input
                            required
                            name="numberEdit"
                            className="inputCadastro"
                            defaultValue={key['number']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Bairro:</td>
                        <td>
                          <input
                            required
                            id="neighborhoodEdit"
                            name="neighborhoodEdit"
                            className="inputCadastro"
                            defaultValue={key['neighborhood']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Cidade:</td>
                        <td>
                          <input
                            required
                            id="cityEdit"
                            name="cityEdit"
                            className="inputCadastro"
                            defaultValue={key['city']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estado:</td>
                        <td>
                          <input
                            required
                            id="stateEdit"
                            name="stateEdit"
                            className="inputCadastro"
                            defaultValue={key['state']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Telefone Fixo:</td>
                        <td>
                          <input
                            required
                            name="phoneEdit"
                            className="inputCadastro"
                            id="phoneEdit"
                            onChange={handleMaskPhone}
                            defaultValue={key['phone']}
                            maxLength={15}
                          />
                          {/* <InputMask required className='inputCadastro' id="phoneEdit" onChange={e => handleMaskPhone(e)} name="phoneEdit" defaultValue={key['phone']} /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Whatsapp:</td>
                        <td>
                          <InputMask required className='inputCadastro' id="whatsappEdit" mask="(99) 9 9999-9999" name="whatsappEdit" defaultValue={key['whatsapp']} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail:</td>
                        <td>
                          <input
                            required
                            name="emailEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Site:</td>
                        <td>
                          <input
                            name="siteEdit"
                            className="inputCadastro"
                            defaultValue={key['site']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Rede Social:</td>
                        <td>
                          <input
                            name="socialMediaEdit"
                            className="inputCadastro"
                            defaultValue={key['social_media']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    let dados = {
    }

    dados = {
      id: idModal,
      db: currentUser.db,
      'image[]': [data.logoLogin, data.logoMenu],
      logoLogin: "https://api.clubedorevendedordegas.com.br/files/logo/",
      logoMenu: "https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoMenu.name,
    }
    setLoginLogo("https://api.clubedorevendedordegas.com.br/files/logo/");
    setMenuLogo("https://api.clubedorevendedordegas.com.br/files/logo/" + data.logoMenu.name)


    api.put("/AtualizarConfiguracoes", dados, {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    })

    dispatch(configurationTheme({ logoLogin: '', logoMenu: menuLogo }));

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  function ModalLogo(props) {
    return (
      <Modal
        show={modalLogo}
        {...props}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Logo da Revenda
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={submitForm}>
            <div className="container2itens">
              {/* <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column' }}>
                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Logo da tela de login </InputLabel>
                <img src={loginLogo} className="imgProfile" id="logoLogin" />
                <input
                  type="file"
                  name="logoLogin"
                  style={{ marginLeft: 10 }}
                  onChange={(e) => {
                    // setInputFileName(e.target.files[0])
                    mostraImagem(e.target)
                  }}
                />
                <InputLabel id="filtro-periudo" style={{ marginLeft: 10, marginTop: 10 }}>Tamanho recomendado da imagem é de 779 x 619</InputLabel>
              </div> */}

              <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column' }}>
                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Logo do menu </InputLabel>
                <img src={menuLogo} className="imgProfile" id="logoMenu" />
                <input
                  type="file"
                  name="logoMenu"
                  style={{ marginLeft: 10 }}
                  onChange={(e) => {
                    // setInputFileName2(e.target.files[0])
                    mostraImagem2(e.target)
                  }}
                />
                <InputLabel id="filtro-periudo" style={{ marginLeft: 10, marginTop: 10 }}>Tamanho recomendado da imagem é de 500 x 500</InputLabel>
                <InputLabel id="filtro-periudo" className='avisoTamanho' style={{ marginLeft: 10, marginTop: 10, display: 'none', color: 'red', fontWeight: 'bold' }}>A imagem é maior que 500x500! Insira uma imagem menor</InputLabel>
              </div>
            </div>
            <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10 }}>
              <button className="buttonSecundary updateLogo">
                Atualizar Logo
              </button>
            </div>
          </form>

        </Modal.Body>
      </Modal>
    )
  }

  function mostraImagem(img) {
    if (img.files && img.files[0]) {
      var reader = new FileReader();
      var imagem = document.getElementById("logoLogin");
      reader.onload = function (e) {
        imagem.src = e.target.result;
      };

      reader.readAsDataURL(img.files[0]);
    }
  }

  function mostraImagem2(img) {
    const imgId = document.getElementById('logoMenu');
    const aviso = document.getElementsByClassName('avisoTamanho')[0]
    const btnUpdate = document.getElementsByClassName('updateLogo')[0]

    imgId.onload = function () {
      const height = this.naturalHeight;
      const width = this.naturalWidth;

      if (height <= 1080 && width <= 1080) {
        btnUpdate.style.display = 'block'
        aviso.style.display = 'none'
      } else {
        aviso.style.display = 'block'
        btnUpdate.style.display = 'none'
      }
    }

    if (img.files && img.files[0]) {
      var reader = new FileReader();
      var imagem = document.getElementById("logoMenu");
      reader.onload = function (e) {
        imagem.src = e.target.result;
      };

      reader.readAsDataURL(img.files[0]);
    }
  }

  const [open, setOpen] = useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Responsável pela revenda", field: "resellerProfile" },
    { title: "Perfil de revenda", field: "resellerName" },
    { title: "Tipo de instalação", field: "installationType" },
    { title: "Distribuidora", field: "distributor" },
    { title: "Capacidade em quilo", field: "capacityKg" },
    { title: "Registro ANP", field: "registry" },
    { title: "Endereço", field: "addressComplete" },
    { title: "Telefone Fixo", field: "phone" },
    { title: "Whatsapp", field: "whatsapp" }
  ]

  return (
    <>
      <Loading />
      <ModalPainel />
      <ModalEdit />
      <ModalLogo />
      <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
        <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
      </Stack>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineUser className='perfilAction' />,
                tooltip: 'Perfil',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <TbPhotoEdit className='perfilAction' />,
                tooltip: 'Logo',
                onClick: (event, rowData) => {
                  setModalLogo(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
              search: false
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
          {/* <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row['id']);
                    const labelId = `enhanced-table-checkbox-${row['id']}`;
                    let dateDelivery = 'Ainda não foi entregue';
                    if (row.horarioDaEntrega !== null) {
                      dateDelivery = row.horarioDaEntrega
                    }
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row['id']}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="left"
                          className="clientTable"
                        >
                          {row.reseller}
                        </TableCell>

                        <TableCell align="left">
                          {row.installation}
                        </TableCell>

                        <TableCell align="left">
                          {row.distributor}
                        </TableCell>

                        <TableCell align="left">
                          {row.capacity_kg}
                        </TableCell>

                        <TableCell align="left" >
                          {row.registry}
                        </TableCell>
                        
                        <TableCell align="left" >
                          <div className="buttonContainer">
                            <Tooltip title="Perfil" className='buttonActionTable' onClick={() => {
                              setPainelModal(true)
                              setIdModal(row.id)
                            }}>
                              <AiOutlineUser className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Editar" className='buttonActionTable' onClick={() => {
                              setEditModal(true)
                              setIdModal(row.id)
                              setText("Atualizar");
                            }}>
                              <AiOutlineEdit className='perfilAction' />
                            </Tooltip>
                            <Tooltip title="Deletar" className='buttonActionTable' onClick={() => {
                              setModalCancel(true)
                              setIdModal(row.id)
                              setText('Sim')
                            }}>
                              <AiOutlineDelete className='perfilAction' />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    </>
  );
}
