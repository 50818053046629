import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableLogStockProduct.css'
import { Modal } from "react-bootstrap";
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { useSelector, useDispatch } from 'react-redux';
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import Form from 'react-bootstrap/Form';
import { TbEyeSearch } from "react-icons/tb";

export default function EnhancedTable() {
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [cupons, setCupons] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [collaborators, setCollaborators] = React.useState([])

  React.useEffect(() => {
    api
      .get("/TodosLogEstoqueProduto" + '/' + currentUser.db)
      .then((response) => {
        setCupons(response.data);
        setRows(response.data.reverse().map(key => ({
          id: key['id'], user_id: key['user_id'], action: key['action'], stock: key['stock'], stock_full: key['stock_full'], stock_empty: key['stock_empty'], stock_complete: key['stock_complete'], date_alter: key['date_alter']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosColaboradores" + '/' + currentUser.db)
      .then((response) => {
        setCollaborators(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const columns = [
    {
      title: "Usuário", field: "user_id", render: (rowData) => {
        let name = '';
        collaborators.map(key => {
          if (key['id'] == currentUser.id) {
            name = key['name']
          }
        })
        return (
          <span>{name}</span>
        )
      }
    },
    { title: "Ação", field: "action" },
    { title: "Estoque", field: "stock" },
    { title: "Estoque Cheio", field: "stock_full" },
    { title: "Estoque Vazio", field: "stock_empty" },
    // { title: "Estoque Completo", field: "stock_complete" },
    { title: "Data", field: "date_alter" }
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
