import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import api from "../../service/api";
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Select2 from 'react-select'
import Button from '@mui/material/Button';
import './styles.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import load from '../../image/load.gif'
import MaterialTable from 'material-table'
import InputMask from 'react-input-mask';
import 'rsuite/dist/rsuite.min.css'
import pt_BR from 'rsuite/locales/pt_BR'
import { CustomProvider, DateRangePicker } from 'rsuite';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeAnimated from 'react-select/animated';
import { CurrencyInput } from 'react-currency-mask';

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [driver, setDriver] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [clientPhysic, setClientPhysic] = React.useState([]);
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [payment, setPayment] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [saleChanell, setSaleChanell] = React.useState([]);
  const [profileOrder, setProfileOrder] = React.useState([])
  const [actions, setActions] = React.useState('');
  const [rowsSelected, setRowsSelected] = React.useState([])
  const [modalCancel, setModalCancel] = React.useState(false);
  const [modalDriver, setModalDriver] = React.useState(false);
  const [modalStatus, setModalStatus] = React.useState(false);
  const [modalProfile, setModalProfile] = React.useState(false);
  const [text, setText] = React.useState();
  const [arrayRows, setArrayRows] = React.useState();
  const [filterDate1, setFilterDate1] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState('');
  const [alert, setAlert] = React.useState('Filtre por data');
  const [filterDriver, setFilterDriver] = React.useState('all');
  const [filterSaleChannel, setFilterSaleChannel] = React.useState('all');
  const [filterRangeDate, setFilterRangeDate] = React.useState('')
  const [filterStatus, setFilterStatus] = React.useState('all')
  const [typePayment, setTypePayment] = React.useState(false);
  const [orderedSelected, setOrderedSelected] = React.useState([])
  const [whatsModal, setWhatsModal] = React.useState(false);
  const [orderedModal, setOrderedModal] = React.useState(false)
  const [optionsProducts, setOptionsProducts] = React.useState([]);
  const [categoryProducts, setCategoryProducts] = React.useState([])
  const [nameUser, setNameUser] = React.useState('')
  const [stockLinked, setStockLinked] = React.useState([])

  const animatedComponents = makeAnimated();

  let whatsPhone = '';
  let productValue = '';
  let productId = [];
  let productName = [];
  let valueOriginal = 0;
  let typeCliente = ''

  const statusOrder = [
    "Recebido", "Entregador Notificado", "Saiu para entrega", "Finalizado", "Finalizado, porém em aberto"]

  const date = new Date()
  const todayDate = date.toLocaleDateString("pt-BR")
  const todayTime = date.toLocaleTimeString("pt-BR")
  const today = todayDate + " " + todayTime;

  const handleModalClose = () => {
    setModalCancel(false)
    setModalDriver(false)
    setModalStatus(false)
    setModalProfile(false)
    setWhatsModal(false)
    setOrderedModal(false)
    setActions('')
  };

  const handleHiddenPayment = e => {
    var payment2Container = document.getElementById('payment2Container')
    var inputPayment2 = document.getElementById('inputPayment2')
    var paymetValueInput2 = document.getElementById('paymetValueInput2');
    var payment3Container = document.getElementById('payment3Container')
    var inputPayment3 = document.getElementById('inputPayment3')
    var paymetValueInput3 = document.getElementById('paymetValueInput3');
    var paymetValueInput = document.getElementById('paymetValueInput');
    var paymentValueContainer = document.getElementById('paymentValueContainer');
    if (productValue !== '') {
      paymetValueInput.value = productValue
    } else {
      paymetValueInput.value = orderedSelected['payment_value2'] !== null && orderedSelected['payment_value2'] !== undefined && orderedSelected['payment_value2'] !== '0' ? orderedSelected['payment_value'] : orderedSelected['total']
    }

    if (e == '2') {
      payment2Container.style.display = "none";
      inputPayment2.value = '';
      payment3Container.style.display = "none";
      inputPayment3.value = '';
      paymetValueInput2.disabled = true
      paymetValueInput3.disabled = true
      paymetValueInput2.placeholder = '0'
      paymetValueInput3.placeholder = '0'
      paymentValueContainer.style.marginRight = ''

      if (productValue !== '') {
        paymetValueInput.value = productValue
      } else {
        paymetValueInput.value = orderedSelected['total']
      }

    }

    if (e == '3') {
      payment3Container.style.display = "none";
      inputPayment3.value = '';
      paymetValueInput3.disabled = true
      paymetValueInput3.placeholder = '0'
      paymetValueInput.value = parseFloat(orderedSelected['total']) - (paymetValueInput2.value !== '' ? parseFloat(paymetValueInput2.value) : 0)
    }
  }

  const productHandler = e => {
    var divProducSelected1 = document.getElementById('productSelect1')
    var divProducSelected2 = document.getElementById('productSelect2')
    var divProducSelected3 = document.getElementById('productSelect3')
    var labelProducSelected1 = document.getElementById('labelProductSelect1')
    var labelProducSelected2 = document.getElementById('labelProductSelect2')
    var labelProducSelected3 = document.getElementById('labelProductSelect3')
    var paymetValueInput = document.getElementById('paymetValueInput');
    var inputProduct1 = document.getElementById("inputProductSelect1").value
    var inputProduct2 = document.getElementById("inputProductSelect2").value
    var inputProduct3 = document.getElementById("inputProductSelect3").value
    var options = e;
    var value = [];
    var name = [];

    if (Number(inputProduct1) >= 1) {
      if (options.length !== 'undefined' && options.length !== undefined) {
        for (var i = 0, l = options.length; i < l; i++) {
          if (typeof options[i].value == "number") {
            value.push(options[i].value);
          }
          if (i == 0) {
            divProducSelected1.style.display = "block"
            divProducSelected2.style.display = "none"
            divProducSelected3.style.display = "none"
            if (typeof options[i].value == "number") {
              name.push(options[i].label)
              labelProducSelected1.textContent = options[i].label + ":"
            } else {
              var nameProduct1 = labelProducSelected1.textContent.split(':')
              name.push(nameProduct1[0])
            }

          }
          if (i == 1) {
            divProducSelected2.style.display = "block"
            divProducSelected3.style.display = "none"
            if (typeof options[i].value == "number") {
              name.push(options[i].label)
              labelProducSelected2.textContent = options[i].label + ":"
            } else {
              var nameProduct2 = labelProducSelected2.textContent.split(':')
              name.push(nameProduct2[0])
            }
          }
          if (i == 2) {
            divProducSelected3.style.display = "block"
            if (typeof options[i].value == "number") {
              name.push(options[i].label)
              labelProducSelected3.textContent = options[i].label + ":"
            } else {
              var nameProduct3 = labelProducSelected3.textContent.split(':')
              name.push(nameProduct3[0])
            }
          }
        }
      } else {
        var nameProduct1 = labelProducSelected1.textContent.split(':')
        name.push(nameProduct1[0].substring(1))
        if (labelProducSelected2.textContent !== '') {
          var nameProduct2 = labelProducSelected2.textContent.split(':')
          name.push(nameProduct2[0].substring(1))
        }
        if (labelProducSelected3.textContent !== '') {
          var nameProduct3 = labelProducSelected3.textContent.split(':')
          name.push(nameProduct3[0].substring(1))
        }
      }

    } else {
      var nameProduct1 = labelProducSelected1.textContent.split(':')
      name.push(nameProduct1[0].substring(1))
      if (labelProducSelected2.textContent !== '') {
        var nameProduct2 = labelProducSelected2.textContent.split(':')
        name.push(nameProduct2[0].substring(1))
      }
      if (labelProducSelected3.textContent !== '') {
        var nameProduct3 = labelProducSelected3.textContent.split(':')
        name.push(nameProduct3[0].substring(1))
      }
    }

    if (options.length == 0) {
      divProducSelected1.style.display = "none"
    }

    var total = [];
    let sumTotal = 0;
    var spanTotal = document.getElementById('productTotal');

    for (var i = 0, l = name.length; i < l; i++) {
      product.map(key => {
        if (key['name'] == name[i]) {
          let valueFormat = key['value'].replace('R$', '').replace('.', '').replace(',', '.');

          if (i == 0) {
            let multTotal = parseFloat(inputProduct1) * parseFloat(valueFormat)
            productId.push(key.id)
            total.push(multTotal)
          }
          if (i == 1) {
            let multTotal = parseFloat(inputProduct2) * parseFloat(valueFormat)
            total.push(multTotal)
            productId.push(key.id)
          }
          if (i == 2) {
            let multTotal = parseFloat(inputProduct3) * parseFloat(valueFormat)
            total.push(multTotal)
            productId.push(key.id)
          }
        }
      })
    }
    for (let i = 0; i < total.length; i++) {
      sumTotal += parseFloat(total[i])
    }
    productValue = sumTotal;
    productName = name

    spanTotal.textContent = sumTotal
    paymetValueInput.value = sumTotal
    valueOriginal = sumTotal
  }

  const paymentHandler = e => {
    var divPayment = document.getElementById("obsPayment");
    var inputPayment = document.getElementById("inputPayment");
    var payment2Container = document.getElementById('payment2Container')
    var inputPayment2 = document.getElementById('inputPayment2')
    var payment3Container = document.getElementById('payment3Container')
    var inputPayment3 = document.getElementById('inputPayment3')
    var paymetValueInput2 = document.getElementById('paymetValueInput2');
    var paymetValueInput3 = document.getElementById('paymetValueInput3');
    var paymentValueContainer = document.getElementById('paymentValueContainer');
    let paymentSelected = false

    payment.map(key => {
      if (key['id'] == e.target.value) {
        if (key['name'] == 'Dinheiro') {
          paymentSelected = true;
        }
      }
    })

    if (e.target.value !== '') {
      payment2Container.style.display = "flex"
      payment2Container.style.justifyContent = 'space-between'
      paymentValueContainer.style.marginRight = '35px'
    } else {
      payment2Container.style.display = "none";
      inputPayment2.value = '';
      payment3Container.style.display = "none";
      inputPayment3.value = '';
      paymetValueInput2.disabled = true
      paymetValueInput3.disabled = true
      paymetValueInput2.placeholder = '0'
      paymetValueInput3.placeholder = '0'
      paymentValueContainer.style.marginRight = ''
    }

    if (paymentSelected) {
      divPayment.setAttribute("style", 'display: block')
      // inputPayment.setAttribute('required', 'required')
    } else {
      divPayment.setAttribute("style", 'display: none')
      // inputPayment.removeAttribute('required', 'required')
    }
  }

  const paymentHandler2 = e => {
    var payment3Container = document.getElementById('payment3Container')
    var inputPayment3 = document.getElementById('inputPayment3')
    var paymetValueInput2 = document.getElementById('paymetValueInput2');


    if (e.target.value !== '') {
      payment3Container.style.display = "flex"
      payment3Container.style.justifyContent = 'space-between'
      paymetValueInput2.disabled = false
      paymetValueInput2.placeholder = 'Digite o segundo valor'
    } else {
      payment3Container.style.display = "none";
      inputPayment3.value = '';
      paymetValueInput2.disabled = true
      paymetValueInput2.placeholder = '0'

    }
  }

  const handleValueTotal = e => {
    var paymetValueInput = document.getElementById('paymetValueInput');
    var paymetValueInput2 = document.getElementById('paymetValueInput2');
    var paymetValueInput3 = document.getElementById('paymetValueInput3');
    paymetValueInput.value = valueOriginal

    if (paymetValueInput2.value !== '') {
      paymetValueInput.value = parseFloat(paymetValueInput.value) - parseFloat(paymetValueInput2.value)
    }

    if (paymetValueInput3.value !== '') {
      paymetValueInput.value = parseFloat(paymetValueInput.value) - parseFloat(paymetValueInput2.value) - parseFloat(paymetValueInput3.value)
    }
  }

  const paymentHandler3 = e => {
    var paymetValueInput3 = document.getElementById('paymetValueInput3');

    if (e.target.value !== '') {
      paymetValueInput3.disabled = false
      paymetValueInput3.placeholder = 'Digite o terceiro valor'
    } else {
      paymetValueInput3.disabled = true
      paymetValueInput3.placeholder = '0'
    }

  }

  const descontoHandler = e => {
    var type = e.target.value;
    var divDesconto = document.getElementById('descontoContainer');
    var inputDesconto = document.getElementsByName('inputDesconto');

    if (type == 'sim') {
      divDesconto.style.display = "block"
    } else {
      divDesconto.style.display = "none"
      inputDesconto[0].value = ''
    }

  }

  const trocoHandler = e => {
    var troco = e.target.value;
    var divTroco = document.getElementById('divTroco');
    if (troco == 'sim') {
      divTroco.style.display = 'block';
    } else {
      divTroco.style.display = 'none';
    }
  }

  const submitDriver = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let message = data.mesageWhats;
    let phoneDriver = '';
    try {
      arrayRows.map((k, index) => {
        api.put('/AtualizarPedido', {
          id: k,
          driver_id: driverSelected,
          status: "Entregador notificado",
          db: currentUser.db
        })
        profileOrder.map(key => {
          if (key['id'] == k) {
            if (key['player_id'] !== null) {
              api.post("/Notificar", {
                app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
                contents: {
                  en: "Oba, seu pedido está a caminho! 🚙"
                },
                include_player_ids: [key['player_id']]
              }).then(response => {
                // console.log(response)
              }).catch(err => {
                console.log(err)
              })
            }
          }
        })
      })
      // driver.map(key => {
      //   if (key['id'] == driverSelected) {
      //     api.post("/Notificar", {
      //       app_id: "2791db33-df64-44ca-917f-bea5142e9d05",
      //       contents: {
      //         en: "Novo pedido para entrega 👏🚙😉"
      //       },
      //       include_player_ids: [key['player_id']]
      //     }).then(response => {
      //       // console.log(response)
      //     }).catch(err => {
      //       console.log(err)
      //     })
      //   }
      // })
      driver.map(key => {
        if (key['id'] == driverSelected) {
          phoneDriver = key['phone']
        }
      })
      let messageFormated = [];

      // message.map(m => {
      //   if (m.split('\n').length > 1) {
      //     messageFormated.push(m)
      //   } else {
      //     messageFormated.push(m)
      //   }
      // })
      axios
        .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
          {
            number: "55" + phoneDriver + "@s.whatsapp.net",
            textMessage: {
              text: message
            },
            delay: 1200
          }, {
          headers: {
            'Content-Type': 'application/json',
            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          let ddd = String(phoneDriver[0]) + String(phoneDriver[1]);
          phoneDriver = phoneDriver.slice(3);

          axios
            .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
              {
                number: "55" + ddd + phoneDriver + "@s.whatsapp.net",
                textMessage: {
                  text: message
                },
                delay: 1200
              }, {
              headers: {
                'Content-Type': 'application/json',
                'apikey': 'B6D711FCDE4D4FD5936544120E713976'
              }
            })
            .then((response) => {
              // console.log(response);
            })
            .catch((err) => {
              console.log(err);
            })
        })
    } catch (err) {

    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setProduct(response.data);
        setOptionsProducts(response.data.sort((a, b) => a.order - b.order).map(key => ({
          value: key['id'],
          label: key['name']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const submitMessageWhats = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageWhats.split('\n \n')
    let messageFormated = [];
    message.map(m => {
      if (m.split('\n').length > 1) {
        messageFormated.push(m.split('\n').join('%0D'))
      } else {
        messageFormated.push(m)
      }
    })


    axios
      .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
        {
          number: "55" + whatsPhone + "@s.whatsapp.net",
          textMessage: {
            text: messageFormated.join('%0D%0D')
          },
          delay: 1200
        }, {
        headers: {
          'Content-Type': 'application/json',
          'apikey': 'B6D711FCDE4D4FD5936544120E713976'
        }
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        let ddd = String(whatsPhone[0]) + String(whatsPhone[1]);
        whatsPhone = whatsPhone.slice(3);

        axios
          .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
            {
              number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
              textMessage: {
                text: message
              },
              delay: 1200
            }, {
            headers: {
              'Content-Type': 'application/json',
              'apikey': 'B6D711FCDE4D4FD5936544120E713976'
            }
          })
          .then((response) => {
            // console.log(response);
          })
          .catch((err) => {
            console.log(err);
          })
      })


    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitStatusOrder = async (e) => {
    e.preventDefault();
    let notification = '';

    if (statusOrderSelected == 'Finalizado' || statusOrderSelected == 'Finalizado, porém em aberto') {
      notification = "Até a próxima, seu pedido foi finalizado! 😉"
    }
    try {
      arrayRows.map((k, index) => {
        api.put('/AtualizarPedido', {
          id: k,
          status: statusOrderSelected,
          delivery_date: today,
          db: currentUser.db
        })
          .then(response => {
            if (statusOrderSelected == 'Finalizado' || statusOrderSelected == 'Finalizado, porém em aberto') {

              api.get(`/Pedido/${k}/${currentUser.db}`)
                .then(response => {
                  let productsOrder = response.data[0].product_id.split(',');
                  let productsOrderQnt = response.data[0].products.split(',');
                  let stock_full = 0
                  let stock_empty = 0
                  let stock_complete = 0
                  let orderedComplete = 0;
                  let stock = 0;
                  let dentro = false;
                  let acessory = false;

                  if (response.data[0].status !== 'Finalizado, porém em aberto' || response.data[0].status !== 'Finalizado') {

                    productsOrder.map(po => {
                      productsOrderQnt.map(poq => {
                        let splitProduct = poq.split('x')

                        api
                          .get(`/EstoqueVinculado/${po}/${currentUser.db}`)
                          .then(response => {
                            let res = response.data.length;

                            if (res > 0) {
                              let linkedProducts = response.data[0].linked_products;
                              let linkedProductsSplit = linkedProducts.split(',')

                              if (Number(response.data[0].product_id) == Number(po)) {
                                stock_full = response.data[0].stock_full
                                stock_empty = response.data[0].stock_empty
                                stock_complete = response.data[0].stock_complete == '' ? 0 : response.data[0].stock_complete
                                stock = response.data[0].stock
                                product.map(p => {
                                  //   if (p['id'] == Number(response.data[0].product_id)) {
                                  // }

                                  if (p['id'] == Number(po) && p['name'] == splitProduct[1].substring(1)) {
                                    categoryProducts.map(cp => {
                                      if (cp['id'] == Number(p['category_id'])) {
                                        if (cp['name'] == 'Gás - Completo' || cp['name'] == 'Água - Completo') {
                                          orderedComplete = splitProduct[0]
                                        }
                                        if (cp['name'] == 'Acessórios') {
                                          acessory = true
                                        }
                                      }
                                    })
                                  }
                                })

                                setTimeout(() => {
                                  let dados = {
                                    id: response.data[0].product_id,
                                    stock_full: Number(stock_full) - Number(splitProduct[0]),
                                    stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                    stock_complete: Number(stock_complete) + Number(orderedComplete),
                                    db: currentUser.db
                                  }

                                  if (Number(orderedComplete) > 0) {
                                    dados = {
                                      id: response.data[0].product_id,
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: Number(stock_full) - Number(splitProduct[0]),
                                      stock_empty: Number(stock_empty),
                                      stock_complete: Number(stock_complete) + Number(orderedComplete),
                                      db: currentUser.db
                                    }
                                  }

                                  if (acessory) {
                                    dados = {
                                      id: Number(po),
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: 0,
                                      stock_empty: 0,
                                      stock_complete: 0,
                                      db: currentUser.db
                                    }
                                  }

                                  // api.put("/AtualizarProduto", dados)
                                  //   .then(response => {
                                  //     console.log(response)
                                  //   }).catch(err => {
                                  //     console.log(err)
                                  //   })
                                  let dados2 = {
                                    id: response.data[0].product_id,
                                    stock_full: Number(stock_full) - Number(splitProduct[0]),
                                    stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                    stock_complete: Number(stock_complete) + Number(orderedComplete),
                                    db: currentUser.db
                                  }

                                  if (Number(orderedComplete) > 0) {
                                    dados2 = {
                                      id: response.data[0].product_id,
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: Number(stock_full) - Number(splitProduct[0]),
                                      stock_empty: Number(stock_empty),
                                      stock_complete: Number(stock_complete) + Number(orderedComplete),
                                      db: currentUser.db
                                    }
                                  }

                                  if (acessory) {
                                    dados2 = {
                                      id: response.data[0].product_id,
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: Number(stock_full),
                                      stock_empty: Number(stock_empty),
                                      stock_complete: Number(stock_complete),
                                      db: currentUser.db
                                    }
                                  }

                                  api.put("/AtualizarEstoqueVinculado", dados2)
                                    .then(response => {
                                      console.log(response.data)
                                    }).catch(err => {
                                      console.log(err)
                                    })

                                  let dados3 = {
                                    user_id: currentUser.id,
                                    action: `Pedido ${k}`,
                                    stock: Number(stock),
                                    stock_full: Number(stock_full) - Number(splitProduct[0]),
                                    stock_empty: Number(stock_empty) + Number(orderedComplete),
                                    stock_complete: Number(stock_complete),
                                    date_alter: today,
                                    db: currentUser.db
                                  }

                                  if (Number(orderedComplete) > 0) {
                                    dados3 = {
                                      user_id: currentUser.id,
                                      action: `Pedido ${k}`,
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: Number(stock_full) - Number(splitProduct[0]),
                                      stock_empty: Number(stock_empty),
                                      stock_complete: Number(stock_complete) + Number(orderedComplete),
                                      date_alter: today,
                                      db: currentUser.db
                                    }
                                  }

                                  if (acessory) {
                                    dados3 = {
                                      user_id: currentUser.id,
                                      action: `Pedido ${k}`,
                                      stock: Number(stock) - Number(splitProduct[0]),
                                      stock_full: Number(stock_full),
                                      stock_empty: Number(stock_empty),
                                      stock_complete: Number(stock_complete),
                                      date_alter: today,
                                      db: currentUser.db
                                    }
                                  }

                                  api.post("/CadastrarLogEstoque", dados3)
                                    .then(response => {
                                      console.log(response.data)
                                    }).catch(err => {
                                      console.log(err)
                                    })
                                }, 1000)

                              } else {
                                linkedProductsSplit.map(lp => {
                                  if (Number(lp) == Number(po)) {
                                    dentro = true
                                  }
                                })
                                setTimeout(() => {

                                  if (dentro) {
                                    stock_full = response.data[0].stock_full
                                    stock_empty = response.data[0].stock_empty
                                    stock_complete = response.data[0].stock_complete == '' ? 0 : response.data[0].stock_complete
                                    stock = response.data[0].stock
                                    product.map(p => {
                                      // if (p['id'] == Number(response.data[0].product_id)) {
                                      //   stock_full = p['stock_full']
                                      //   stock_empty = p['stock_empty']
                                      //   stock_complete = p['stock_complete'] == '' ? 0 : p['stock_complete']
                                      //   stock = p['stock']
                                      // }

                                      if (p['id'] == Number(po) && p['name'] == splitProduct[1].substring(1)) {
                                        categoryProducts.map(cp => {
                                          if (cp['id'] == Number(p['category_id'])) {
                                            if (cp['name'] == 'Gás - Completo' || cp['name'] == 'Água - Completo') {
                                              orderedComplete = splitProduct[0]
                                            }
                                            if (cp['name'] == 'Acessórios') {
                                              acessory = true
                                            }
                                          }
                                        })
                                      }
                                    })
                                    setTimeout(() => {
                                      let dados = {
                                        id: response.data[0].product_id,
                                        stock_full: Number(stock_full) - Number(splitProduct[0]),
                                        stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                        stock_complete: Number(stock_complete) + Number(orderedComplete),
                                        db: currentUser.db
                                      }

                                      if (Number(orderedComplete) > 0) {
                                        dados = {
                                          id: response.data[0].product_id,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full) - Number(splitProduct[0]),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete) + Number(orderedComplete),
                                          db: currentUser.db
                                        }
                                      }

                                      if (acessory) {
                                        dados = {
                                          id: Number(po),
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: 0,
                                          stock_empty: 0,
                                          stock_complete: 0,
                                          db: currentUser.db
                                        }
                                      }

                                      // api.put("/AtualizarProduto", dados)
                                      //   .then(response => {
                                      //     console.log(response)
                                      //   }).catch(err => {
                                      //     console.log(err)
                                      //   })
                                      let dados2 = {
                                        id: response.data[0].product_id,
                                        stock_full: Number(stock_full) - Number(splitProduct[0]),
                                        stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                        stock_complete: Number(stock_complete) + Number(orderedComplete),
                                        db: currentUser.db
                                      }

                                      if (Number(orderedComplete) > 0) {
                                        dados2 = {
                                          id: response.data[0].product_id,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full) - Number(splitProduct[0]),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete) + Number(orderedComplete),
                                          db: currentUser.db
                                        }
                                      }

                                      if (acessory) {
                                        dados2 = {
                                          id: response.data[0].product_id,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete),
                                          db: currentUser.db
                                        }
                                      }

                                      api.put("/AtualizarEstoqueVinculado", dados2)
                                        .then(response => {
                                          console.log(response.data)
                                        }).catch(err => {
                                          console.log(err)
                                        })

                                      let dados3 = {
                                        user_id: currentUser.id,
                                        action: `Pedido ${k}`,
                                        stock: Number(stock),
                                        stock_full: Number(stock_full) - Number(splitProduct[0]),
                                        stock_empty: Number(stock_empty) + Number(orderedComplete),
                                        stock_complete: Number(stock_complete),
                                        date_alter: today,
                                        db: currentUser.db
                                      }

                                      if (Number(orderedComplete) > 0) {
                                        dados3 = {
                                          user_id: currentUser.id,
                                          action: `Pedido ${k}`,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: stock_full - Number(splitProduct[0]),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete) + Number(orderedComplete),
                                          date_alter: today,
                                          db: currentUser.db
                                        }
                                      }

                                      if (acessory) {
                                        dados3 = {
                                          user_id: currentUser.id,
                                          action: `Pedido ${k}`,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete),
                                          date_alter: today,
                                          db: currentUser.db
                                        }
                                      }

                                      api.post("/CadastrarLogEstoque", dados3)
                                        .then(response => {
                                          console.log(response.data)
                                        }).catch(err => {
                                          console.log(err)
                                        })
                                    }, 1000)
                                  } else {
                                    product.map(p => {
                                      if (p['id'] == Number(po)) {
                                        stock_full = p['stock_full'] == '' ? 0 : p['stock_full']
                                        stock_empty = p['stock_empty'] == '' ? 0 : p['stock_empty']
                                        stock_complete = p['stock_complete'] == '' ? 0 : p['stock_complete']
                                        stock = p['stock'] == '' ? 0 : p['stock']
                                      }

                                      if (p['id'] == Number(po) && p['name'] == splitProduct[1].substring(1)) {
                                        categoryProducts.map(cp => {
                                          if (cp['id'] == Number(p['category_id'])) {
                                            if (cp['name'] == 'Gás - Completo' || cp['name'] == 'Água - Completo') {
                                              orderedComplete = splitProduct[0]
                                            }
                                            if (cp['name'] == 'Acessórios') {
                                              acessory = true
                                            }
                                          }
                                        })
                                      }
                                    })

                                    setTimeout(() => {
                                      let dados = {
                                        id: Number(po),
                                        stock_full: Number(stock_full) - Number(splitProduct[0]),
                                        stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                        stock_complete: Number(stock_complete),
                                        db: currentUser.db
                                      }

                                      if (Number(orderedComplete) > 0) {
                                        dados = {
                                          id: Number(po),
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full) - Number(splitProduct[0]),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete) + Number(orderedComplete),
                                          db: currentUser.db
                                        }
                                      }

                                      if (acessory) {
                                        dados = {
                                          id: Number(po),
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: 0,
                                          stock_empty: 0,
                                          stock_complete: 0,
                                          db: currentUser.db
                                        }
                                      }

                                      api.put("/AtualizarProduto", dados)
                                        .then(response => {
                                          console.log(response)
                                        }).catch(err => {
                                          console.log(err)
                                        })

                                      let dados3 = {
                                        user_id: currentUser.id,
                                        action: `Pedido ${k}`,
                                        stock: Number(stock),
                                        stock_full: Number(stock_full) - Number(splitProduct[0]),
                                        stock_empty: Number(stock_empty) + Number(orderedComplete),
                                        stock_complete: Number(stock_complete),
                                        date_alter: today,
                                        db: currentUser.db
                                      }

                                      if (Number(orderedComplete) > 0) {
                                        dados3 = {
                                          user_id: currentUser.id,
                                          action: `Pedido ${k}`,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: stock_full - Number(splitProduct[0]),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete) + Number(orderedComplete),
                                          date_alter: today,
                                          db: currentUser.db
                                        }
                                      }

                                      if (acessory) {
                                        dados3 = {
                                          user_id: currentUser.id,
                                          action: `Pedido ${k}`,
                                          stock: Number(stock) - Number(splitProduct[0]),
                                          stock_full: Number(stock_full),
                                          stock_empty: Number(stock_empty),
                                          stock_complete: Number(stock_complete),
                                          date_alter: today,
                                          db: currentUser.db
                                        }
                                      }
                                      api.post("/CadastrarLogEstoque", dados3)
                                        .then(response => {
                                          console.log(response.data)
                                        }).catch(err => {
                                          console.log(err)
                                        })
                                    }, 1000)
                                  }
                                }, 500)
                              }

                            } else {
                              product.map(p => {
                                if (p['id'] == Number(po)) {
                                  stock_full = p['stock_full'] == '' ? 0 : p['stock_full']
                                  stock_empty = p['stock_empty'] == '' ? 0 : p['stock_empty']
                                  stock_complete = p['stock_complete'] == '' ? 0 : p['stock_complete']
                                  stock = p['stock'] == '' ? 0 : p['stock']
                                }

                                if (p['id'] == Number(po) && p['name'] == splitProduct[1].substring(1)) {
                                  categoryProducts.map(cp => {
                                    if (cp['id'] == Number(p['category_id'])) {
                                      if (cp['name'] == 'Gás - Completo' || cp['name'] == 'Água - Completo') {
                                        orderedComplete = splitProduct[0]
                                      }
                                      if (cp['name'] == 'Acessórios') {
                                        acessory = true
                                      }
                                    }
                                  })
                                }
                              })

                              setTimeout(() => {
                                let dados = {
                                  id: Number(po),
                                  stock_full: Number(stock_full) - Number(splitProduct[0]),
                                  stock_empty: Number(stock_empty) + Number(splitProduct[0]),
                                  stock_complete: Number(stock_complete),
                                  db: currentUser.db
                                }

                                if (Number(orderedComplete) > 0) {
                                  dados = {
                                    id: Number(po),
                                    stock: Number(stock) - Number(splitProduct[0]),
                                    stock_full: Number(stock_full) - Number(splitProduct[0]),
                                    stock_empty: Number(stock_empty),
                                    stock_complete: Number(stock_complete) + Number(orderedComplete),
                                    db: currentUser.db
                                  }
                                }

                                if (acessory) {
                                  dados = {
                                    id: Number(po),
                                    stock: Number(stock) - Number(splitProduct[0]),
                                    stock_full: 0,
                                    stock_empty: 0,
                                    stock_complete: 0,
                                    db: currentUser.db
                                  }
                                }

                                api.put("/AtualizarProduto", dados)
                                  .then(response => {
                                    console.log(response)
                                  }).catch(err => {
                                    console.log(err)
                                  })

                                let dados3 = {
                                  user_id: currentUser.id,
                                  action: `Pedido ${k}`,
                                  stock: Number(stock),
                                  stock_full: Number(stock_full) - Number(splitProduct[0]),
                                  stock_empty: Number(stock_empty) + Number(orderedComplete),
                                  stock_complete: Number(stock_complete),
                                  date_alter: today,
                                  db: currentUser.db
                                }

                                if (Number(orderedComplete) > 0) {
                                  dados3 = {
                                    user_id: currentUser.id,
                                    action: `Pedido ${k}`,
                                    stock: Number(stock) - Number(splitProduct[0]),
                                    stock_full: Number(stock_full) - Number(splitProduct[0]),
                                    stock_empty: Number(stock_empty),
                                    stock_complete: Number(stock_complete) + Number(orderedComplete),
                                    date_alter: today,
                                    db: currentUser.db
                                  }
                                }

                                if (acessory) {
                                  dados3 = {
                                    user_id: currentUser.id,
                                    action: `Pedido ${k}`,
                                    stock: Number(stock) - Number(splitProduct[0]),
                                    stock_full: Number(stock_full),
                                    stock_empty: Number(stock_empty),
                                    stock_complete: Number(stock_complete),
                                    date_alter: today,
                                    db: currentUser.db
                                  }
                                }
                                api.post("/CadastrarLogEstoque", dados3)
                                  .then(response => {
                                    console.log(response.data)
                                  }).catch(err => {
                                    console.log(err)
                                  })
                              }, 1000)
                            }
                          }).catch(err => {
                            console.log(err)
                          })
                      })
                    })
                  }


                })
                .catch(err => {
                  console.log(err)
                })

            }
          })

        // profileOrder.map(key => {
        //   if (key['id'] == k) {
        //     if (key['player_id'] !== null) {
        //       api.post("/Notificar", {
        //         app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
        //         contents: {
        //           en: notification
        //         },
        //         include_player_ids: [key['player_id']]
        //       }).then(response => {
        //         // console.log(response)
        //       }).catch(err => {
        //         console.log(err)
        //       })
        //     }
        //   }
        // })
      })

      setOpen(true)
      setTimeout(function () {
        window.location.reload(1);
      }, 3000);
    } catch (err) {

    }
  }

  const handleCancelOrdered = async (e) => {
    arrayRows.map((k, index) => {
      api.put('/AtualizarPedido', {
        id: k,
        status: 'Cancelado',
        db: currentUser.db
      })
    })
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  let driverSelected = '';
  let statusOrderSelected = '';

  const driverHandler = e => {
    driverSelected = e.target.value;
  }

  const statusOrderHandler = e => {
    statusOrderSelected = e.target.value
  }
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

  let db = 'clubeApi';
  if (currentUser !== undefined || currentUser !== null) {
    db = currentUser.db
  }

  React.useEffect(() => {
    api
      .get("/TodasCategoriasProdutos" + '/' + currentUser.db)
      .then(response => {
        setCategoryProducts(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        const reversed = response.data.reverse();
        setProfileOrder(response.data)
        setRows(reversed.map(key => ({
          id: key['id'],
          canalDeVenda: key['sale_channel'],
          cliente: key['client_id'],
          phone: '',
          endereco: key['client_id'],
          produtos: key['products'],
          formaDePagamento: key['payment_id2'] == '' ? key['payment_id'] : key['payment_id3'] == '' ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
          horarioDoPedido: key['order_date'],
          entregador: key['driver_id'],
          horarioDaEntrega: key['delivery_date'],
          total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
          status: key['status'],
          typeClient: key['typeClient']
        })))
        setFilteredData(reversed.map(key => ({
          id: key['id'],
          canalDeVenda: key['sale_channel'],
          cliente: key['client_id'],
          phone: '',
          endereco: key['client_id'],
          produtos: key['products'],
          formaDePagamento: key['payment_id2'] == null ? key['payment_id'] : key['payment_id3'] == null ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
          horarioDoPedido: key['order_date'],
          entregador: key['driver_id'],
          horarioDaEntrega: key['delivery_date'],
          total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
          status: key['status'],
          typeClient: key['typeClient']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const MINUTE_MS = 60000;
  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosPedidos2" + '/' + currentUser.db)
        .then((response) => {
          const reversed = response.data.reverse();
          setProfileOrder(response.data)
          setRows(reversed.map(key => ({
            id: key['id'],
            canalDeVenda: key['sale_channel'],
            cliente: key['client_id'],
            phone: '',
            endereco: key['client_id'],
            produtos: key['products'],
            formaDePagamento: key['payment_id2'] == '' ? key['payment_id'] : key['payment_id3'] == '' ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
            horarioDoPedido: key['order_date'],
            entregador: key['driver_id'],
            horarioDaEntrega: key['delivery_date'],
            total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
            status: key['status'],
            typeClient: key['typeClient']
          })))
          setFilteredData(reversed.map(key => ({
            id: key['id'],
            canalDeVenda: key['sale_channel'],
            cliente: key['client_id'],
            phone: '',
            endereco: key['client_id'],
            produtos: key['products'],
            formaDePagamento: key['payment_id2'] == null ? key['payment_id'] : key['payment_id3'] == null ? String(key['payment_id']).concat(' / ', String(key['payment_id2'])) : String(key['payment_id']).concat(' / ', String(key['payment_id2']), ' / ', String(key['payment_id3'])),
            horarioDoPedido: key['order_date'],
            entregador: key['driver_id'],
            horarioDaEntrega: key['delivery_date'],
            total: "R$ " + Number(key['total']).toFixed(2).replace('.', ','),
            status: key['status'],
            typeClient: key['typeClient']
          })))

        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useState(() => {
    const interval = setInterval(() => {
      setRows(filteredData)
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, [])

  React.useState(() => {
    setRows(filteredData)
  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodasFormasPagamentos" + '/' + currentUser.db)
        .then((response) => {
          setPayment(response.data)
        })

        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosClientesFisicos" + '/' + currentUser.db)
        .then((response) => setClientPhysic(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosClientesJuridicos" + '/' + currentUser.db)
        .then((response) => setClientBusiness(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosMotoristas" + '/' + currentUser.db)
        .then((response) => {
          setDriver(response.data)
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosProdutos" + '/' + currentUser.db)
        .then((response) => {
          setProduct(response.data)
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasFormasPagamentos" + '/' + currentUser.db)
      .then((response) => {
        setPayment(response.data)
      })

      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesFisicos" + '/' + currentUser.db)
      .then((response) => setClientPhysic(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => setClientBusiness(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosMotoristas" + '/' + currentUser.db)
      .then((response) => {
        setDriver(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setProduct(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => setSaleChanell(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);


  React.useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/TodosCanaisVendas" + '/' + currentUser.db)
        .then((response) => setSaleChanell(response.data))
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);

  const columns = [
    { title: "Canal de Venda", field: "canalDeVenda" },
    { title: "Cliente", field: "cliente" },
    {
      title: "Telefone", field: "phone", render: (rowData) => {
        if (rowData.phone.length === 10) {
          return (
            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={rowData.phone} disabled />
          )
        } else {
          return (
            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={rowData.phone} disabled />
          )
        }
      }
    },
    { title: "Endereço", field: "endereco" },
    { title: "Produtos", field: 'produtos' },
    { title: "Forma de pagamento", field: 'formaDePagamento' },
    { title: "Horário do pedido", field: 'horarioDoPedido' },
    { title: "Entregador", field: 'entregador' },
    // { title: "Horário da entrega", field: 'horarioDaEntrega', emptyValue: () => <em>Ainda sem informação</em> },
    { title: "Total", field: 'total' },
    { title: "Status", field: 'status', render: (rowData) => <div style={{ background: rowData.status == 'Finalizado' ? '#c2c2c2' : rowData.status == 'Entregador notificado' ? '#ffff76' : rowData.status == 'Entregador notificado' ? '#ffff76' : rowData.status == 'Saiu para entrega' ? '#ffff76' : rowData.status == 'Cancelado' ? '#ff3333' : '', height: 50, display: "flex", alignItems: "center", justifyContent: "center" }}><span style={{ textAlign: 'center' }}>{rowData.status}</span></div> },
  ]

  filteredData.forEach(item => {
    if (item.horarioDaEntrega !== null) {
      if (item.horarioDaEntrega.toString().length == 13) {
        let date = new Date(Number(item.horarioDaEntrega));
        let entrega = date.getDate() +
          "/" + (date.getMonth() + 1) +
          "/" + date.getFullYear() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds();
        item.horarioDaEntrega = entrega;
      }
    }
    saleChanell.map(key => {
      if (item.canalDeVenda == key['id']) {
        return item.canalDeVenda = key['name']
      }
    })
    if (item.typeClient == 'fisico') {
      clientPhysic.map(key => {
        if (item.cliente == key['id']) {
          let completmentFisico = key['complement'] == null ? '' : key['complement']
          let referenceFisico = key['reference_point'] == null ? '' : key['reference_point']
          item.cliente = key['name']
          item.endereco = key['street'] + ", " + key['number'] + ' - ' + key['neighborhood'] + " " + completmentFisico + " " + referenceFisico
          item.phone = key['phone']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (item.cliente == key['id']) {
          let completmentJuridico = key['complement'] == null ? '' : key['complement']
          let referenceJuridico = key['reference_point'] == null ? '' : key['reference_point']
          item.cliente = key['name']
          item.endereco = key['street'] + ", " + key['number'] + ' - ' + key['neighborhood'] + " " + completmentJuridico + " " + referenceJuridico
          item.phone = key['phone']
        }
      })
    }

    var formaPagamento = item.formaDePagamento;
    let pag1 = '0';
    let pag2 = '0';
    let pag3 = '0';
    let pagName1 = '';
    let pagName2 = '';
    let pagName3 = '';
    if (typeof formaPagamento == 'string') {
      var splitPagamento = formaPagamento.split(' / ');
      pag1 = splitPagamento[0];
      if (splitPagamento[1] !== undefined) {
        pag1 = splitPagamento[0];
        pag2 = splitPagamento[1];
      }
      if (splitPagamento[2] !== undefined) {
        pag1 = splitPagamento[0];
        pag2 = splitPagamento[1];
        pag3 = splitPagamento[2];
      }
    } else {
      pag1 = item.formaDePagamento
    }
    if (pag2 === '0') {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          item.formaDePagamento = key['name']
        }
      })
    } else if (pag3 === '0') {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          pagName1 = key['name']
        }
        if (Number(pag2) == key['id']) {
          pagName2 = key['name']
        }
        let arr = [pagName1];
        let arrString = String(arr);
        if (pagName2 !== '') {
          arr = [pagName1, pagName2];
          arrString = String(arr.join(', '));
        }

        item.formaDePagamento = arrString
      })
    } else {
      payment.map(key => {
        if (Number(pag1) == key['id']) {
          pagName1 = key['name']
        }
        if (Number(pag2) == key['id']) {
          pagName2 = key['name']
        }
        if (Number(pag3) == key['id']) {
          pagName3 = key['name']
        }
        let arr2 = [pagName1];
        let arrString2 = String(arr2);
        if (pagName2 !== '' && pagName3 !== '') {
          arr2 = [pagName1, pagName2, pagName3];
          arrString2 = String(arr2.join(', '));
        }

        item.formaDePagamento = arrString2
      })
    }
    driver.map(key => {
      if (item.entregador == key['id']) {
        item.entregador = key['name']
      }
    })
  })

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Cancelar Pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja cancelar o(os) pedido(os) selecionado(os)?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalDriver(props) {
    let message = `Pedido nº ${orderedSelected.id} \n \n Itens: ➡ ⁠ ${orderedSelected.produtos} \n \n 💳 ${orderedSelected.formaDePagamento} \n \n 🏪 Entrega em: \n ${orderedSelected.endereco}  \n \n Total: R$ ${orderedSelected.total} `;
    whatsPhone = orderedSelected.phone
    return (
      <Modal
        show={modalDriver}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Entregador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione um entregador para o pedido</h4>
          <form onSubmit={submitDriver} className="input">
            <Form.Select
              onChange={driverHandler}
            >
              <option value=''>Selecione um entregador</option>
              {driver.map((key, value) => {
                return <option value={key['id']} name='driver'>{key['name']}</option>
              })}
            </Form.Select>
            <div style={typePayment ? { display: 'flex', marginTop: 10, flexDirection: 'column' } : { display: 'none' }}>
              <span>Mensagem</span>
              <Form.Control
                as="textarea"
                style={{ height: '100px', width: '100%' }}
                name='mesageWhats'
                id='mesageWhats'
                defaultValue={message}
              />
            </div>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Atualizar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const submitModalConfirm = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    var inputProduct1 = document.getElementById("inputProductSelect1").value
    var inputProduct2 = document.getElementById("inputProductSelect2").value
    var inputProduct3 = document.getElementById("inputProductSelect3").value
    var selectQuant = [];
    let resultSelect = '';
    let pName = '';
    let pId = '';
    let descont = 0;
    let totalValue = 0
    let paymentValue1 = 0
    let phoneDriver = '';

    for (var i = 0, l = productName.length; i < l; i++) {
      if (productName[i].label !== undefined) {
        if (i == 0) {
          selectQuant.push(inputProduct1 + "x" + " " + productName[i].label);
        }
        if (i == 1) {
          selectQuant.push(inputProduct2 + "x" + " " + productName[i].label);
        }
        if (i == 2) {
          selectQuant.push(inputProduct3 + "x" + " " + productName[i].label);
        }
      } else {
        if (i == 0) {
          selectQuant.push(inputProduct1 + "x" + " " + productName[i]);
        }
        if (i == 1) {
          selectQuant.push(inputProduct2 + "x" + " " + productName[i]);
        }
        if (i == 2) {
          selectQuant.push(inputProduct3 + "x" + " " + productName[i]);
        }
      }

    }
    if (selectQuant.length == 1) {
      resultSelect = selectQuant.join(", ")
    } else {
      resultSelect = selectQuant.join(", ")
    }

    if (selectQuant.length == 0) {
      pName = orderedSelected['products']
      pId = orderedSelected['product_id'].split(',')
      descont = orderedSelected['desconto']
      totalValue = parseFloat(orderedSelected['total'])
      paymentValue1 = parseFloat(orderedSelected['payment_value'])
    } else {
      pName = resultSelect;
      pId = productId;
      descont = parseFloat(data.inputDesconto.replace('R$', '').replace('.', '').replace(',', '.'))
      totalValue = (productValue !== '' ? productValue : parseFloat(orderedSelected['total']));
      paymentValue1 = (productValue !== '' ? productValue : parseFloat(orderedSelected['total'])) - (data.paymentValue2 !== '0' && data.paymentValue2 !== undefined ? parseFloat(data.paymentValue2) : 0) - (data.paymentValue3 !== '0' && data.paymentValue3 !== undefined ? parseFloat(data.paymentValue3) : 0)
    }

    if (data.inputDesconto !== '') {
      descont = parseFloat(data.inputDesconto.replace('R$', '').replace('.', '').replace(',', '.'))
      if (selectQuant.length == 0) {
        totalValue = parseFloat(orderedSelected['total']) - parseFloat(descont)
      } else {
        totalValue = parseFloat((productValue !== '' ? productValue : parseFloat(orderedSelected['total']))) - parseFloat(parseFloat(descont));
      }
    } else {
      descont = orderedSelected['desconto']
      if (selectQuant.length > 0) {
        totalValue = parseFloat((productValue !== '' ? productValue : parseFloat(orderedSelected['total']))) - (orderedSelected['desconto'] !== '' ? parseFloat(orderedSelected['desconto']) : 0);
      }

      if (document.getElementsByName('radioDesconto')[1].checked) {
        if ((parseFloat(orderedSelected['payment_value']) + parseFloat((orderedSelected['payment_value2'] !== null ? orderedSelected['payment_value2'] : 0)) + parseFloat(orderedSelected['payment_value3'] !== null ? orderedSelected['payment_value3'] : 0)) !== parseFloat(orderedSelected['total'])) {
          totalValue = (parseFloat(orderedSelected['payment_value']) + parseFloat((orderedSelected['payment_value2'] !== null ? orderedSelected['payment_value2'] : 0)) + parseFloat(orderedSelected['payment_value3'] !== null ? orderedSelected['payment_value3'] : 0))
        }
        descont = ''
      }
    }

    driver.map(key => {
      if (key['id'] == orderedSelected['driver_id']) {
        phoneDriver = key['phone']
      }
    })

    // console.log(
    //   {
    //     id: orderedSelected['id'],
    //     driver_id: Number(data.driverSelect),
    //     product_id: pId.toString(),
    //     products: pName,
    //     payment_id: Number(data.payment),
    //     payment_id2: Number(data.payment2),
    //     payment_id3: Number(data.payment3),
    //     payment_value: paymentValue1,
    //     payment_value2: (data.paymentValue2 !== '0' && data.paymentValue2 !== undefined ? data.paymentValue2 : 0),
    //     payment_value3: (data.paymentValue3 !== '0' && data.paymentValue3 !== undefined ? data.paymentValue3 : 0),
    //     sale_channel: Number(data.channel),
    //     obs: data.obs,
    //     total: totalValue,
    //     quick_client: orderedSelected['res'],
    //     obsPayment: data.inputObsPayment,
    //     desconto: descont,
    //     db: currentUser.db
    //   }
    // )

    api.put("/AtualizarPedido", {
      id: orderedSelected['id'],
      driver_id: Number(data.driverSelect),
      product_id: pId.toString(),
      products: pName,
      payment_id: Number(data.payment),
      payment_id2: Number(data.payment2),
      payment_id3: Number(data.payment3),
      payment_value: paymentValue1,
      payment_value2: (data.paymentValue2 !== '0' && data.paymentValue2 !== undefined ? data.paymentValue2 : 0),
      payment_value3: (data.paymentValue3 !== '0' && data.paymentValue3 !== undefined ? data.paymentValue3 : 0),
      sale_channel: Number(data.channel),
      obs: data.obs,
      total: totalValue,
      quick_client: orderedSelected['quick_client'],
      obsPayment: data.inputObsPayment,
      desconto: descont,
      db: currentUser.db
    }).then(response => {
      console.log(response)
      let message =
        `*Pedido ${orderedSelected['id']} Atualizado* 
    
    *Cliente e Endereço:* ${orderedSelected['quick_client']} 
    *Produtos:* ${pName} 
    *Valor:* R$ ${totalValue}
    *Observação:* ${data.obs}`;
      axios
        .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
          {
            number: "55" + phoneDriver + "@s.whatsapp.net",
            textMessage: {
              text: message
            },
            delay: 1200
          }, {
          headers: {
            'Content-Type': 'application/json',
            'apikey': 'B6D711FCDE4D4FD5936544120E713976'
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          let ddd = String(phoneDriver[0]) + String(phoneDriver[1]);
          phoneDriver = phoneDriver.slice(3);

          axios
            .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
              {
                number: "55" + ddd + phoneDriver + "@s.whatsapp.net",
                textMessage: {
                  text: message
                },
                delay: 1200
              }, {
              headers: {
                'Content-Type': 'application/json',
                'apikey': 'B6D711FCDE4D4FD5936544120E713976'
              }
            })
            .then((response) => {
              // console.log(response);
            })
            .catch((err) => {
              console.log(err);
            })
        })
    }).catch(error => {
      console.log(error)
    });

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalOrdered(props) {
    if (actions == 'edit_order' && orderedSelected.length !== 0) {
      // if (orderedSelected['desconto'] !== null) {
      //   var divDesconto = document.getElementById('descontoContainer');
      //   divDesconto.style.display = "block"
      // }
      var arrayProd = []
      var arrayProdSelectedSplit = orderedSelected['product_id'].split(',')
      var arrayProdSelected = arrayProdSelectedSplit.filter((este, i) => arrayProdSelectedSplit.indexOf(este) === i);
      let quant1 = 1;
      let quant2 = 1;
      let quant3 = 1;
      let prod1 = '';
      let prod2 = '';
      let prod3 = '';
      arrayProdSelected.map(k => {
        optionsProducts.map(key => {
          if (key['value'] == k) {
            arrayProd.push(key)
          }
        })
      })

      if (arrayProdSelected.length == 1) {
        var arrayProdSelectedSplit2 = [orderedSelected['products']];
        var splitProd = arrayProdSelectedSplit2[0].split('x');
        quant1 = Number(splitProd[0])
        prod1 = splitProd[1]
      } else if (arrayProdSelected.length == 2) {
        var arrayProdSelectedSplit2 = orderedSelected['products'].split(',');
        var splitProd = arrayProdSelectedSplit2[0].split('x');
        var splitProd2 = arrayProdSelectedSplit2[1].split('x');
        quant1 = Number(splitProd[0])
        prod1 = splitProd[1]
        quant2 = Number(splitProd2[0])
        prod2 = splitProd2[1]
      } else if (arrayProdSelected.length == 3) {
        var arrayProdSelectedSplit2 = orderedSelected['products'].split(',');
        var splitProd = arrayProdSelectedSplit2[0].split('x');
        var splitProd2 = arrayProdSelectedSplit2[1].split('x');
        var splitProd3 = arrayProdSelectedSplit2[2].split('x');
        quant1 = Number(splitProd[0])
        prod1 = splitProd[1]
        quant2 = Number(splitProd2[0])
        prod2 = splitProd2[1]
        quant3 = Number(splitProd3[0])
        prod3 = splitProd3[1]
      }

      valueOriginal = orderedSelected['payment_value2'] !== null && orderedSelected['payment_value2'] !== undefined && orderedSelected['payment_value2'] !== '0' ? orderedSelected['payment_value'] : orderedSelected['total']

      productName = arrayProd
      return (
        <Modal
          show={orderedModal}
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={handleModalClose}
        >
          <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter">
              Novo pedido
            </Modal.Title>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
          </Modal.Header>
          <Modal.Body className="cancelBody">
            <form className="input" onSubmit={submitModalConfirm}>
              <div className="divOrderClientAndProduct">
                <div className="divOrderLeft">
                  <label className="labelForm">Produtos</label>
                  <Select2
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    defaultValue={arrayProd}
                    options={optionsProducts}
                    name='product'
                    id='products'
                    onChange={productHandler}
                    isOptionDisabled={() => productName.length >= 3}
                    required
                  />
                  <div className="productSelect1" id="productSelect1" style={arrayProdSelected.length >= 1 ? { display: 'block' } : { display: 'none' }}>
                    <div className="divProduct">
                      <label className="labelProductSelect1" id="labelProductSelect1">{prod1}</label>
                      <input
                        type="number"
                        name="productSelect1"
                        id="inputProductSelect1"
                        className="inputCadastro"
                        onChange={productHandler}
                        defaultValue={quant1}
                        min='1'
                      />
                    </div>
                  </div>
                  <div className="productSelect2" id="productSelect2" style={arrayProdSelected.length >= 2 ? { display: 'block' } : { display: 'none' }}>
                    <div className="divProduct">
                      <label className="labelProductSelect2" id="labelProductSelect2">{prod2}</label>
                      <input
                        type="number"
                        name="productSelect2"
                        id="inputProductSelect2"
                        className="inputCadastro"
                        onChange={productHandler}
                        defaultValue={quant2}
                        min='1'
                      />
                    </div>
                  </div>
                  <div className="productSelect3" id="productSelect3" style={arrayProdSelected.length >= 3 ? { display: 'block' } : { display: 'none' }}>
                    <div className="divProduct">
                      <label className="labelProductSelect3" id="labelProductSelect3">{prod3}</label>
                      <input
                        type="number"
                        name="productSelect3"
                        id="inputProductSelect3"
                        className="inputCadastro"
                        onChange={productHandler}
                        defaultValue={quant3}
                        min='1'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '45%' }}>
                      <label className="labelForm">Forma de pagamento</label>
                      <Form.Select
                        name='payment'
                        id='inputPayment'
                        onChange={paymentHandler}
                        required
                      >
                        <option value=''>Selecione uma forma de pagamento</option>
                        {payment.map((key, value) => {
                          return <option value={key['id']} selected={key['id'] == orderedSelected['payment_id']}>{key['name']}</option>
                        })}
                      </Form.Select>
                    </div>
                    <div id="paymentValueContainer" style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                      <label className="labelForm">Valor</label>
                      <input
                        className="inputCadastro"
                        type="text"
                        name="paymentValue"
                        step='0,01'
                        id='paymetValueInput'
                        placeholder={orderedSelected['payment_value'] !== null ? orderedSelected['payment_value'] : orderedSelected['total']}
                        disabled
                      />
                    </div>
                  </div>


                  <div className='payment2Container' id='payment2Container' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '45%' }}>
                      <label className="labelForm">Segunda forma de pagamento</label>
                      <Form.Select
                        name='payment2'
                        id='inputPayment2'
                        onChange={paymentHandler2}
                      >
                        <option value=''>Selecione uma forma de pagamento</option>
                        {payment.map((key, value) => {
                          return <option value={key['id']} selected={key['id'] == orderedSelected['payment_id2']}>{key['name']}</option>
                        })}
                      </Form.Select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                      <label className="labelForm">Segundo valor</label>
                      <input
                        className="inputCadastro"
                        id='paymetValueInput2'
                        name="paymentValue2"
                        type="number"
                        step='0,01'
                        placeholder={orderedSelected['payment_value2']}
                        defaultValue={orderedSelected['payment_value2']}
                        onBlur={handleValueTotal}
                        disabled={orderedSelected['payment_value2'] !== '' ? orderedSelected['payment_value2'] !== '0' ? false : true : true}
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                      <span style={{ cursor: 'pointer' }} onClick={(e) => handleHiddenPayment('2')}>X</span>
                    </div>
                  </div>

                  <div className='payment3Container' id='payment3Container' style={{ display: 'none' }}>
                    <div style={{ width: '45%' }}>
                      <label className="labelForm">Terceira forma de pagamento</label>
                      <Form.Select
                        name='payment3'
                        id='inputPayment3'
                        onChange={paymentHandler3}
                      >
                        <option value=''>Selecione uma forma de pagamento</option>
                        {payment.map((key, value) => {
                          return <option value={key['id']} selected={key['id'] == orderedSelected['payment_id3']}>{key['name']}</option>
                        })}
                      </Form.Select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                      <label className="labelForm">Terceiro valor</label>
                      <input
                        className="inputCadastro"
                        id='paymetValueInput3'
                        name="paymentValue3"
                        type="number"
                        step='0,01'
                        onBlur={handleValueTotal}
                        placeholder={orderedSelected['payment_value3']}
                        disabled
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                      <span style={{ cursor: 'pointer' }} onClick={(e) => handleHiddenPayment('3')}>X</span>
                    </div>
                  </div>

                  <div className="obsPayment" id='obsPayment' style={{ display: 'none' }}>
                    <div>
                      <label className="labelForm">Valor total: R$ <span className="productTotal" id="productTotal"></span></label>
                    </div>
                    <div>
                      <label className="labelForm">Vai precisar de troco?</label>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="radioClient"
                        required
                      >
                        <FormControlLabel value="sim" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Sim" />
                        <FormControlLabel value="nao" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Não" />
                      </RadioGroup>
                    </div>
                    <div className="divTroco" id="divTroco" style={{ display: 'none' }}>
                      <label className="labelForm" >Troco para</label>
                      <Form.Control
                        placeholder="Troco para"
                        aria-label="troco"
                        name="obsTroco"
                      />
                    </div>
                  </div>
                  <label className="labelForm">Desconto?</label>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={orderedSelected['desconto'] == null || orderedSelected['desconto'] == '' ? 'nao' : 'sim'}
                    name="radio-buttons-group"
                    className="radioClient"
                    required
                  >
                    <FormControlLabel value="sim" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Sim" />
                    <FormControlLabel value="nao" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Não" />
                  </RadioGroup>
                  <div className="descontoContainer" id="descontoContainer" style={orderedSelected['desconto'] == '' ? { display: 'none' } : orderedSelected['desconto'] == null ? { display: 'none' } : { display: 'block' }}>
                    <label className="labelForm">Valor do desconto</label>
                    <CurrencyInput
                      id="categoryProduct"
                      name="inputDesconto"
                      className="inputCadastro"
                      value={parseFloat(orderedSelected['desconto'])}
                      onChangeValue={(event, originalValue, maskedValue) => {
                        // console.log(event, originalValue, maskedValue);
                      }}
                    />
                  </div>
                  <label className="labelForm">Canal de venda</label>
                  <Form.Select
                    name='channel'
                    required
                  >
                    <option value=''>Selecione um canal de venda</option>
                    {saleChanell.map((key, value) => {
                      return <option value={key['id']} selected={key['id'] == orderedSelected['sale_channel'] ? true : false}>{key['name']}</option>
                    })}
                  </Form.Select>


                  {driver.length > 1 ? (
                    <>
                      <label className="labelForm">Selecionar Entregador</label>
                      <Form.Select
                        name='driverSelect'
                        id='driverSelect'
                        required
                      >
                        <option value=''>Selecione um entregador</option>
                        {driver.map((key, value) => {
                          return <option value={key['id']} selected={key['id'] == orderedSelected['driver_id'] ? true : false}>{key['name']}</option>
                        })}
                      </Form.Select>
                    </>
                  ) : ""}

                  <label className="labelForm">Observação</label>
                  <Form.Control
                    className="textAreaForm"
                    as="textarea"
                    style={{ height: '100px' }}
                    name='obs'
                    defaultValue={orderedSelected['obs']}
                  />
                </div>
              </div>
              <div className="buttonLogin">
                <button className="buttonSecundary" >
                  Atualizar Pedido
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal >
      )
    }
  }

  function ModalWhats(props) {
    let message = `Olá ${orderedSelected.cliente}, o seu pedido foi feito com sucesso! \n \n Pedido nº ${orderedSelected.id} \n \n Itens: ➡ ⁠ ${orderedSelected.produtos} \n \n 💳 ${orderedSelected.formaDePagamento} \n \n 🏪 Entrega em: \n ${orderedSelected.endereco}  \n \n Total: R$ ${orderedSelected.total} \n \n Obrigado pela preferência, se precisar de algo é só chamar! 😉`;
    return (
      <Modal
        show={whatsModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Mensagem whatsapp
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a mensagem para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitMessageWhats}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageWhats'
              id='mesageWhats'
              defaultValue={message}
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalStatus(props) {
    return (
      <Modal
        show={modalStatus}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Status do pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Selecione o status do pedido</h4>
          <form onSubmit={submitStatusOrder} className="input">
            <Form.Select
              onChange={statusOrderHandler}
            >
              <option value=''>Selecione um status</option>
              {statusOrder.map((key, value) => {
                return <option value={key} name='driver'>{key}</option>
              })}
            </Form.Select>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Atualizar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalPainel(props) {
    let driverStatus = "Ainda não informado"
    let dateDelivery = "Ainda não informado"
    let vasilhameStatus = "Ainda não informado"
    let formPayment = '';
    let pag1 = '';
    let pag2 = '';
    let pag3 = '';
    let pagName1 = '';
    let pagName2 = '';
    let pagName3 = '';

    return (
      <Modal
        show={modalProfile}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes do pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4 >Informações</h4>
          </div>
          {profileOrder.map(key => {
            if (key['id'] == arrayRows) {
              pag1 = key['payment_id'];
              pag2 = key['payment_id2'];
              pag3 = key['payment_id3'];
              if (pag2 == null) {
                payment.map(k => {
                  if (pag1 == k['id']) {
                    formPayment = k['name']
                  }
                })
              } else if (pag3 == null) {
                payment.map(k => {
                  if (Number(pag1) == k['id']) {
                    pagName1 = k['name']
                  }
                  if (Number(pag2) == k['id']) {
                    pagName2 = k['name']
                  }
                })
                let arr = [pagName1, pagName2];
                let arrString = String(arr.join(', '));
                formPayment = arrString
              } else {
                payment.map(k => {
                  if (Number(pag1) == k['id']) {
                    pagName1 = k['name']
                  }
                  if (Number(pag2) == k['id']) {
                    pagName2 = k['name']
                  }
                  if (Number(pag3) == k['id']) {
                    pagName3 = k['name']
                  }
                })
                let arr2 = [pagName1, pagName2, pagName3];
                let arrString2 = String(arr2.join(', '));
                formPayment = arrString2
              }
              if (key['delivery_date'] !== null) {
                dateDelivery = key['delivery_date']
              }
              if (key['vasilhame'] !== null) {
                vasilhameStatus = key['vasilhame']
              }
              return (
                <Table striped bordered hover className="tableProfile" >
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cliente:</td>
                      {key['typeClient'] == 'fisico' ? (
                        <td>{clientPhysic.map(k => {
                          if (k['id'] == key['client_id']) {
                            return k['name']
                          }
                        })}
                        </td>
                      ) : (
                        <td>{clientBusiness.map(k => {
                          if (k['id'] == key['client_id']) {
                            return k['name']
                          }
                        })}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td className="labelConfirm">Telefone do cliente:</td>
                      {key['typeClient'] == 'fisico' ? (
                        <td>{clientPhysic.map(k => {
                          if (k['id'] == key['client_id']) {
                            if (k['phone'].length === 10) {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={k['phone']} disabled />
                              )
                            } else {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={k['phone']} disabled />
                              )
                            }
                          }
                        })}
                        </td>
                      ) : (
                        <td>{clientBusiness.map(k => {
                          if (k['id'] == key['client_id']) {

                            if (k['phone'].length === 10) {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={k['phone']} disabled />
                              )
                            } else {
                              return (
                                <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={k['phone']} disabled />
                              )
                            }
                          }
                        })}
                        </td>
                      )}

                    </tr>
                    <tr>
                      <td className="labelConfirm">Produtos:</td>
                      <td>{key['products']}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Forma de pagamento:</td>
                      <td>{formPayment}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Canal de venda:</td>
                      <td>{saleChanell.map(k => {
                        if (k['id'] == key['sale_channel']) {
                          return k['name']
                        }
                      })}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Entregador:</td>
                      <td>{driver.map(k => {
                        if (k['id'] == key['driver_id']) {
                          driverStatus = k['name']
                          return driverStatus
                        }
                      })}
                      </td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Data do pedido:</td>
                      <td>{key['order_date']}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Data da entrega:</td>
                      <td>{dateDelivery}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Vasilhame:</td>
                      <td>{vasilhameStatus}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Observação:</td>
                      <td>{key['obs']}</td>
                    </tr>

                    <tr>
                      <td className="labelConfirm">Primeiro Valor:</td>
                      <td>R$ {key['payment_value']}</td>
                    </tr>

                    {
                      key['payment_value2'] !== null ? (
                        <tr>
                          <td className="labelConfirm">Segundo valor:</td>
                          <td>R$ {key['payment_value2']}</td>
                        </tr>
                      ) : ('')
                    }

                    {
                      key['payment_value3'] !== null ? (
                        <tr>
                          <td className="labelConfirm">Terceiro valor:</td>
                          <td>R$ {key['payment_value3']}</td>
                        </tr>
                      ) : ('')
                    }

                    <tr>
                      <td className="labelConfirm">Total:</td>
                      <td>{'R$ ' + key['total']}</td>
                    </tr>

                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal >
    )

  }

  React.useEffect(() => {
    var array = []
    rowsSelected.map(key => {
      array.push(key['id'])
    })
    if (actions == 'cancel_order') {
      setModalCancel(true)
      setText('Sim')
    } else if (actions == 'select_driver') {
      setText('Confirmar')
      setModalDriver(true)
      filteredData.map(key => {
        if (key['id'] == array[0]) {
          // if (key['canalDeVenda'] == 'Whastapp') {
          setTypePayment(true)
          setOrderedSelected(key)
          // }
        }

      })
    } else if (actions == 'select_status') {
      setText('Aplicar')
      setModalStatus(true)
    } else if (actions == 'profile_order') {
      setModalProfile(true)
    } else if (actions == 'send_message') {
      setWhatsModal(true)
      filteredData.map(key => {
        if (key['id'] == array[0]) {
          // if (key['canalDeVenda'] == 'Whastapp') {
          setTypePayment(true)
          setOrderedSelected(key)
          // }
        }

      })
    } else if (actions == 'edit_order') {
      setOrderedModal(true)
      profileOrder.map(key => {
        if (key['id'] == array[0]) {
          // if (key['canalDeVenda'] == 'Whastapp') {
          setOrderedSelected(key)
          // }
        }

      })
    }

    setArrayRows(array)
  }, [actions])

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  React.useEffect(() => {
    setFilteredData(rows)
    let dateNow = new Date();
    var filter = [];
    let driverName = filterDriver.split('-');
    let saleChannell = filterSaleChannel.split('-');
    // let dateArray = dateFilter.split("/");
    let dateStart = new Date(formatDate(dateFilter[0])).getTime()
    let dateEnd = new Date(formatDate(dateFilter[1])).getTime()

    if (dateFilter !== '') {
      rows.filter(f => {
        let dateOrder = f.horarioDoPedido.split(' ');
        let splitOrderDate = dateOrder[0].split('/');
        let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();

        if (dateStart <= orderDateTimestamp && dateEnd >= orderDateTimestamp) {
          if (filterDate1 == 'semana') {
            dateNow.setDate(dateNow.getDate() - 7);
            let dateFormat = dateNow.getTime();
            if (f.horarioDoPedido !== null) {
              if (dateFormat < orderDateTimestamp) {
                if (filterDriver !== 'all') {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f);
                    }
                  }
                } else if (filterSaleChannel !== 'all') {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f)
                    }
                  }
                } else {
                  filter.push(f);
                }
              }
            }
            setFilteredData(filter)
          } else if (filterDate1 == 'mes') {
            dateNow.setDate(dateNow.getDate() - 31);
            let dateFormat = dateNow.getTime();
            if (f.horarioDoPedido !== null) {
              if (dateFormat < orderDateTimestamp) {
                if (filterDriver !== 'all') {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f);
                    }
                  }
                } else if (filterSaleChannel !== 'all') {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f)
                    }
                  }
                } else {
                  filter.push(f);
                }
              }
            }
            setFilteredData(filter)
          } else if (filterDate1 == 'ano') {
            dateNow.setDate(dateNow.getDate() - 365);
            let dateFormat = dateNow.getTime();
            if (f.horarioDoPedido !== null) {
              if (dateFormat < orderDateTimestamp) {
                if (filterDriver !== 'all') {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f);
                    }
                  }
                } else if (filterSaleChannel !== 'all') {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                      filter.push(f)
                    } else {
                      filter.push(f)
                    }
                  }
                } else {
                  filter.push(f);
                }
              }
            }
            setFilteredData(filter)
          } else {
            if (filterDriver !== 'all') {
              if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  filter.push(f)
                } else {
                  filter.push(f);
                }
              }
            } else if (filterSaleChannel !== 'all') {
              if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  filter.push(f)
                } else {
                  filter.push(f)
                }
              }
            } else {
              filter.push(f)
            }
          }
        }
        // }
        if (!isNaN(f.horarioDoPedido)) {
          const options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
            timeZone: "America/Los_Angeles",
          };
          f.horarioDoPedido = new Intl.DateTimeFormat("pt-BR", options).format(new Date(f.horarioDoPedido)).replace(',', '')
        }

      })
      setFilteredData(filter)
      document.getElementById("helperFilterDate").setAttribute('style', 'color: rgba(0, 0, 0, 0.6)')
      setAlert("Filtre por data")
    }

    if (dateFilter == "") {
      if (filterDate1 == 'semana') {
        dateNow.setDate(dateNow.getDate() - 7);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          let dateOrder = f.horarioDoPedido.split(' ');
          let splitOrderDate = dateOrder[0].split('/');
          let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
          if (f.horarioDoPedido !== null) {
            if (dateFormat < orderDateTimestamp) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'mes') {
        dateNow.setDate(dateNow.getDate() - 31);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          let dateOrder = f.horarioDoPedido.split(' ');
          let splitOrderDate = dateOrder[0].split('/');
          let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
          if (f.horarioDoPedido !== null) {
            if (dateFormat < orderDateTimestamp) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'ano') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          let dateOrder = f.horarioDoPedido.split(' ');
          let splitOrderDate = dateOrder[0].split('/');
          let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
          if (f.horarioDoPedido !== null) {
            if (dateFormat < orderDateTimestamp) {
              if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f);
                  }
                }
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                    filter.push(f)
                  } else {
                    filter.push(f)
                  }
                }
              } else {
                filter.push(f);
              }
            }
          }
        })
        setFilteredData(filter)
      } else {
        rows.filter(f => {
          let dateOrder = f.horarioDoPedido.split(' ');
          let splitOrderDate = dateOrder[0].split('/');
          let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
          if (filterDriver !== 'all') {
            if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
              if (dateStart <= orderDateTimestamp && dateEnd >= orderDateTimestamp) {
                filter.push(f)
              } else if (filterDate1 == 'semana') {
                dateNow.setDate(dateNow.getDate() - 7);
                let dateFormat = dateNow.getTime();
                // rows.filter(f => {
                if (f.horarioDoPedido !== null) {
                  if (dateFormat < orderDateTimestamp) {
                    if (filterDriver !== 'all') {
                      if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          filter.push(f)
                        } else {
                          filter.push(f);
                        }
                      }
                    } else if (filterSaleChannel !== 'all') {
                      if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          filter.push(f)
                        } else {
                          filter.push(f)
                        }
                      }
                    } else {
                      filter.push(f);
                    }
                  }
                }
                // })
                setFilteredData(filter)
              } else if (filterDate1 == 'mes') {
                dateNow.setDate(dateNow.getDate() - 31);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  let dateOrder = f.horarioDoPedido.split(' ');
                  let splitOrderDate = dateOrder[0].split('/');
                  let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                  if (f.horarioDoPedido !== null) {
                    if (dateFormat < orderDateTimestamp) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'ano') {
                dateNow.setDate(dateNow.getDate() - 365);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  let dateOrder = f.horarioDoPedido.split(' ');
                  let splitOrderDate = dateOrder[0].split('/');
                  let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                  if (f.horarioDoPedido !== null) {
                    if (dateFormat < orderDateTimestamp) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterSaleChannel !== 'all') {
                if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                  filter.push(f);
                }
                setFilteredData(filter)
              } else {
                filter.push(f);
              }
            }
            setFilteredData(filter)
          } else if (dateStart <= orderDateTimestamp && dateEnd >= orderDateTimestamp) {
            filter.push(f)
          } else if (filterSaleChannel !== 'all') {
            if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
              if (dateStart <= orderDateTimestamp && dateEnd >= orderDateTimestamp) {
                filter.push(f)
              } else if (filterDate1 == 'semana') {
                dateNow.setDate(dateNow.getDate() - 7);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  let dateOrder = f.horarioDoPedido.split(' ');
                  let splitOrderDate = dateOrder[0].split('/');
                  let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                  if (f.horarioDoPedido !== null) {
                    if (dateFormat < orderDateTimestamp) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'mes') {
                dateNow.setDate(dateNow.getDate() - 31);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  let dateOrder = f.horarioDoPedido.split(' ');
                  let splitOrderDate = dateOrder[0].split('/');
                  let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                  if (f.horarioDoPedido !== null) {
                    if (dateFormat < orderDateTimestamp) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDate1 == 'ano') {
                dateNow.setDate(dateNow.getDate() - 365);
                let dateFormat = dateNow.getTime();
                rows.filter(f => {
                  let dateOrder = f.horarioDoPedido.split(' ');
                  let splitOrderDate = dateOrder[0].split('/');
                  let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                  if (f.horarioDoPedido !== null) {
                    if (dateFormat < orderDateTimestamp) {
                      if (filterDriver !== 'all') {
                        if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                          if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f);
                          }
                        }
                      } else if (filterSaleChannel !== 'all') {
                        if (f.canalDeVenda == saleChannell[0] || f.canalDeVenda == saleChannell[1]) {
                          if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                            filter.push(f)
                          } else {
                            filter.push(f)
                          }
                        }
                      } else {
                        filter.push(f);
                      }
                    }
                  }
                })
                setFilteredData(filter)
              } else if (filterDriver !== 'all') {
                if (f.entregador == driverName[0] || f.entregador == driverName[1]) {
                  filter.push(f);
                }
              } else {
                filter.push(f);
              }
            }
          } else if (filterStatus !== 'all') {
            if (filterStatus == 'finalizado') {
              if (f.status == 'Finalizado') {
                filter.push(f)

              }
            } else if (filterStatus == 'a caminho') {
              if (f.status == 'Á caminho') {
                filter.push(f)
              }
            } else if (filterStatus == 'entregador notificado') {
              if (f.status == 'Entregador notificado') {
                filter.push(f)
              }
            } else if (filterStatus == 'cancelado') {
              if (f.status == 'Cancelado') {
                filter.push(f)
              }
            } else if (filterStatus == 'finalizadoAberto') {
              if (f.status == 'Finalizado, porém em aberto') {
                filter.push(f)
              }
            }
          } else {
            filter.push(f)
          }
        })
        setFilteredData(filter)
      }
    }

  }, [filterDate1, dateFilter, filterDriver, filterSaleChannel, filterRangeDate, filterStatus])

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return (
    <>
      <Loading />
      <ModalWhats />
      <ModalPainel />
      <ModalCancelar />
      <ModalStatus />
      <ModalDriver />
      <ModalOrdered />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Pedidos"
            columns={columns}
            data={filteredData}
            onSelectionChange={(selectedRows) => setRowsSelected(selectedRows)}
            options={{
              selection: true,
              pageSize: 30,
              pageSizeOptions: false,
              exportButton: true,
              showTitle: false,
              toolbarButtonAlignment: "left",
              exportAllData: true
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
            actions={[
              {
                icon: () => <Select
                  labelId="demo-simple-select-label"
                  className='selectActionOrdered'
                  value={actions}
                  onChange={(e) => setActions(e.target.value)}
                >
                  <MenuItem value={'select_driver'}>Selecionar Entregador</MenuItem>
                  <MenuItem value={'select_status'}>Mudar status do pedido</MenuItem>
                  <MenuItem value={'profile_order'}>Detalhes do pedido</MenuItem>
                  <MenuItem value={'send_message'}>Enviar mensagem para o cliente</MenuItem>
                  <MenuItem value={'edit_order'}>Editar pedido</MenuItem>
                  <MenuItem value={'cancel_order'}>Cancelar Pedido</MenuItem>
                </Select>
              },
              {
                icon: () => <FormControl sx={{ m: 1, minWidth: 220 }}>
                  <CustomProvider locale={pt_BR}>
                    <DateRangePicker
                      placeholder="Selecione as datas"
                      format="dd/MM/yyyy"
                      size="lg"
                      className='dateRangedActionOrdered'
                      onChange={(e) => {
                        if (e == null) {
                          setFilteredData(rows)
                          setDateFilter("")
                        } else {
                          setDateFilter(e)
                        }
                      }
                      }
                    />
                  </CustomProvider>
                  <FormHelperText id="helperFilterDate">Filtre por data</FormHelperText>
                </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por período</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterDate1}
                      onChange={(e) => setFilterDate1(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      <MenuItem value={'semana'}>Última semana</MenuItem>
                      <MenuItem value={'mes'}>Último mês</MenuItem>
                      <MenuItem value={'ano'}>Último ano</MenuItem>
                    </Select>
                    <FormHelperText>Filtre por período especifico</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filterDriver">Filtro por entregador</InputLabel> */}
                    <Select
                      labelId="filterDriver"
                      id="filterDriver"
                      style={{ width: 220 }}
                      value={filterDriver}
                      onChange={(e) => setFilterDriver(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      {driver.map(key => {
                        return (
                          <MenuItem value={key['id'] + "-" + key['name']}>{key['name']}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Filtre por entregador</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por canal de venda</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterSaleChannel}
                      onChange={(e) => setFilterSaleChannel(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      {saleChanell.map(key => {
                        return (
                          <MenuItem value={key['id'] + "-" + key['name']}>{key['name']}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Filtre por canal especifico</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 220 }}>
                    {/* <InputLabel id="filtro-periudo">Filtro por canal de venda</InputLabel> */}
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 220 }}
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      <MenuItem value={'finalizado'}>Finalizado</MenuItem>
                      <MenuItem value={'finalizadoAberto'}>Finalizado, porém em aberto</MenuItem>
                      <MenuItem value={'entregador notificado'}>Entregador Notificado</MenuItem>
                      <MenuItem value={'saiu para entrega'}>Saiu para entrega</MenuItem>
                      <MenuItem value={'a caminho'}>Á caminho</MenuItem>
                      <MenuItem value={'cancelado'}>Cancelado</MenuItem>
                    </Select>
                    <FormHelperText>Filtre por status de pedido</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              }
            ]}
          />
        </Paper>
      </Box>
    </>
  );
}
