export class Impressao {

    constructor(dadosParaImpressao, dadosParaImpressao2, dadosParaImpressao3, dadosParaImpressao4, dadosParaImpressao5, dadosParaImpressao6, dadosParaImpressao7, dadosParaImpressao8) {
        this.dadosParaImpressao = dadosParaImpressao.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao ;
        this.dadosParaImpressao2 = dadosParaImpressao2.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao2;
        this.dadosParaImpressao3 = dadosParaImpressao3.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao3;
        this.dadosParaImpressao4 = dadosParaImpressao4.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao4;
        this.dadosParaImpressao5 = dadosParaImpressao5.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao5;
        this.dadosParaImpressao6 = dadosParaImpressao6.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao6;
        this.dadosParaImpressao7 = dadosParaImpressao7.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao7;
        this.dadosParaImpressao8 = dadosParaImpressao8.length == 0 ? [{ label: 'Não possui', value: 0 }] : dadosParaImpressao8;
    }

    async PreparaDocumento() {
        const documento = this.GerarDocumento();
        return documento;
    }

    GerarDocumento() {
        const body = this.dadosParaImpressao.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body2 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body3 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body4 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body5 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body6 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body7 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const body8 = this.dadosParaImpressao2.map((prod) => {
            return [
                { text: prod.label, fontSize: 12 },
                { text: prod.value, fontSize: 12 }
            ];
        });
        const documento = {
            pageSize: 'A4',
            pageMargins: [14, 53, 14, 48],
            header: function () {
                return {
                    margin: [14, 12, 14, 0],
                    layout: 'noBorders',
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                { text: 'RELATÓRIO', style: 'reportName' }
                            ]
                        ],
                    },
                };
            },
            content: [
                { text: 'Canais de venda', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body
                    }
                },
                { text: 'Venda por entregador', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body2
                    }
                },
                { text: 'Venda pde produto', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body3
                    }
                },
                { text: 'Vasilhames recebidos', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body4
                    }
                },
                { text: 'Venda por cliente físico/juridico', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body5
                    }
                },
                { text: 'Venda por tipo de comércio', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body6
                    }
                },
                { text: 'Tipo de pagamento por venda', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body7
                    }
                },
                { text: 'Venda por setor', style: 'header' },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', 55, 55],
                        body: body8
                    }
                }
            ],
            footer(currentPage, pageCount) {
                return {
                    layout: 'noBorders',
                    margin: [14, 0, 14, 22],
                    table: {
                        widths: ['auto'],
                        body: [
                            [
                                {
                                    text:
                                        '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                                    alignment: 'center',
                                    fontSize: 5,
                                },
                            ],
                            [
                                [
                                    {
                                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                                        fontSize: 7,
                                        alignment: 'right',
                                        /* horizontal, vertical */
                                        margin: [3, 0],
                                    },
                                    {
                                        text: 'Clube do revendedor de gás',
                                        fontSize: 7,
                                        alignment: 'center',
                                    },
                                ],
                            ],
                        ],
                    },
                };
            },
            styles: {
                reportName: {
                    fontSize: 18,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 4, 0, 0],
                },
                header: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 5, 0, 10]
                }
            },

        };
        return documento;
    }
}