import React from 'react';
import { useState, useEffect } from 'react';
import './report.css'
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { Card, Button, Offcanvas } from "react-bootstrap";

import MonthlyBarChart from './MonthlyBarChart';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import anuncion from '../../image/anuncio.png';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import pt_BR from 'rsuite/locales/pt_BR'
import { CustomProvider, DateRangePicker } from 'rsuite';
import { FaRegFilePdf } from "react-icons/fa6";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";
import { array } from 'prop-types';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Impressao } from './Impressao.js';

const Dashboard = () => {
    const theme = useTheme();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [plusGrafic, setPlusGrafic] = useState(false);
    const [plusGraficText, setplusGraficText] = useState('CARREGAR MAIS');
    const [driverLocalization, setDriverLocalization] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [orderedTable, setOrderedTable] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [clientPhysicAll, setClientPhysicAll] = React.useState([]);
    const [clientBusinessAll, setClientBusinessAll] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([])
    const [dateFilter, setDateFilter] = React.useState('');
    const [monthValueNow, setMonthValueNow] = React.useState(0);
    const [dayValueNow, setDayValueNow] = React.useState(0)

    const [localizacao, setLocalizacao] = useState();
    const [notas, setNotas] = useState();
    const [geradorImagem, setGeradorImagem] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [relatorio, setRelatorio] = useState();
    const [appCliente, SetAppClient] = useState();
    const [marketing, setMarketing] = useState();
    const [totalToday, setTotalToday] = useState(0)

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    // useEffect(() => {
    //     axios
    //         .get("https://api.clubedorevendedordegas.com.br/TodasAddons")
    //         .then((response) => {
    //             let addonsArray = [];
    //             response.data.map(addons => {
    //                 if (addons.name == 'Painel') {
    //                     addonsArray.push(JSON.parse(addons.addons))
    //                 }
    //             })
    //             addonsArray[0].map(key => {
    //                 if (key[0].addon == 'whatsapp') {
    //                     setWhatsapp(key[0].status)
    //                 } else if (key[0].addon == 'gerador de imagem') {
    //                     setGeradorImagem(key[0].status)
    //                 } else if (key[0].addon == 'app cliente') {
    //                     SetAppClient(key[0].status)
    //                 } else if (key[0].addon == 'app entregador/localizacao') {
    //                     setLocalizacao(key[0].status)
    //                 } else if (key[0].addon == 'relatorios') {
    //                     setRelatorio(key[0].status)
    //                 } else if (key[0].addon == 'notas') {
    //                     setNotas(key[0].status)
    //                 } else if (key[0].addon == 'marketing') {
    //                     setMarketing(key[0].status)
    //                 }
    //             })

    //         })

    //         .catch((err) => {
    //             console.error("ops! ocorreu um erro" + err);
    //         });
    // }, []);
    const dispatch = useDispatch();
    useEffect(() => {
        let db = '';
        if (currentUser !== undefined) {
            db = currentUser.db;
        } else {
            db = 'clubeApi';
        }
        api
            .get("/Configuracoes/" + currentUser.db)
            .then((response) => {
                dispatch(configurationTheme({ logoLogin: response.data[0].logoLogin, logoMenu: response.data[0].logoMenu, colorPrimary: response.data[0].colorPrimary, colorSecundary: response.data[0].colorSecundary }));
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const MINUTE_MS = 30000;

    React.useEffect(() => {
        const interval = setInterval(() => {
            api
                .get("/TodasLocalizacoes" + '/' + currentUser.db)
                .then((response) => {
                    setLocalization(response.data);
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosMotoristas" + '/' + currentUser.db)
            .then((response) => {
                setDriverLocalization(response.data);
                setDriver(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: -23.530135851187612,
        lng: -46.78384858832871
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA0Tp3QicqpegAAEaBuSJFugiKyCM7LAUU"
    })

    function createData(name, address, cellphone, date, value) {
        return { name, address, cellphone, date, value };
    }

    let totalOldMonth = 0;
    let totalMonth = 0
    // let totalToday = 0;
    let totalYesterday = 0;
    var clientPhysic = []
    var clientBusiness = [];
    var chApp = [];
    var chAuto = [];
    var chPhone = [];
    var chWhats = [];
    var vaUltra = [];
    var vaLiqui = [];
    var vaCopa = [];
    var vaSuper = [];
    var vaConsi = [];
    var vaButano = [];
    var vaServi = [];
    var vaOutros = [];
    var driver1 = [];
    var driver2 = [];
    var driver3 = [];
    var driver4 = [];
    var driver5 = [];
    var driver6 = [];
    var driver7 = [];
    var driver8 = [];
    var driver9 = [];
    var driver10 = [];
    var driverName1 = '';
    var driverName2 = '';
    var driverName3 = '';
    var driverName4 = '';
    var driverName5 = '';
    var driverName6 = '';
    var driverName7 = '';
    var driverName8 = '';
    var driverName9 = '';
    var driverName10 = '';
    let detailsDriver = [];
    var categoryName1 = '';
    var categoryName2 = '';
    var categoryName3 = '';
    var categoryName4 = '';
    var categoryName5 = '';
    var category1 = [];
    var category2 = [];
    var category3 = [];
    var category4 = [];
    var category5 = [];
    let detailsCategory = [];
    var paymentName1 = "";
    var paymentName2 = "";
    var paymentName3 = "";
    var paymentName4 = "";
    var paymentName5 = "";
    var paymentName6 = "";
    var paymentName7 = "";
    var paymentName8 = "";
    var paymentName9 = "";
    var paymentName10 = "";
    var paymentType1 = [];
    var paymentType2 = [];
    var paymentType3 = [];
    var paymentType4 = [];
    var paymentType5 = [];
    var paymentType6 = [];
    var paymentType7 = [];
    var paymentType8 = [];
    var paymentType9 = [];
    var paymentType10 = [];
    var detailsPayment = [];
    var sectorName1 = '';
    var sectorName2 = '';
    var sectorName3 = '';
    var sectorName4 = '';
    var sectorName5 = '';
    var sectorName6 = '';
    var sectorName7 = '';
    var sectorName8 = '';
    var sectorName9 = '';
    var sectorName10 = '';
    var sector1 = [];
    var sector2 = [];
    var sector3 = [];
    var sector4 = [];
    var sector5 = [];
    var sector6 = [];
    var sector7 = [];
    var sector8 = [];
    var sector9 = [];
    var sector10 = [];
    var detailsSector = [];
    var product1 = [];
    var product2 = [];
    var product3 = [];
    var product4 = [];
    var product5 = [];
    var product6 = [];
    var product7 = [];
    var product8 = [];
    var product9 = [];
    var product10 = [];
    var detailsProduct = [];
    var productName1 = '';
    var productName2 = '';
    var productName3 = '';
    var productName4 = '';
    var productName5 = '';
    var productName6 = '';
    var productName7 = '';
    var productName8 = '';
    var productName9 = '';
    var productName10 = '';

    let db = 'clubeApi';
    if (currentUser !== undefined || currentUser !== null) {
        db = currentUser.db
    }

    useEffect(() => {
        api
            .get("/TodosPedidos2" + '/' + currentUser.db)
            .then((response) => {
                setOrdered(response.data);
                setOrderedTable(response.data.reverse())
                let array = []
                response.data.map(key => {
                    let monthNow = new Date().getMonth() + 1;
                    let splitOrderDateComplete = key['order_date'].split(' ');
                    let splitOrderDate = splitOrderDateComplete[0].split('/');
                    let monthOrderDate = Number(splitOrderDate[1]);
                    if (monthNow == monthOrderDate) {
                        array.push(key)
                    }
                })
                setFilteredData(array)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/PedidosFinalizadosDia" + '/' + currentUser.db)
            .then((response) => {
                setTotalToday(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodosCanaisVendas" + '/' + currentUser.db)
            .then((response) => {
                setSaleChanell(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodosClientesJuridicos" + '/' + currentUser.db)
            .then((response) => {
                setCBusiness(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodasCategoriasComerciais" + '/' + currentUser.db)
            .then((response) => {
                setCategoryBusiness(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodasFormasPagamentos" + '/' + currentUser.db)
            .then((response) => {
                setPayment(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodosSetores" + '/' + currentUser.db)
            .then((response) => {
                setSector(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        api
            .get("/TodosProdutos" + '/' + currentUser.db)
            .then((response) => {
                setProducts(response.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    useEffect(() => {
        const infinity = document.getElementsByClassName('MuiChip-root MuiChip-combined MuiChip-sizeSmall MuiChip-colorPrimary MuiChip-combinedPrimary');
        for (let e of infinity) {
            if (e.children[1].innerText == 'Infinity%') {
                e.style.display = 'none'
            }
        }

    }, [filteredData])

    React.useEffect(() => {
        api
            .get("/TodosClientesFisicos" + '/' + currentUser.db)
            .then((response) => setClientPhysicAll(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosClientesJuridicos" + '/' + currentUser.db)
            .then((response) => setClientBusinessAll(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const arrayOrderedTable = [];
    useEffect(() => {
        for (var i = 0; i < 5; i++) {
            let orderedFor = filteredData[i];
            if (orderedFor !== undefined) {
                if (orderedFor.typeClient == 'fisico') {
                    clientPhysicAll.map(key => {
                        if (key['id'] == orderedFor.client_id) {
                            arrayOrderedTable.push(createData(key['name'], key['street'] + ", " + key['number'], key['phone'], orderedFor.order_date, parseFloat(orderedFor.total)))
                        }
                    })
                } else {
                    clientBusinessAll.map(key => {
                        if (key['id'] == orderedFor.client_id) {
                            arrayOrderedTable.push(createData(key['name'], key['street'] + ", " + key['number'], key['phone'], orderedFor.order_date, parseFloat(orderedFor.total)))
                        }
                    })
                }

            }
            setRows(arrayOrderedTable)
        }
    }, [filteredData])

    products.map(key => {
        if (key['id'] == 1) {
            productName1 = key['name']
        }
        if (key['id'] == 2) {
            productName2 = key['name']
        }
        if (key['id'] == 3) {
            productName3 = key['name']
        }
        if (key['id'] == 4) {
            productName4 = key['name']
        }
        if (key['id'] == 5) {
            productName5 = key['name']
        }
        if (key['id'] == 6) {
            productName6 = key['name']
        }
        if (key['id'] == 7) {
            productName7 = key['name']
        }
        if (key['id'] == 8) {
            productName8 = key['name']
        }
        if (key['id'] == 9) {
            productName9 = key['name']
        }
        if (key['id'] == 10) {
            productName10 = key['name']
        }
    })

    filteredData.map(key => {
        if (key['status'] == "Finalizado") {
            if (key['delivery_date'] !== null) {
                var date = key['delivery_date'].split(" ");
                var dateSplit = date[0].split("/");
                var dateEU = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
                let dateTime = dateEU.getTime();

                var today = new Date().toLocaleDateString("pt-br");
                var todaySplit = today.split("/");
                var dateOldStart = new Date(todaySplit[2], todaySplit[1] - 2, "1")
                var dateOldStartTime = dateOldStart.getTime();

                var dateOldEnd = new Date(todaySplit[2], todaySplit[1] - 2, "30")
                var dateOldEndTime = dateOldEnd.getTime();

                var dateStart = new Date(todaySplit[2], todaySplit[1] - 1, "1")
                var dateStartTime = dateStart.getTime();

                var dateEnd = new Date(todaySplit[2], todaySplit[1] - 1, "30")
                var dateEndTime = dateEnd.getTime();

                if (dateTime >= dateOldStartTime && dateTime <= dateOldEndTime) {
                    totalOldMonth += Number(key['total']);
                }

                if (dateTime >= dateStartTime && dateTime <= dateEndTime) {
                    totalMonth += Number(key['total']);
                }

                var dateYesterday = new Date();
                dateYesterday.setDate(dateYesterday.getDate() - 1)

                var dateNow = new Date().toLocaleDateString("pt-br");

                if (dateYesterday.toLocaleDateString("pt-br") == date[0]) {
                    totalYesterday += Number(key['total'])
                }

                // if (dateNow == date[0]) {
                //     totalToday += Number(key['total'])
                // }
            }

            if (key['typeClient'] == 'fisico') {
                clientPhysic.push(key['id'])
            }

            if (key['typeClient'] == 'juridico') {
                clientBusiness.push(key['client_id'])
            }

            let splitProducts = key['products'].split(',');
            if (splitProducts.length > 1) {
                splitProducts.map(split => {

                    let splitProduct = split.trim().split(' ');
                    let productQntSplit = splitProduct[0].split('x');
                    let productQnt = Number(productQntSplit[0]);
                    splitProduct.shift()

                    for (var i = 1; productQnt >= i; i++) {
                        let product = splitProduct.join(' ');
                        products.map(key => {
                            if (key['name'] == product) {
                                if (key['id'] == 1) {
                                    product1.push(key['id'])
                                }
                                if (key['id'] == 2) {
                                    product2.push(key['id'])
                                }
                                if (key['id'] == 3) {
                                    product3.push(key['id'])
                                }
                                if (key['id'] == 4) {
                                    product4.push(key['id'])
                                }
                                if (key['id'] == 5) {
                                    product5.push(key['id'])
                                }
                                if (key['id'] == 6) {
                                    product6.push(key['id'])
                                }
                                if (key['id'] == 7) {
                                    product7.push(key['id'])
                                }
                                if (key['id'] == 8) {
                                    product8.push(key['id'])
                                }
                                if (key['id'] == 9) {
                                    product9.push(key['id'])
                                }
                                if (key['id'] == 10) {
                                    product10.push(key['id'])
                                }
                            }
                        })
                    }

                })
            } else {
                let splitProduct = key['products'].trim().split(' ');
                let productQntSplit = splitProduct[0].split('x');
                let productQnt = Number(productQntSplit[0]);
                splitProduct.shift()

                for (var i = 1; productQnt >= i; i++) {
                    let product = splitProduct.join(' ');
                    products.map(key => {
                        if (key['name'] == product) {
                            if (key['id'] == 1) {
                                product1.push(key['id'])
                            }
                            if (key['id'] == 2) {
                                product2.push(key['id'])
                            }
                            if (key['id'] == 3) {
                                product3.push(key['id'])
                            }
                            if (key['id'] == 4) {
                                product4.push(key['id'])
                            }
                            if (key['id'] == 5) {
                                product5.push(key['id'])
                            }
                            if (key['id'] == 6) {
                                product6.push(key['id'])
                            }
                            if (key['id'] == 7) {
                                product7.push(key['id'])
                            }
                            if (key['id'] == 8) {
                                product8.push(key['id'])
                            }
                            if (key['id'] == 9) {
                                product9.push(key['id'])
                            }
                            if (key['id'] == 10) {
                                product10.push(key['id'])
                            }
                        }
                    })
                }

            }

            saleChanell.map(k => {
                if (k['id'] == key['sale_channel']) {
                    if (k['name'] == 'APP') {
                        chApp.push(key['id'])
                    }
                    if (k['name'] == 'Automático') {
                        chAuto.push(key['id'])
                    }
                    if (k['name'] == 'Whatsapp') {
                        chWhats.push(key['id'])
                    }
                    if (k['name'] == 'Telefone') {
                        chPhone.push(key['id'])
                    }
                }
            })

            if (key['vasilhame'] == "Ultragaz") {
                vaUltra.push(key['id'])
            } else if (key['vasilhame'] == "Liquigaz") {
                vaLiqui.push(key['id'])
            } else if (key['vasilhame'] == "Copagaz") {
                vaCopa.push(key['id'])
            } else if (key['vasilhame'] == "Supergasbraz") {
                vaSuper.push(key['id'])
            } else if (key['vasilhame'] == "Consigaz") {
                vaConsi.push(key['id'])
            } else if (key['vasilhame'] == "Butano") {
                vaButano.push(key['id'])
            } else if (key['vasilhame'] == "Servigás") {
                vaServi.push(key['id'])
            } else {
                vaOutros.push(key['id'])
            }

            if (key['driver_id'] == 1) {
                driver1.push(key['driver_id'])
            }
            if (key['driver_id'] == 2) {
                driver2.push(key['driver_id'])
            }
            if (key['driver_id'] == 3) {
                driver3.push(key['driver_id'])
            }
            if (key['driver_id'] == 4) {
                driver4.push(key['driver_id'])
            }
            if (key['driver_id'] == 5) {
                driver5.push(key['driver_id'])
            }
            if (key['driver_id'] == 6) {
                driver6.push(key['driver_id'])
            }
            if (key['driver_id'] == 7) {
                driver7.push(key['driver_id'])
            }
            if (key['driver_id'] == 8) {
                driver8.push(key['driver_id'])
            }
            if (key['driver_id'] == 9) {
                driver9.push(key['driver_id'])
            }
            if (key['driver_id'] == 10) {
                driver10.push(key['driver_id'])
            }

            if (key['payment_id'] == 1) {
                paymentType1.push(key['id'])
            }
            if (key['payment_id'] == 2) {
                paymentType2.push(key['id'])
            }
            if (key['payment_id'] == 3) {
                paymentType3.push(key['id'])
            }
            if (key['payment_id'] == 4) {
                paymentType4.push(key['id'])
            }
            if (key['payment_id'] == 5) {
                paymentType5.push(key['id'])
            }
            if (key['payment_id'] == 6) {
                paymentType6.push(key['id'])
            }
            if (key['payment_id'] == 7) {
                paymentType7.push(key['id'])
            }
            if (key['payment_id'] == 8) {
                paymentType8.push(key['id'])
            }
            if (key['payment_id'] == 9) {
                paymentType9.push(key['id'])
            }
            if (key['payment_id'] == 10) {
                paymentType10.push(key['id'])
            }

            driver.map(k => {
                if (key['driver_id'] == k['id']) {
                    if (k['sector_id'] == 1) {
                        sector1.push(key['id'])
                    }
                    if (k['sector_id'] == 2) {
                        sector2.push(key['id'])
                    }
                    if (k['sector_id'] == 3) {
                        sector3.push(key['id'])
                    }
                    if (k['sector_id'] == 4) {
                        sector4.push(key['id'])
                    }
                    if (k['sector_id'] == 5) {
                        sector5.push(key['id'])
                    }
                    if (k['sector_id'] == 6) {
                        sector6.push(key['id'])
                    }
                    if (k['sector_id'] == 7) {
                        sector7.push(key['id'])
                    }
                    if (k['sector_id'] == 8) {
                        sector8.push(key['id'])
                    }
                    if (k['sector_id'] == 9) {
                        sector9.push(key['id'])
                    }
                    if (k['sector_id'] == 10) {
                        sector10.push(key['id'])
                    }
                }
            })
        }

    })

    driver.map(key => {
        if (key['id'] == 1) {
            driverName1 = key['name']
        }
        if (key['id'] == 2) {
            driverName2 = key['name']
        }
        if (key['id'] == 3) {
            driverName3 = key['name']
        }
        if (key['id'] == 4) {
            driverName4 = key['name']
        }
        if (key['id'] == 5) {
            driverName5 = key['name']
        }
        if (key['id'] == 6) {
            driverName6 = key['name']
        }
        if (key['id'] == 7) {
            driverName7 = key['name']
        }
        if (key['id'] == 8) {
            driverName8 = key['name']
        }
        if (key['id'] == 9) {
            driverName9 = key['name']
        }
        if (key['id'] == 10) {
            driverName10 = key['name']
        }
    })

    clientBusiness.map(key => {
        console.log(key)
        cBusiness.map(b => {
            if (key == b['id']) {
                if (b['commercial_type'] == 1) {
                    category1.push(key['id'])
                }
                if (b['commercial_type'] == 2) {
                    category2.push(key['id'])
                }
                if (b['commercial_type'] == 3) {
                    category3.push(key['id'])
                }
                if (b['commercial_type'] == 4) {
                    category4.push(key['id'])
                }
                if (b['commercial_type'] == 5) {
                    category5.push(key['id'])
                }
            }
        })
    })

    CategoryBusiness.map(k => {
        if (k['id'] == 1) {
            categoryName1 = k['name']
        }
        if (k['id'] == 2) {
            categoryName2 = k['name']
        }
        if (k['id'] == 3) {
            categoryName3 = k['name']
        }
        if (k['id'] == 4) {
            categoryName4 = k['name']
        }
        if (k['id'] == 5) {
            categoryName5 = k['name']
        }
    })

    payment.map(key => {
        if (key['id'] == 1) {
            paymentName1 = key['name'];
        }
        if (key['id'] == 2) {
            paymentName2 = key['name'];
        }
        if (key['id'] == 3) {
            paymentName3 = key['name'];
        }
        if (key['id'] == 4) {
            paymentName4 = key['name'];
        }
        if (key['id'] == 5) {
            paymentName5 = key['name'];
        }
        if (key['id'] == 6) {
            paymentName6 = key['name'];
        }
        if (key['id'] == 7) {
            paymentName7 = key['name'];
        }
        if (key['id'] == 8) {
            paymentName8 = key['name'];
        }
        if (key['id'] == 9) {
            paymentName9 = key['name'];
        }
        if (key['id'] == 10) {
            paymentName10 = key['name'];
        }
    })


    sector.map(key => {
        if (key['id'] == 1) {
            sectorName1 = key['name'];
        }
        if (key['id'] == 2) {
            sectorName2 = key['name'];
        }
        if (key['id'] == 3) {
            sectorName3 = key['name'];
        }
        if (key['id'] == 4) {
            sectorName4 = key['name'];
        }
        if (key['id'] == 5) {
            sectorName5 = key['name'];
        }
        if (key['id'] == 6) {
            sectorName6 = key['name'];
        }
        if (key['id'] == 7) {
            sectorName7 = key['name'];
        }
        if (key['id'] == 8) {
            sectorName8 = key['name'];
        }
        if (key['id'] == 9) {
            sectorName9 = key['name'];
        }
        if (key['id'] == 10) {
            sectorName10 = key['name'];
        }
    })



    var resultChAuto = chAuto.length;
    var resultChApp = chApp.length;
    var resultChWhats = chWhats.length;
    var resultChPhone = chPhone.length;

    var resultClientPhysic = clientPhysic.length;
    var resultClientBusiness = clientBusiness.length;

    var resultVaUltra = vaUltra.length;
    var resultVaLiqui = vaLiqui.length;
    var resultVaCopa = vaCopa.length;
    var resultVaSuper = vaSuper.length;
    var resultVaConsi = vaConsi.length;
    var resultVaButano = vaButano.length;
    var resultVaServi = vaServi.length;
    var resultVaOutros = vaOutros.length;

    var resultDriver1 = driver1.length;
    var resultDriver2 = driver2.length;
    var resultDriver3 = driver3.length;
    var resultDriver4 = driver4.length;
    var resultDriver5 = driver5.length;
    var resultDriver6 = driver6.length;
    var resultDriver7 = driver7.length;
    var resultDriver8 = driver8.length;
    var resultDriver9 = driver9.length;
    var resultDriver10 = driver10.length;

    for (var i = 0, l = driver.length; i < l; i++) {
        if (i == 0) {
            if (driverName1 !== '') {
                detailsDriver.push({ label: driverName1, value: resultDriver1 })
            }
        }
        if (i == 1) {
            if (driverName2 !== '') {
                detailsDriver.push({ label: driverName2, value: resultDriver2 })
            }
        }
        if (i == 2) {
            if (driverName3 !== '') {
                detailsDriver.push({ label: driverName3, value: resultDriver3 })
            }
        }
        if (i == 3) {
            if (driverName4 !== '') {
                detailsDriver.push({ label: driverName4, value: resultDriver4 })
            }
        }
        if (i == 4) {
            if (driverName5 !== '') {
                detailsDriver.push({ label: driverName5, value: resultDriver5 })
            }
        }
        if (i == 5) {
            if (driverName6 !== '') {
                detailsDriver.push({ label: driverName6, value: resultDriver6 })
            }
        }
        if (i == 6) {
            if (driverName7 !== '') {
                detailsDriver.push({ label: driverName7, value: resultDriver7 })
            }
        }
        if (i == 7) {
            if (driverName8 !== '') {
                detailsDriver.push({ label: driverName8, value: resultDriver8 })
            }
        }
        if (i == 8) {
            if (driverName9 !== '') {
                detailsDriver.push({ label: driverName9, value: resultDriver9 })
            }
        }
        if (i == 9) {
            if (driverName10 !== '') {
                detailsDriver.push({ label: driverName10, value: resultDriver10 })
            }
        }
    }

    var resultCategory1 = category1.length;
    var resultCategory2 = category2.length;
    var resultCategory3 = category3.length;
    var resultCategory4 = category4.length;
    var resultCategory5 = category5.length;

    for (var i = 0, l = CategoryBusiness.length; i < l; i++) {
        if (i == 0) {
            detailsCategory.push({ label: categoryName1, value: resultCategory1 })
        }
        if (i == 1) {
            detailsCategory.push({ label: categoryName2, value: resultCategory2 })
        }
        if (i == 2) {
            detailsCategory.push({ label: categoryName3, value: resultCategory3 })
        }
        if (i == 3) {
            detailsCategory.push({ label: categoryName4, value: resultCategory4 })
        }
        if (i == 4) {
            detailsCategory.push({ label: categoryName5, value: resultCategory5 })
        }
    }

    var resultPayment1 = paymentType1.length;
    var resultPayment2 = paymentType2.length;
    var resultPayment3 = paymentType3.length;
    var resultPayment4 = paymentType4.length;
    var resultPayment5 = paymentType5.length;
    var resultPayment6 = paymentType6.length;
    var resultPayment7 = paymentType7.length;
    var resultPayment8 = paymentType8.length;
    var resultPayment9 = paymentType9.length;
    var resultPayment10 = paymentType10.length;

    for (var i = 0, l = payment.length; i < l; i++) {
        if (i == 0) {
            detailsPayment.push({ label: paymentName1, value: resultPayment1 })
        }
        if (i == 1) {
            detailsPayment.push({ label: paymentName2, value: resultPayment2 })
        }
        if (i == 2) {
            detailsPayment.push({ label: paymentName3, value: resultPayment3 })
        }
        if (i == 3) {
            detailsPayment.push({ label: paymentName4, value: resultPayment4 })
        }
        if (i == 4) {
            detailsPayment.push({ label: paymentName5, value: resultPayment5 })
        }
        if (i == 5) {
            detailsPayment.push({ label: paymentName6, value: resultPayment6 })
        }
        if (i == 6) {
            detailsPayment.push({ label: paymentName7, value: resultPayment7 })
        }
        if (i == 7) {
            detailsPayment.push({ label: paymentName8, value: resultPayment8 })
        }
        if (i == 8) {
            detailsPayment.push({ label: paymentName9, value: resultPayment9 })
        }
        if (i == 9) {
            detailsPayment.push({ label: paymentName10, value: resultPayment10 })
        }
    }

    var resultSector1 = sector1.length;
    var resultSector2 = sector2.length;
    var resultSector3 = sector3.length;
    var resultSector4 = sector4.length;
    var resultSector5 = sector5.length;
    var resultSector6 = sector6.length;
    var resultSector7 = sector7.length;
    var resultSector8 = sector8.length;
    var resultSector9 = sector9.length;
    var resultSector10 = sector10.length;

    for (var i = 0, l = sector.length; i < l; i++) {
        if (i == 0) {
            detailsSector.push({ label: sectorName1, value: resultSector1 })
        }
        if (i == 1) {
            detailsSector.push({ label: sectorName2, value: resultSector2 })
        }
        if (i == 2) {
            detailsSector.push({ label: sectorName3, value: resultSector3 })
        }
        if (i == 3) {
            detailsSector.push({ label: sectorName4, value: resultSector4 })
        }
        if (i == 4) {
            detailsSector.push({ label: sectorName5, value: resultSector5 })
        }
        if (i == 5) {
            detailsSector.push({ label: sectorName6, value: resultSector6 })
        }
        if (i == 6) {
            detailsSector.push({ label: sectorName7, value: resultSector7 })
        }
        if (i == 7) {
            detailsSector.push({ label: sectorName8, value: resultSector8 })
        }
        if (i == 8) {
            detailsSector.push({ label: sectorName9, value: resultSector9 })
        }
        if (i == 9) {
            detailsSector.push({ label: sectorName10, value: resultSector10 })
        }
    }

    var resultProduct1 = product1.length;
    var resultProduct2 = product2.length;
    var resultProduct3 = product3.length;
    var resultProduct4 = product4.length;
    var resultProduct5 = product5.length;
    var resultProduct6 = product6.length;
    var resultProduct7 = product7.length;
    var resultProduct8 = product8.length;
    var resultProduct9 = product9.length;
    var resultProduct10 = product10.length;

    for (var i = 0, l = products.length; i < l; i++) {
        if (i == 0) {
            if (productName1 !== '') {
                detailsProduct.push({ label: productName1, value: resultProduct1 })
            }

        }
        if (i == 1) {
            if (productName2 !== '') {
                detailsProduct.push({ label: productName2, value: resultProduct2 })
            }
        }
        if (i == 2) {
            if (productName3 !== '') {
                detailsProduct.push({ label: productName3, value: resultProduct3 })
            }
        }
        if (i == 3) {
            if (productName4 !== '') {
                detailsProduct.push({ label: productName4, value: resultProduct4 })
            }
        }
        if (i == 4) {
            if (productName5 !== '') {
                detailsProduct.push({ label: productName5, value: resultProduct5 })
            }
        }
        if (i == 5) {
            if (productName6 !== '') {
                detailsProduct.push({ label: productName6, value: resultProduct6 })
            }
        }
        if (i == 6) {
            if (productName7 !== '') {
                detailsProduct.push({ label: productName7, value: resultProduct7 })
            }
        }
        if (i == 7) {
            if (productName8 !== '') {
                detailsProduct.push({ label: productName8, value: resultProduct8 })
            }
        }
        if (i == 8) {
            if (productName9 !== '') {
                detailsProduct.push({ label: productName9, value: resultProduct9 })
            }
        }
        if (i == 9) {
            if (productName10 !== '') {
                detailsProduct.push({ label: productName10, value: resultProduct10 })
            }
        }
    }

    var porc = Number(totalMonth) / Number(totalOldMonth) * 100
    var porcDay = Number(totalToday) / Number(totalYesterday) * 100

    if (isNaN(porcDay)) {
        porcDay = 0;
    }

    if (isNaN(porc)) {
        porc = 0;
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        let dateStart = new Date(formatDate(dateFilter[0])).getTime()
        let dateEnd = new Date(formatDate(dateFilter[1])).getTime()
        let array = [];
        let monthNow = new Date().getMonth() + 1;
        if (dateFilter) {
            ordered.map(key => {
                if (key['status'] == "Finalizado") {
                    if (key['delivery_date'] !== null) {
                        let splitOrderDateComplete = key['order_date'].split(' ');
                        let splitOrderDate = splitOrderDateComplete[0].split('/');
                        let orderDateTimestamp = new Date(`${splitOrderDate[2]}-${splitOrderDate[1]}-${splitOrderDate[0]}`).getTime();
                        if (orderDateTimestamp >= dateStart && orderDateTimestamp <= dateEnd) {
                            array.push(key)
                            totalMonth += Number(key['total']);
                        }
                    }
                }
            })
        } else {
            ordered.map(key => {
                if (key['status'] == "Finalizado") {
                    if (key['delivery_date'] !== null) {
                        let splitOrderDateComplete = key['order_date'].split(' ');
                        let splitOrderDate = splitOrderDateComplete[0].split('/');
                        let monthOrderDate = Number(splitOrderDate[1]);
                        if (monthNow == monthOrderDate) {
                            array.push(key)
                        }
                    }
                }
            })
        }

        setFilteredData(array)
    }, [dateFilter])

    useEffect(() => {
        filteredData.map(key => {
            if (key['status'] == "Finalizado") {
                if (key['delivery_date'] !== null) {
                    var date = key['delivery_date'].split(" ");
                    var dateSplit = date[0].split("/");
                    var dateEU = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]);
                    let dateTime = dateEU.getTime();

                    var today = new Date().toLocaleDateString("pt-br");
                    var todaySplit = today.split("/");
                    var dateOldStart = new Date(todaySplit[2], todaySplit[1] - 2, "1")
                    var dateOldStartTime = dateOldStart.getTime();

                    var dateOldEnd = new Date(todaySplit[2], todaySplit[1] - 2, "30")
                    var dateOldEndTime = dateOldEnd.getTime();

                    var dateStart = new Date(todaySplit[2], todaySplit[1] - 1, "1")
                    var dateStartTime = dateStart.getTime();

                    var dateEnd = new Date(todaySplit[2], todaySplit[1] - 1, "30")
                    var dateEndTime = dateEnd.getTime();

                    if (dateTime >= dateOldStartTime && dateTime <= dateOldEndTime) {
                        totalOldMonth += Number(key['total']);
                    }

                    // if (dateTime >= dateStartTime && dateTime <= dateEndTime) {
                    //     totalMonth += Number(key['total']);
                    // }

                    var dateYesterday = new Date();
                    dateYesterday.setDate(dateYesterday.getDate() - 1)

                    var dateNow = new Date().toLocaleDateString("pt-br");

                    if (dateYesterday.toLocaleDateString("pt-br") == date[0]) {
                        totalYesterday += Number(key['total'])
                    }

                    // if (dateNow == date[0]) {
                    //     totalToday += Number(key['total'])
                    // }
                }

                if (key['typeClient'] == 'fisico') {
                    clientPhysic.push(key['id'])
                }

                if (key['typeClient'] == 'juridico') {
                    clientBusiness.push(key['client_id'])
                }

                let splitProducts = key['products'].split(',');
                if (splitProducts.length > 1) {
                    splitProducts.map(split => {

                        let splitProduct = split.trim().split(' ');
                        let productQntSplit = splitProduct[0].split('x');
                        let productQnt = Number(productQntSplit[0]);
                        splitProduct.shift()

                        for (var i = 1; productQnt >= i; i++) {
                            let product = splitProduct.join(' ');
                            products.map(key => {
                                if (key['name'] == product) {
                                    if (key['id'] == 1) {
                                        product1.push(key['id'])
                                    }
                                    if (key['id'] == 2) {
                                        product2.push(key['id'])
                                    }
                                    if (key['id'] == 3) {
                                        product3.push(key['id'])
                                    }
                                    if (key['id'] == 4) {
                                        product4.push(key['id'])
                                    }
                                    if (key['id'] == 5) {
                                        product5.push(key['id'])
                                    }
                                    if (key['id'] == 6) {
                                        product6.push(key['id'])
                                    }
                                    if (key['id'] == 7) {
                                        product7.push(key['id'])
                                    }
                                    if (key['id'] == 8) {
                                        product8.push(key['id'])
                                    }
                                    if (key['id'] == 9) {
                                        product9.push(key['id'])
                                    }
                                    if (key['id'] == 10) {
                                        product10.push(key['id'])
                                    }
                                }
                            })
                        }

                    })
                } else {
                    let splitProduct = key['products'].trim().split(' ');
                    let productQntSplit = splitProduct[0].split('x');
                    let productQnt = Number(productQntSplit[0]);
                    splitProduct.shift()

                    for (var i = 1; productQnt >= i; i++) {
                        let product = splitProduct.join(' ');
                        products.map(key => {
                            if (key['name'] == product) {
                                if (key['id'] == 1) {
                                    product1.push(key['id'])
                                }
                                if (key['id'] == 2) {
                                    product2.push(key['id'])
                                }
                                if (key['id'] == 3) {
                                    product3.push(key['id'])
                                }
                                if (key['id'] == 4) {
                                    product4.push(key['id'])
                                }
                                if (key['id'] == 5) {
                                    product5.push(key['id'])
                                }
                                if (key['id'] == 6) {
                                    product6.push(key['id'])
                                }
                                if (key['id'] == 7) {
                                    product7.push(key['id'])
                                }
                                if (key['id'] == 8) {
                                    product8.push(key['id'])
                                }
                                if (key['id'] == 9) {
                                    product9.push(key['id'])
                                }
                                if (key['id'] == 10) {
                                    product10.push(key['id'])
                                }
                            }
                        })
                    }

                }

                saleChanell.map(k => {
                    if (k['id'] == key['sale_channel']) {
                        if (k['name'] == 'APP') {
                            chApp.push(key['id'])
                        }
                        if (k['name'] == 'Automático') {
                            chAuto.push(key['id'])
                        }
                        if (k['name'] == 'Whatsapp') {
                            chWhats.push(key['id'])
                        }
                        if (k['name'] == 'Telefone') {
                            chPhone.push(key['id'])
                        }
                    }
                })

                if (key['vasilhame'] == "Ultragaz") {
                    vaUltra.push(key['id'])
                } else if (key['vasilhame'] == "Liquigaz") {
                    vaLiqui.push(key['id'])
                } else if (key['vasilhame'] == "Copagaz") {
                    vaCopa.push(key['id'])
                } else if (key['vasilhame'] == "Supergasbraz") {
                    vaSuper.push(key['id'])
                } else if (key['vasilhame'] == "Consigaz") {
                    vaConsi.push(key['id'])
                } else if (key['vasilhame'] == "Butano") {
                    vaButano.push(key['id'])
                } else if (key['vasilhame'] == "Servigás") {
                    vaServi.push(key['id'])
                } else {
                    vaOutros.push(key['id'])
                }

                if (key['driver_id'] == 1) {
                    driver1.push(key['driver_id'])
                }
                if (key['driver_id'] == 2) {
                    driver2.push(key['driver_id'])
                }
                if (key['driver_id'] == 3) {
                    driver3.push(key['driver_id'])
                }
                if (key['driver_id'] == 4) {
                    driver4.push(key['driver_id'])
                }
                if (key['driver_id'] == 5) {
                    driver5.push(key['driver_id'])
                }
                if (key['driver_id'] == 6) {
                    driver6.push(key['driver_id'])
                }
                if (key['driver_id'] == 7) {
                    driver7.push(key['driver_id'])
                }
                if (key['driver_id'] == 8) {
                    driver8.push(key['driver_id'])
                }
                if (key['driver_id'] == 9) {
                    driver9.push(key['driver_id'])
                }
                if (key['driver_id'] == 10) {
                    driver10.push(key['driver_id'])
                }

                if (key['payment_id'] == 1) {
                    paymentType1.push(key['id'])
                }
                if (key['payment_id'] == 2) {
                    paymentType2.push(key['id'])
                }
                if (key['payment_id'] == 3) {
                    paymentType3.push(key['id'])
                }
                if (key['payment_id'] == 4) {
                    paymentType4.push(key['id'])
                }
                if (key['payment_id'] == 5) {
                    paymentType5.push(key['id'])
                }
                if (key['payment_id'] == 6) {
                    paymentType6.push(key['id'])
                }
                if (key['payment_id'] == 7) {
                    paymentType7.push(key['id'])
                }
                if (key['payment_id'] == 8) {
                    paymentType8.push(key['id'])
                }
                if (key['payment_id'] == 9) {
                    paymentType9.push(key['id'])
                }
                if (key['payment_id'] == 10) {
                    paymentType10.push(key['id'])
                }

                driver.map(k => {
                    if (key['driver_id'] == k['id']) {
                        if (k['sector_id'] == 1) {
                            sector1.push(key['id'])
                        }
                        if (k['sector_id'] == 2) {
                            sector2.push(key['id'])
                        }
                        if (k['sector_id'] == 3) {
                            sector3.push(key['id'])
                        }
                        if (k['sector_id'] == 4) {
                            sector4.push(key['id'])
                        }
                        if (k['sector_id'] == 5) {
                            sector5.push(key['id'])
                        }
                        if (k['sector_id'] == 6) {
                            sector6.push(key['id'])
                        }
                        if (k['sector_id'] == 7) {
                            sector7.push(key['id'])
                        }
                        if (k['sector_id'] == 8) {
                            sector8.push(key['id'])
                        }
                        if (k['sector_id'] == 9) {
                            sector9.push(key['id'])
                        }
                        if (k['sector_id'] == 10) {
                            sector10.push(key['id'])
                        }
                    }
                })
            }

        })
    }, [dateFilter])

    const data = [
        {
            nome: "Mousepad",
            qtdEstoque: 4,
            qtdVendido: 10,
        },
        {
            nome: "Teclado",
            qtdEstoque: 8,
            qtdVendido: 12,
        },
        {
            nome: "Monitor",
            qtdEstoque: 2,
            qtdVendido: 14,
        },
        {
            nome: "Mouse",
            qtdEstoque: 15,
            qtdVendido: 32,
        }
    ];

    const handleExportPDF = async () => {
        const classeImpressao = new Impressao([
            { label: 'Automatico', value: resultChAuto },
            { label: 'APP', value: resultChApp },
            { label: 'Whatsapp', value: resultChWhats },
            { label: 'Telefone', value: resultChPhone },
        ], detailsDriver, detailsProduct, [
            { label: 'Ultragaz', value: resultVaUltra },
            { label: 'Liquigaz', value: resultVaLiqui },
            { label: 'Copagaz', value: resultVaCopa },
            { label: 'Supergasbraz', value: resultVaSuper },
            { label: 'Consigaz', value: resultVaConsi },
            { label: 'Butano', value: resultVaButano },
            { label: 'Servigás', value: resultVaServi },
            { label: 'Outros', value: resultVaOutros },
        ], [
            { label: 'Clientes Físicos', value: resultClientPhysic },
            { label: 'Clientes Jurídicos', value: resultClientBusiness },
        ], detailsCategory, detailsPayment, detailsSector);
        const documento = await classeImpressao.PreparaDocumento();
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }

    const handleExportXSL = () => {
        // Aqui criamos as rows da tabela
        const canais_de_vendas = [
            {
                Automatico: resultChAuto,
                APP: resultChApp,
                Whatsapp: resultChWhats,
                Telefone: resultChPhone
            }
        ];

        const vasilhames = [
            {
                Ultragaz: resultVaUltra,
                Liquigaz: resultVaLiqui,
                Copagaz: resultVaCopa,
                Supergasbraz: resultVaSuper,
                Consigaz: resultVaConsi,
                Butano: resultVaButano,
                Servigás: resultVaServi,
                Outros: resultVaOutros
            }
        ];

        const clients = [
            {
                'Clientes Físicos': resultClientPhysic,
                'Clientes Jurídicos': resultClientBusiness,
            }
        ];

        let arrayDriver = []
        let arrayVendas = []
        detailsDriver.map(key => {
            if (key['label'] !== '') {
                let driverValue = key['label']
                let value = key['value']
                arrayDriver = { ...arrayDriver, [driverValue]: value }
            }
        })

        detailsProduct.map(key => {
            if (key['label'] !== '') {
                let titleValue = key['label']
                let value = key['value']
                arrayVendas = { ...arrayVendas, [titleValue]: value }
            }
        })

        // cria workbook e worksheet
        const workbook = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(canais_de_vendas);
        var ws2 = XLSX.utils.json_to_sheet([arrayDriver]);
        var ws3 = XLSX.utils.json_to_sheet([arrayVendas]);
        var ws4 = XLSX.utils.json_to_sheet(vasilhames);
        var ws5 = XLSX.utils.json_to_sheet(clients);

        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workbook, ws, 'Canais de vendas', true);
        XLSX.utils.book_append_sheet(workbook, ws2, 'Venda por entregador', true);
        XLSX.utils.book_append_sheet(workbook, ws3, 'Venda de produtos', true);
        XLSX.utils.book_append_sheet(workbook, ws4, 'Vasilhames recebidos', true);
        XLSX.utils.book_append_sheet(workbook, ws5, 'Venda por tipo de cliente', true);

        XLSX.writeFile(workbook, "relatorio.xlsx", { compression: true });
    }

    return (
        <div className='orderedContainer'>
            <div className="titleAndButton">
                {/* <div>
                    <Typography variant="h5">Dashboard</Typography>
                </div> */}
            </div>
            <Card className='cardBoxDarshboard'>
                <Grid container rowSpacing={2} columnSpacing={2.75}>
                    <Grid item xs={12} sm={6} md={4} lg={3.2} className='divOrderedValues'>
                        <InputLabel className='titleOrderedValues'>VENDA DO MÊS</InputLabel>
                        <CurrencyInput
                            prefix="R$"
                            className='valueOrderedValues'
                            value={totalMonth}
                            decimalsLimit={2}
                            decimalScale={2}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3.2} className='divOrderedValues'>
                        <InputLabel className='titleOrderedValues'>VENDA DO DIA</InputLabel>
                        <CurrencyInput
                            prefix="R$"
                            className='valueOrderedValues'
                            value={totalToday}
                            decimalsLimit={2}
                            decimalScale={2}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2.5} className='filterDashboard'>
                        <FormControl sx={{ m: 1, minWidth: 220 }}>
                            <CustomProvider locale={pt_BR}>
                                <DateRangePicker
                                    placeholder="Selecione as datas"
                                    format="dd/MM/yyyy"
                                    size="lg"
                                    onChange={(e) => {
                                        if (e == null) {
                                            setDateFilter("")
                                        } else {
                                            setDateFilter(e)
                                        }
                                    }
                                    }
                                />
                            </CustomProvider>
                            <FormHelperText id="helperFilterDate">Filtre por data</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1} className='filterDashboard' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="primary" onClick={handleExportPDF} className="btnExport" >
                            <FaRegFilePdf style={{ fontSize: 22 }} />
                        </Button>
                        <FormHelperText id="helperFilterDate">Exportar em PDF</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={1} className='filterDashboard' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="primary" onClick={handleExportXSL} className="btnExport" >
                            <SiMicrosoftexcel style={{ fontSize: 22 }} />
                        </Button>
                        <FormHelperText id="helperFilterDate">Exportar em Excel</FormHelperText>
                    </Grid>

                    <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                        <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                            <Typography variant="span" className='titleSpanGrafic'>Canais de vendas</Typography>
                        </Grid>
                        <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                            <AppCurrentVisits
                                chartData={[
                                    { label: 'Automatico', value: resultChAuto },
                                    { label: 'APP', value: resultChApp },
                                    { label: 'Whatsapp', value: resultChWhats },
                                    { label: 'Telefone', value: resultChPhone },
                                ]}
                                chartColors={[
                                    "#053BB5",
                                    "#459903",
                                    "#6D6F72",
                                    "#7B0399",
                                    "#F97904",
                                    "#0805A4",
                                    "#9B9B9B",
                                    "#26CBC1",
                                ]}
                            />
                        </MainCard>
                    </Grid>

                    <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                        <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                            <Typography variant="span" className='titleSpanGrafic'>Venda por entregador</Typography>
                        </Grid>
                        <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                            <AppCurrentVisits
                                chartData={detailsDriver}
                                chartColors={[
                                    "#053BB5",
                                    "#459903",
                                    "#6D6F72",
                                    "#7B0399",
                                    "#F97904",
                                    "#0805A4",
                                    "#9B9B9B",
                                    "#26CBC1",
                                    "#7B0399",
                                    "#F97904",
                                ]}
                            />
                        </MainCard>
                    </Grid>

                    <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                        <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                            <Typography variant="span" className='titleSpanGrafic'>Venda de produtos</Typography>
                        </Grid>
                        <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                            <AppCurrentVisits
                                chartData={detailsProduct}
                                chartColors={[
                                    "#053BB5",
                                    "#459903",
                                    "#6D6F72",
                                    "#7B0399",
                                    "#F97904",
                                    "#0805A4",
                                    "#9B9B9B",
                                    "#26CBC1",
                                ]}
                            />
                        </MainCard>
                    </Grid>

                    <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                        <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                            <Typography variant="span" className='titleSpanGrafic'>Vasilhames recebidos</Typography>
                        </Grid>
                        <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                            <AppCurrentVisits
                                chartData={[
                                    { label: 'Ultragaz', value: resultVaUltra },
                                    { label: 'Liquigaz', value: resultVaLiqui },
                                    { label: 'Copagaz', value: resultVaCopa },
                                    { label: 'Supergasbraz', value: resultVaSuper },
                                    { label: 'Consigaz', value: resultVaConsi },
                                    { label: 'Butano', value: resultVaButano },
                                    { label: 'Servigás', value: resultVaServi },
                                    { label: 'Outros', value: resultVaOutros },
                                ]}
                                chartColors={[
                                    "#053BB5",
                                    "#459903",
                                    "#6D6F72",
                                    "#7B0399",
                                    "#F97904",
                                    "#0805A4",
                                    "#9B9B9B",
                                    "#26CBC1",
                                ]}
                            />
                        </MainCard>
                    </Grid>

                    <Grid container rowSpacing={2} columnSpacing={2.75} style={{ display: 'flex', width: '100%', marginLeft: 0, marginTop: 5 }}>
                        <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic3'>
                                <Typography variant="span" className='titleSpanGrafic'>Venda de cliente físico/juridico</Typography>
                            </Grid>
                            <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                                <AppCurrentVisits
                                    chartData={[
                                        { label: 'Clientes Físicos', value: resultClientPhysic },
                                        { label: 'Clientes Jurídicos', value: resultClientBusiness },
                                    ]}
                                    chartColors={[
                                        "#053BB5",
                                        "#459903",
                                        "#6D6F72",
                                        "#7B0399",
                                        "#F97904",
                                        "#0805A4",
                                        "#9B9B9B",
                                        "#26CBC1",
                                    ]}
                                />
                            </MainCard>
                        </Grid>

                        <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic3'>
                                <Typography variant="span" className='titleSpanGrafic'>Venda por tipo de comércio</Typography>
                            </Grid>
                            <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                                <AppCurrentVisits
                                    chartData={detailsCategory}
                                    chartColors={[
                                        "#053BB5",
                                        "#459903",
                                        "#6D6F72",
                                        "#7B0399",
                                        "#F97904",
                                        "#0805A4",
                                        "#9B9B9B",
                                        "#26CBC1",
                                    ]}
                                />
                            </MainCard>
                        </Grid>

                        <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic3'>
                                <Typography variant="span" className='titleSpanGrafic'>Tipo de pagamento por venda</Typography>
                            </Grid>
                            <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                                <AppCurrentVisits
                                    chartData={detailsPayment}
                                    chartColors={[
                                        "#053BB5",
                                        "#459903",
                                        "#6D6F72",
                                        "#7B0399",
                                        "#F97904",
                                        "#0805A4",
                                        "#9B9B9B",
                                        "#26CBC1",
                                    ]}
                                />
                            </MainCard>
                        </Grid>

                        <Grid item xs={12} md={5} lg={3} className='gridGrafic'>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic2'>
                                <Typography variant="span" className='titleSpanGrafic'>Venda por setor</Typography>
                            </Grid>
                            <MainCard sx={{ mt: 2 }} content={false} className="graficDiv">
                                <AppCurrentVisits
                                    chartData={detailsSector}
                                    chartColors={[
                                        "#053BB5",
                                        "#459903",
                                        "#6D6F72",
                                        "#7B0399",
                                        "#F97904",
                                        "#0805A4",
                                        "#9B9B9B",
                                        "#26CBC1",
                                    ]}
                                />
                            </MainCard>
                        </Grid>
                    </Grid>

                    {/* <div style={{ display: 'flex', width: '100%', justifyContent: 'end', marginTop: 10 }}>
                        <button onClick={(e) => {
                            setPlusGrafic(!plusGrafic)
                            if (plusGrafic) {
                                setplusGraficText('CARREGAR MAIS')
                            } else {
                                setplusGraficText('CARREGAR MENOS')
                            }

                        }} className='btnPlusGrafic'>{plusGraficText}</button>
                    </div> */}



                    {/* <div className='divTableAndLocalization'>
                        <div className='divTableLastOrders'>
                            <TableContainer component={Paper} style={{ width: '100%', height: '100%' }}>
                                <Table sx={{ width: '100%', height: '100%' }} aria-label="simple table">
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                            >
                                                <TableCell component="th" scope="row" style={{ padding: 10 }}>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: 10 }}>{row.address}</TableCell>
                                                <TableCell align="center" style={{ padding: 10 }}>
                                                    {row.cellphone.length === 10 ?
                                                        (
                                                            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9999-9999" value={row.cellphone} disabled />
                                                        ) : (
                                                            <InputMask style={{ backgroundColor: 'transparent', border: 'none', width: 127, cursor: 'auto' }} mask="(99) 9 9999-9999" value={row.cellphone} disabled />
                                                        )
                                                    }
                                                </TableCell>
                                                <TableCell align="center" style={{ padding: 10 }}>{row.date}</TableCell>
                                                <TableCell align="center" className='rowOrderedValue'>
                                                    <CurrencyInput
                                                        prefix="R$"
                                                        className='valueOrderedTableResume'
                                                        value={row.value}
                                                        decimalsLimit={2}
                                                        decimalScale={2}
                                                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                        disabled
                                                    /></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className='divTableLastOrders'>
                            {isLoaded ? (
                                <>
                                    <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic4'>
                                        <Typography variant="span" className='titleSpanGrafic'>ENTREGADORES</Typography>
                                    </Grid>
                                    <Card style={{ width: '100%', height: '50vh', borderRadius: '10px', padding: '10px' }}>
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            zoom={17}
                                        >
                                            {localization.map(key => {
                                                return (
                                                    driver.map(k => {
                                                        if (k['id'] == key['driver_id']) {
                                                            return (
                                                                <Marker position={{
                                                                    lat: Number(key['latitude']),
                                                                    lng: Number(key['longitude'])
                                                                }}
                                                                    options={{
                                                                        label: {
                                                                            text: k['name'],
                                                                            className: 'map-marker'
                                                                        }
                                                                    }}
                                                                    icon={icon}
                                                                />
                                                            )
                                                        }
                                                    })
                                                )

                                            })}
                                        </GoogleMap>
                                    </Card>
                                </ >
                            ) : <></>}
                        </div>

                    </div> */}

                </Grid >
            </Card>
        </div >
    );
}

export default Dashboard