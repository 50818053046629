import { useState, useEffect } from "react";
import React from 'react'
import './physic.css';
import { Card, Table, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableClientPhysic from '../TableClientPhysic'
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ClientPhysic = () => {

    const [text, setText] = useState();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        setText('Criar cliente')
    };


    const submitForm = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let phone  = data.celularPhysic.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        api.post("/CadastrarClienteFisico", {
            name: data.namePhysic,
            number: data.numeroPhysic,
            street: data.enderecoPhysic,
            city: data.cidadePhysic,
            state: data.estadoPhysic,
            neighborhood: data.bairroPhysic,
            complement: data.complementoPhysic,
            reference_point: data.referenciaPhysic,
            cpf: data.cpfPhysic,
            email: data.emailPhysic,
            phone: phone,
            birthdate: data.dataNascimentoPhysic
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo cliente'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header>
                        <Offcanvas.Title>Novo cliente físico</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="namePhysic">Nome *</InputLabel>
                                <input
                                    required
                                    id="namePhysic"
                                    name="namePhysic"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="cpfPhysic">CPF</InputLabel>
                                <InputMask className="inputCadastro" id="cpfPhysic" mask="999.999.999-**" name="cpfPhysic" />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="enderecoPhysic">Endereço *</InputLabel>
                                <input
                                    required
                                    name="enderecoPhysic"
                                    id="enderecoPhysic"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="numeroPhysic">Número *</InputLabel>
                                    <input
                                        required
                                        name="numeroPhysic"
                                        id="numeroPhysic"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="bairroPhysic">Bairro *</InputLabel>
                                    <input
                                        required
                                        name="bairroPhysic"
                                        id="bairroPhysic"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="cidadePhysic">Cidade *</InputLabel>
                                    <input
                                        required
                                        name="cidadePhysic"
                                        id="cidadePhysic"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="estadoPhysic">Estado *</InputLabel>
                                    <input
                                        required
                                        name="estadoPhysic"
                                        id="estadoPhysic"
                                        className="inputCadastro"
                                        
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="complementoPhysic">Complemento</InputLabel>
                                    <input
                                        name="complementoPhysic"
                                        id="complementoPhysic"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="referenciaPhysic">Ponto de referência</InputLabel>
                                    <input
                                        name="referenciaPhysic"
                                        id="referenciaPhysic"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="emailPhysic">E-mail</InputLabel>
                                <input
                                    name="emailPhysic"
                                    id="emailPhysic"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="celularPhysic">Celular *</InputLabel>
                                <InputMask required className='inputCadastro' id="celularPhysic" mask="(99) 99999-9999" name="celularPhysic" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="dataNascimentoPhysic">Data de Nascimento</InputLabel>
                                <InputMask className='inputCadastro' id="dataNascimentoPhysic" mask="99/99/9999" name="dataNascimentoPhysic" />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Cadastrar
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Clientes Físico</h5>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer"  style={{ width: '100%',  borderRadius: '10px', padding: '10px' }}>
                    <TableClientPhysic />
                </Card>
            </div >
        </>
    )
}

export default ClientPhysic