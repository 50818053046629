import * as React from 'react';
import api from "../../service/api";
import { Card, Button, Offcanvas } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import './localizationDriver.css'
import mapStyles from './mapStyle.json'
import icon from '../../image/truck.png'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

function MyComponent() {
    const [driver, setDriver] = React.useState([]);
    const [localization, setLocalization] = React.useState([]);
    const [cep, setCep] = React.useState();
    const [city, setCity] = React.useState();
    const [state, setState] = React.useState();
    const [lat, setLat] = React.useState(-23.38405532830399);
    const [lng, setLng] = React.useState(-46.71632873582917)

    let apiKey = '98ddedf7d9ad493bbe84c451e52a70bf';

    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    let user_id = '';
    if (currentUser !== undefined || currentUser !== null) {
        user_id = currentUser.id;
    }
    let db = 'clubeApi';
    if (currentUser !== undefined || currentUser !== null) {
        db = currentUser.db
    }

    const MINUTE_MS = 30000;

    React.useEffect(() => {
        const interval = setInterval(() => {
            api
                .get("/TodasLocalizacoes/" + currentUser.db)
                .then((response) => {
                    setLocalization(response.data);
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        api
            .get("/TodasLocalizacoes/" + currentUser.db)
            .then((response) => {
                setLocalization(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosMotoristas/" + currentUser.db)
            .then((response) => {
                setDriver(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    React.useEffect(() => {
        const interval = setInterval(() => {
            api
                .get("/TodosPerfilRevendas" + '/' + currentUser.db)
                .then((response) => {
                    setCep(response.data[0].cep.replace('-', ''));
                    setCity(response.data[0].city);
                    setState(response.data[0].state)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] === currentUser.db) {
                        setLat(parseFloat(key['lat']))
                        setLng(parseFloat(key['lng']))
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, [])

    // React.useEffect(() => {
    //     axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${cep}&key=${apiKey}&language=pt`)
    //         .then(response => {
    //             let results = response.data.results;
    //             if (results.length == 0) {
    //                 let cityState = city + ', ' + state
    //                 axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${cityState}&key=${apiKey}&language=pt`)
    //                     .then(response => {
    //                         if (response.data.results.length !== 0) {
    //                             setLat(response.data.results[0].geometry.lat)
    //                             setLng(response.data.results[0].geometry.lng)
    //                         }
    //                     })
    //             } else {
    //                 setLat(response.data.results[0].geometry.lat)
    //                 setLng(response.data.results[0].geometry.lng)
    //             }
    //         }).catch(err => {
    //             console.log(err)
    //         })
    // }, [cep])

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: lat,
        lng: lng
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyA0Tp3QicqpegAAEaBuSJFugiKyCM7LAUU"
    })

    return isLoaded ? (
        <div className='orderedContainer'>

            <div className="titleAndButton">
                <div>
                    <h5>Localização dos entregadores</h5>
                </div>
            </div>
            <Card style={{ width: '100%', height: '82vh', borderRadius: '10px', padding: '10px' }}>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={14}
                >
                    <Marker position={{
                        lat: Number(lat),
                        lng: Number(lng)
                    }}
                        options={{
                            label: {
                                text: 'Revenda',
                                className: 'map-marker'
                            }
                        }}
                    />
                    {localization.map(key => {
                        return (
                            driver.map(k => {
                                if (k['id'] == key['driver_id']) {
                                    return (
                                        <Marker position={{
                                            lat: Number(key['latitude']),
                                            lng: Number(key['longitude'])
                                        }}
                                            options={{
                                                label: {
                                                    text: k['name'],
                                                    className: 'map-marker'
                                                }
                                            }}
                                            icon={icon}
                                        />
                                    )
                                }
                            })
                        )

                    })}
                </GoogleMap>
            </Card>
        </div >
    ) : <></>
}

export default React.memo(MyComponent)