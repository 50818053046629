import React, { useState, useEffect } from 'react';
import './login.css'
import { Card } from 'react-bootstrap'
import logo from '../../image/clube-do-gas (grande).png'
import api from '../../service/api'
import token from "../../service/autentification";
import { useSelector, useDispatch } from 'react-redux';
import { userLogin } from '../../redux/user/actions';
import { autentificacao } from '../../redux/nuvemFiscal/actions';
import { configurationTheme } from '../../redux/configurationTheme/actions'
import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";
import InputLabel from '@mui/material/InputLabel';
import {
    Alert,
    Stack
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState('');
    const [logoLogin, setLogoLogin] = useState();
    const [modalRecoverPass, setModalRecoverPass] = useState(false)
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [showPassword1, setShowPassword1] = useState(false);
    // let logoLogin = '';
    const { currentTheme } = useSelector(rootReducer => rootReducer.configurationReducer);

    const dispatch = useDispatch();

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    function isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return true;
    }

    useEffect(() => {
        if (currentTheme !== null && !isEmpty(currentTheme)) {
            setLogoLogin(currentTheme.logoLogin)
        } else {
            api
                .get("/Configuracoes/clubeApi")
                .then((response) => {
                    response.data.map(key => {
                        setLogoLogin(key['logoLogin']);
                        dispatch(configurationTheme({ logoLogin: key['logoLogin'], logoMenu: key['logoMenu'], colorPrimary: key['colorPrimary'], colorSecundary: key['colorSecundary'] }));
                    })
                })

                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }
    }, []);

    const navigate = useNavigate();

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post("/LoginColaborador", {
                email: email, password: password
            });
            const printAddress = async () => {
                const a = await token;
                dispatch(autentificacao(a))
            };
            printAddress();
            dispatch(userLogin({ token: response.data.token, location: '', id: response.data.id, typeCollaborator: response.data.type, db: response.data.db_name, revenda: response.data.revenda }))
            // dispatch(userLogin({ token: response.data.token, id: response.data.id, typeCollaborator: response.data.type, db: 'clubeApi', revenda: response.data.revenda }))
            navigate('/', { replace: true });
        } catch (err) {
            if (!err.response) {
                setErrMsg('Sem resposta do servidor!');
            } else if (err.response.status === 400) {
                setErrMsg('E-mail ou senha inválido');
            }
        }

    }
    const onLoginClick = () => {
        window.FB.login(function (response) {
            if (response.authResponse) {
                const accessToken = response.authResponse.accessToken;
                //Use this token to call the debug_token API and get the shared WABA's ID
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '1573277440199297', // configuration ID obtained in the previous step goes here
            response_type: 'code',     // must be set to 'code' for System User access token
            override_default_response_type: true,
            extras: {
                setup: {}
            }
        });
    };

    useEffect(() => {
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: '716700277279735',
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v19.0'

            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const submitRecovery = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        api
            .post("/EsqueciASenha", {
                email: data.recoveryEmail,
                userType: 'colaborador'
            })
            .then((response) => {
                setModalRecoverPass(false)
                setAlertModal(true)
                setMessageAlert(response.data.message)
                if (response.data.message == 'E-email com nova senha enviado com sucesso') {
                    setTypeAlert('success')
                } else {
                    setTypeAlert('error')
                }

                setTimeout(function () {
                    setAlertModal(false)
                }, 3000);
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    function ModalPayment(props) {
        let valueDebt = '';
        return (
            <Modal
                show={modalRecoverPass}
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={e => setModalRecoverPass(false)}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Recuperar senha
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={e => setModalRecoverPass(false)}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <form className="input" enctype="multipart/form-data" onSubmit={submitRecovery}>
                        <div className="divCadastro">
                            <InputLabel htmlFor="categoryProduct">Informe o e-mail *</InputLabel>
                            <input
                                required
                                type='email'
                                id="categoryProduct"
                                name="recoveryEmail"
                                className="inputCadastro"
                            />
                        </div>
                        <div className="buttonLogin" style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="buttonSecundary" >
                                Recuperar
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        )
    }

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <ModalPayment />
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={typeAlert}>{messageAlert}</Alert>
            </Stack>
            <div className='loginContainer'>
                <Card style={{ width: '60rem', height: '45rem', background: "transparent" }}>
                    <div className='modalContainer'>
                        <div className='imgContainer'>
                            <img className='loginImg' src={logo} />
                        </div>
                        <div className='dateContainer'>
                            <div className="titleContainer">
                                <h1 className="titleDiv">Bem vindo ao seu painel de gestão</h1>
                            </div>
                            <div className="errorContainer">
                                <span>{errMsg}</span>
                            </div>
                            <form onSubmit={submitForm} className="input">
                                {/* <input
                                    className="inputLog"
                                    aria-label="email"
                                    placeholder="E-mail"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={e => setEmail(e.target.value)}
                                    name="email"
                                    value={email}
                                /> */}
                                <TextField
                                    required
                                    id="outlined-required"
                                    placeholder="E-mail"
                                    className='inputLog'
                                    onChange={e => setEmail(e.target.value)}
                                    style={{marginBottom: 30}}
                                />
                                {/* <input
                                    type='password'
                                    className="inputLog"
                                    placeholder="Senha"
                                    onChange={e => setPassword(e.target.value)}
                                    name="password"
                                    value={password}
                                /> */}
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    className='inputLog'
                                    placeholder="Senha"
                                    type={showPassword1 ? 'text' : 'password'}
                                    style={{ height: 55, width: '100%', backgroundColor: 'white' }}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword1}
                                                edge="end"
                                            >
                                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Senha"
                                    required
                                />
                                <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }} onClick={e => setModalRecoverPass(true)}>
                                    <span style={{ background: 'transparent', color: '#25368C', fontSize: 14, padding: 0 }} >
                                        Esqueci a senha
                                    </span>
                                </div>
                                <div className="buttonLogin">
                                    <button id="buttonLogin" >
                                        Acessar
                                    </button>
                                </div>
                            </form>
                            {/* <div>
                            <button onClick={onLoginClick}>Login with Facebook</button>
                        </div> */}
                        </div>
                    </div >
                </Card >
            </div >
        </>
    )
}

export default Login