import { useState, useEffect } from "react";
import React from 'react'
import './logStockProduct.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import TableCupons from '../TableLogStockProduct'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

const LogStockProduct = () => {
    const [show, setShow] = useState(false);
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [open, setOpen] = useState(false);

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Histórico dos estoques dos produtos</h5>
                    </div>
                    {/* <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div> */}
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableCupons />
                </Card>
            </div >
        </>
    )
}

export default LogStockProduct