import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableCreateProducts.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { TbEyeSearch } from "react-icons/tb";
import { CurrencyInput } from 'react-currency-mask';
import id from 'date-fns/locale/id/index';
import { FaDolly } from "react-icons/fa";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { SlLink } from "react-icons/sl";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Produtos',
  },
  {
    id: 'category_id',
    numeric: false,
    disablePadding: false,
    label: 'Categoria',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'promotional_value',
    numeric: false,
    disablePadding: false,
    label: 'Valor promocional',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Descrição',
  },
  {
    id: 'stock',
    numeric: false,
    disablePadding: false,
    label: 'Estoque',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Imagem',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [salaryModal, setSalaryModal] = React.useState(false);
  const [category, setCategory] = React.useState([])
  const [filter, setFilter] = React.useState(true)
  const [stockModal, setStockModal] = React.useState(false)
  const [stockLinkedModal, setStockLinkedModal] = React.useState(false)
  const [optionsProducts, setOptionsProducts] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [productStock, setProductStock] = React.useState([])
  const [disabledStock, setDisabledStock] = React.useState(false)
  const [nameProductVinculado, setNameProductVinculado] = React.useState('')
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

  let productId = [];

  const animatedComponents = makeAnimated();

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setCollaborators(response.data);
        setRows(response.data.map(key => ({
          id: key['id'], product: key['name'], category_id: key['category_id'], value: key['value'], promotional_value: key['promotional_value'], promocional_date_end: key['promocional_date_end'], description: key['description'], stock: key['stock'], stock_full: key['stock_full'], stock_empty: key['stock_empty'], stock_complete: key['stock_complete'], image: key['imagem'], order: key['order']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setProduct(response.data);
        setOptionsProducts(response.data.sort((a, b) => a.order - b.order).map(key => ({
          value: key['id'],
          label: key['name']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasCategoriasProdutos" + '/' + currentUser.db)
      .then((response) => {
        setCategory(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get(`/TodosEstoqueVinculado/${currentUser.db}`)
      .then(response => {
        setProductStock(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setSalaryModal(false)
    setStockModal(false)
    setStockLinkedModal(false)
  };

  const submitModalStockLinked = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let pId = [];
    var arraySubmit = []
    console.log(data.stockProductVinculado)
    if (productId.length > 0) {
      pId = productId.filter((este, i) => productId.indexOf(este) === i)
    } else {
      collaborators.map(key => {
        if (key['id'] == idModal) {
          productStock.map(k => {
            let list = k['linked_products'].split(',')

            list.map(p => {
              if (k['product_id'] == idModal) {
                optionsProducts.map(o => {
                  if (o['value'] == p) {
                    arraySubmit.push(o['value'])
                  }
                })
              }
            })
          })
        }
      })
      pId = arraySubmit
      if (data.stockProductVinculado == '') {
        pId = []
      }
    }

    let dados = {
      id: idModal,
      stock: data.stockProductStock,
      stock_full: data.stockProductStockFull,
      stock_empty: data.stockProductStockClean,
      stock_complete: data.stockProductStockComplete,
      db: currentUser.db
    }

    api.put("/AtualizarProduto", dados, {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {

    }).catch(err => {
      console.log(err)
    })

    api
      .get(`/EstoqueVinculado/${idModal}/${currentUser.db}`)
      .then(response => {
        let res = response.data.length;

        if (res == 0) {
          api.post("/CadastrarEstoqueVinculado", {
            product_id: idModal,
            linked_products: pId.toString(),
            stock: data.stockProductStock,
            stock_full: data.stockProductStockFull,
            stock_empty: data.stockProductStockClean,
            stock_complete: 0,
            db: currentUser.db
          }).then(response => {
            console.log(response.data)
          }).catch(err => {
            console.log(err)
          })
        } else if (res > 0) {
          if (Number(response.data[0].product_id) == Number(idModal)) {
            api.put("/AtualizarEstoqueVinculado", {
              id: idModal,
              product_id: idModal,
              linked_products: pId.toString(),
              stock: data.stockProductStock,
              stock_full: data.stockProductStockFull,
              stock_empty: data.stockProductStockClean,
              stock_complete: data.stockProductStockComplete,
              db: currentUser.db
            }).then(response => {
              console.log(response.data)
            }).catch(err => {
              console.log(err)
            })
          } else {
            api.post("/CadastrarEstoqueVinculado", {
              product_id: idModal,
              linked_products: pId.toString(),
              stock: data.stockProductStock,
              stock_full: data.stockProductStockFull,
              stock_empty: data.stockProductStockClean,
              stock_complete: 0,
              db: currentUser.db
            }).then(response => {
              console.log(response.data)
            }).catch(err => {
              console.log(err)
            })
          }

        }
      })
      .catch(err => {
        console.log(err)
      })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitModalStock = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    let dados = {
      id: idModal,
      stock: data.stockProductStock,
      stock_full: data.stockProductStockFull,
      stock_empty: data.stockProductStockClean,
      db: currentUser.db
    }

    api.put("/AtualizarProduto", dados)
      .then(response => {
        console.log(response)
      }).catch(err => {
        console.log(err)
      })

    api
      .get(`/EstoqueVinculado/${idModal}/${currentUser.db}`)
      .then(response => {
        let res = response.data.length;

        if (res == 0) {
          api.post("/CadastrarEstoqueVinculado", {
            product_id: idModal,
            stock: data.stockProductStock,
            stock_full: data.stockProductStockFull,
            stock_empty: data.stockProductStockClean,
            stock_complete: data.stockProductStockComplete,
            db: currentUser.db
          }).then(response => {
            console.log(response.data)
          }).catch(err => {
            console.log(err)
          })
        } else if (res > 0) {
          api.put("/AtualizarEstoqueVinculado", {
            id: idModal,
            product_id: idModal,
            stock: data.stockProductStock,
            stock_full: data.stockProductStockFull,
            stock_empty: data.stockProductStockClean,
            stock_complete: data.stockProductStockComplete,
            db: currentUser.db
          }).then(response => {
            console.log(response.data)
          }).catch(err => {
            console.log(err)
          })
        }
      })
      .catch(err => {
        console.log(err)
      })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);

  }

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    var arrayTest = [];
    let dados = '';
    let valuePromo = null

    let timestampPromocional = 0;

    var timeNow = new Date().valueOf();
    var dateFormatNow = new Date(timeNow);

    if (Number(data.promotionalDateProduct) !== 0) {
      timestampPromocional = dateFormatNow.setDate(dateFormatNow.getDate() + Number(data.promotionalDateProduct));
    }

    if (data.promotionalValueProduct !== '' || data.promotionalValueProduct !== 0) {
      valuePromo = data.promotionalValueProduct;
    }

    if (data.imageProduct.name !== '') {
      dados = {
        id: idModal,
        'image': data.imageProduct,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: valuePromo,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        imagem: "https://api.clubedorevendedordegas.com.br/files/products/" + data.imageProduct.name,
        whatsapp: data.whatsappProduct,
        order: Number(data.orderProduct),
        db: currentUser.db
      }
    } else {
      dados = {
        id: idModal,
        name: data.product,
        category_id: Number(data.categoryProduct),
        value: data.valueProduct,
        promotional_value: valuePromo,
        promotional_date_end: timestampPromocional,
        description: data.descriptionProduct,
        whatsapp: data.whatsappProduct,
        order: Number(data.orderProduct),
        db: currentUser.db
      }
    }

    api.put("/AtualizarProduto", dados, {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    })

    collaborators.map(key => {
      if (key['id'] !== idModal) {
        if (key['order'] >= Number(data.orderProduct)) {
          arrayTest.push({
            id: key['id'],
            order: Number(key['order']),
            db: currentUser.db
          })
        }
      }
    })

    for (var i = 0; i <= arrayTest.length; i++) {
      let product = arrayTest.sort((a, b) => a.order - b.order)[i];
      if (product !== undefined) {
        api.put("/AtualizarProduto", {
          id: product.id,
          order: Number(data.orderProduct) + i + 1,
          db: currentUser.db
        }, {
          'headers': {
            'Content-Type': 'multipart/form-data'
          }
        })
      }
    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  const handleCancelOrdered = async (e) => {
    var arrayTest = [];

    api.delete('/DeletarProduto/' + idModal + "/" + currentUser.db)

    collaborators.map(key => {
      if (key['id'] !== idModal) {
        arrayTest.push({
          id: key['id'],
          order: Number(key['order']),
          db: currentUser.db
        })
      }
    })

    for (var i = 0; i <= arrayTest.length; i++) {
      let product = arrayTest.sort((a, b) => a.order - b.order)[i];
      if (product !== undefined) {
        api.put("/AtualizarProduto", {
          id: product.id,
          order: i + 1,
          db: currentUser.db
        }, {
          'headers': {
            'Content-Type': 'multipart/form-data'
          }
        })
      }
    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Detalhes
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Produto</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Categoria</td>
                      <td>{key['category_id']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Valor</td>
                      <td>{<CurrencyInput
                        className="inputDisableValueProduct"
                        value={key['value']}
                        onChangeValue={(event, originalValue, maskedValue) => {
                          // console.log(event, originalValue, maskedValue);
                        }}
                      />}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Descrição</td>
                      <td>{key['description']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Estoque</td>
                      <td>{key['stock']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Imagem</td>
                      <td><img src={key['imagem']} className="imgTable" /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Aparecerá no whatsapp?</td>
                      <td>{key['whatsapp'] == '1' ? 'Sim' : 'Não'}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do produto</h4>
          </div>
          {collaborators.map(key => {
            let promocionalDate = 0;
            var timeNow = new Date().valueOf();
            var dateFormatNow = new Date(timeNow);
            var dateNow = dateFormatNow.getFullYear() +
              "-" + (dateFormatNow.getMonth() + 1) +
              "-" + dateFormatNow.getDate();

            if (key['id'] == idModal) {
              if (typeof key['promotional_value'] !== 'undefined') {
                if (key['promotional_value'] !== null) {
                  if (key['promotional_date_end'] !== '0') {
                    var timestamp = Number(key['promotional_date_end']);
                    var dateFormat = new Date(timestamp);

                    var datePromocional = dateFormat.getFullYear() +
                      "-" + (dateFormat.getMonth() + 1) +
                      "-" + dateFormat.getDate();

                    const diffInMs = new Date(datePromocional) - new Date(dateNow)
                    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

                    promocionalDate = parseInt(diffInDays);
                  }
                }
              }
              return (
                <form className="input" onSubmit={submitModalEdit} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Produto:</td>
                        <td>
                          <input
                            required
                            name="product"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Categoria:</td>
                        <td>
                          <Form.Select
                            name='categoryProduct'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione uma categoria</option>
                            {category.map(k => {
                              return (
                                <option value={k['id']} selected={key['category_id'] == k['id']}>{k['name']}</option>
                              )
                            })}
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor:</td>
                        <td>
                          <CurrencyInput
                            required
                            name="valueProduct"
                            className="inputCadastro"
                            defaultValue={key['value']}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Valor promocional:</td>
                        <td>
                          <CurrencyInput
                            name="promotionalValueProduct"
                            className="inputCadastro"
                            defaultValue={key['promotional_value']}
                            onChangeValue={(event, originalValue, maskedValue) => {
                              // console.log(event, originalValue, maskedValue);
                            }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Dias de promoção:</td>
                        <td>
                          <input
                            name="promotionalDateProduct"
                            className="inputCadastro"
                            defaultValue={promocionalDate}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Descrição:</td>
                        <td>
                          <input
                            required
                            name="descriptionProduct"
                            className="inputCadastro"
                            defaultValue={key['description']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Imagem:</td>
                        <td>
                          <img src={key['imagem']} className="imgTable" />
                          <input
                            type="file"
                            name="imageProduct"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Ordem:</td>
                        <td>
                          <input
                            required
                            name="orderProduct"
                            className="inputCadastro"
                            defaultValue={key['order']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Aparecerá no whatsapp:</td>
                        <td>
                          <Form.Select
                            name='whatsappProduct'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione uma resposta</option>
                            <option value='1' selected={key['whatsapp'] == '1'}>Sim</option>
                            <option value='0' selected={key['whatsapp'] == '0'}>Não</option>
                          </Form.Select>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  const productHandler = e => {
    var options = e;
    var name = [];

    if (options.length !== 'undefined' && options.length !== undefined) {
      for (var i = 0, l = options.length; i < l; i++) {
        name.push(options[i].label)
      }
    }

    for (var i = 0, l = name.length; i < l; i++) {
      product.map(key => {
        if (key['name'] == name[i]) {
          productId.push(key.id)
        }
      })
    }
  }

  function ModalStockLinked(props) {
    return (
      <Modal
        show={stockLinkedModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Estoque vinculado do produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do vinculo</h4>
            {disabledStock ? (
              <span style={{ fontSize: 16, color: 'red', fontWeight: 900 }}>{`Estoque desse produto está vinculado ao produto ${nameProductVinculado}. Para alterar o estoque, remova a vinculação dele com o ${nameProductVinculado}.`}</span>
            ) : ('')}
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              let stock = key['stock'];
              let stockFull = key['stock_full'] !== '' ? key['stock_full'] : 0;
              let stockClean = key['stock_empty'] !== '' ? key['stock_empty'] : 0;
              let stockComplete = key['stock_complete'] !== '' ? key['stock_complete'] : 0;
              let array = []

              productStock.map(k => {
                let list = k['linked_products'].split(',')

                if (k['product_id'] == idModal) {
                  stock = k['stock']
                  stockFull = k['stock_full']
                  stockClean = k['stock_empty']
                  stockComplete = k['stock_complete']
                }
                list.map(p => {
                  if (Number(p) == idModal) {
                    stock = k['stock']
                    stockFull = k['stock_full']
                    stockClean = k['stock_empty']
                    stockComplete = k['stock_complete']
                  }
                  if (k['product_id'] == idModal) {
                    optionsProducts.map(o => {
                      if (o['value'] == p) {
                        array.push(o)
                      }
                    })
                  }
                })
              })

              return (
                <form className="input" onSubmit={submitModalStockLinked} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Estoque geral:</td>
                        <td>
                          <input
                            required
                            name="stockProductStock"
                            className="inputCadastro"
                            defaultValue={stock}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque cheio:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockFull"
                            className="inputCadastro"
                            defaultValue={stockFull}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque vazio:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockClean"
                            className="inputCadastro"
                            defaultValue={stockClean}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="labelConfirm">Estoque vazilhame + liquido:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockComplete"
                            className="inputCadastro"
                            defaultValue={stockComplete}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td className="labelConfirm">Estoque vinculado:</td>
                        <td>
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            defaultValue={array}
                            options={optionsProducts.filter(item => item.value !== idModal)}
                            name='stockProductVinculado'
                            id='products'
                            onChange={productHandler}
                            isDisabled={disabledStock}
                          />
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalStock(props) {
    return (
      <Modal
        show={stockModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Estoque do produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do estoque</h4>
            {disabledStock ? (
              <span style={{ fontSize: 16, color: 'red', fontWeight: 900 }}>{`Estoque desse produto está vinculado ao produto ${nameProductVinculado}. Para alterar o estoque, remova a vinculação dele com o ${nameProductVinculado}.`}</span>
            ) : ('')}
          </div>
          {collaborators.map(key => {
            if (key['id'] == idModal) {
              let stock = key['stock'];
              let stockFull = key['stock_full'] !== '' ? key['stock_full'] : 0;
              let stockClean = key['stock_empty'] !== '' ? key['stock_empty'] : 0;
              let stockComplete = key['stock_complete'] !== '' ? key['stock_complete'] : 0;

              productStock.map(k => {
                let list = k['linked_products'].split(',')

                if (k['product_id'] == idModal) {
                  stock = k['stock']
                  stockFull = k['stock_full']
                  stockClean = k['stock_empty']
                  stockComplete = k['stock_complete']
                }
                list.map(p => {
                  if (Number(p) == idModal) {
                    stock = k['stock']
                    stockFull = k['stock_full']
                    stockClean = k['stock_empty']
                    stockComplete = k['stock_complete']
                  }

                })
              })

              return (
                <form className="input" onSubmit={submitModalStock} enctype="multipart/form-data">
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Estoque geral:</td>
                        <td>
                          <input
                            required
                            name="stockProductStock"
                            className="inputCadastro"
                            defaultValue={stock}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque cheio:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockFull"
                            className="inputCadastro"
                            defaultValue={stockFull}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estoque vazio:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockClean"
                            className="inputCadastro"
                            defaultValue={stockClean}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="labelConfirm">Estoque vazilhame + liquido:</td>
                        <td>
                          <input
                            required
                            name="stockProductStockComplete"
                            className="inputCadastro"
                            defaultValue={stockComplete}
                            disabled={disabledStock}
                          />
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar estoque
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir produto
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o produto selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }
  let promotionalValue = 'Sem valor promocional'
  var dateNow = new Date().getTime();

  const columns = [
    { title: "Produtos", field: "product" },
    { title: "Categoria", field: "category_id" },
    {
      title: "Valor", field: "value", render: (rowData) => {
        return (
          <CurrencyInput
            className="inputDisableValueProduct"
            defaultValue={rowData.value}
            onChangeValue={(event, originalValue, maskedValue) => {
              // console.log(event, originalValue, maskedValue);
            }}
            disabled
          />
        )
      }
    },
    {
      title: "Valor promocional", field: 'promotional_value', render: (rowData) => {
        return (
          <CurrencyInput
            className="inputDisableValueProduct"
            defaultValue={rowData.promotional_value}
            onChangeValue={(event, originalValue, maskedValue) => {
              // console.log(event, originalValue, maskedValue);
            }}
            disabled
          />
        )
      }
    },
    { title: "Descrição", field: 'description' },
    {
      title: "Estoque Geral", field: 'stock', render: (rowData) => {
        let stock = rowData.stock;

        productStock.map(k => {
          let list = k['linked_products'].split(',')
          if (k['product_id'] == rowData.id) {
            stock = k['stock']
          }
          list.map(p => {
            if (Number(p) == rowData.id) {
              stock = k['stock']
            }
          })
        })
        return (
          <span>{stock}</span>
        )
      }
    },
    {
      title: "Estoque Cheio", field: 'stock_full', render: (rowData) => {
        let stock = rowData.stock_full !== '' ? rowData.stock_full : 0;

        productStock.map(k => {
          let list = k['linked_products'].split(',')
          if (k['product_id'] == rowData.id) {
            stock = k['stock_full']

          }
          list.map(p => {
            if (Number(p) == rowData.id) {
              stock = k['stock_full']
            }
          })
        })
        return (
          <span>{stock}</span>
        )
      }
    },
    {
      title: "Estoque Vazio", field: 'stock_empty', render: (rowData) => {
        let stock = rowData.stock_empty !== '' ? rowData.stock_empty : 0;

        productStock.map(k => {
          let list = k['linked_products'].split(',')
          if (k['product_id'] == rowData.id) {
            stock = k['stock_empty']
          }
          list.map(p => {
            if (Number(p) == rowData.id) {
              stock = k['stock_empty']
            }
          })
        })
        return (
          <span>{stock}</span>
        )
      }
    },
    // {
    //   title: "Estoque Vazilhame + Liquido", field: 'stock_complete', render: (rowData) => {
    //     let stock = rowData.stock_complete !== '' ? rowData.stock_complete : 0;

    //     productStock.map(k => {
    //       let list = k['linked_products'].split(',')
    //       if (k['product_id'] == rowData.id) {
    //         stock = k['stock_complete']
    //       }
    //       list.map(p => {
    //         if (Number(p) == rowData.id) {
    //           stock = k['stock_complete']
    //         }
    //       })
    //     })
    //     return (
    //       <span>{stock}</span>
    //     )
    //   }
    // },
    { title: "Imagem", field: 'image', render: (rowData) => <img src={rowData.image} className="imgTable" /> }
  ]

  rows.forEach(item => {
    category.map(key => {
      if (key['id'] == item.category_id) {
        item.category_id = key['name']
      }
    })
  })

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <ModalStockLinked />
      <ModalStock />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows.sort((a, b) => a.order - b.order)}
            actions={[
              {
                icon: () => <TbEyeSearch className='perfilAction' />,
                tooltip: 'Detalhes',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <SlLink className='perfilAction' />,
                tooltip: 'Estoque Vinculado',
                onClick: (event, rowData) => {
                  setStockLinkedModal(true)
                  setIdModal(rowData.id)
                  setDisabledStock(false)
                  let name = ''
                  let product_id = ''
                  productStock.map(k => {
                    let list = k['linked_products'].split(',')
                    list.map(p => {
                      if (Number(p) == rowData.id) {
                        setDisabledStock(true)
                        product_id = k['product_id']
                      }
                    })
                  })

                  if (product_id !== '') {
                    product.map(key => {
                      if (key['id'] == product_id) {
                        name = key['name']
                      }
                    })
                  }

                  setNameProductVinculado(name)
                }
              },
              {
                icon: () => <FaDolly className='perfilAction' />,
                tooltip: 'Estoque',
                onClick: (event, rowData) => {
                  setStockModal(true)
                  setIdModal(rowData.id)
                  setDisabledStock(false)
                  let name = ''
                  let product_id = ''
                  productStock.map(k => {
                    let list = k['linked_products'].split(',')
                    list.map(p => {
                      if (Number(p) == rowData.id) {
                        setDisabledStock(true)
                        product_id = k['product_id']
                      }
                    })
                  })

                  if (product_id !== '') {
                    product.map(key => {
                      if (key['id'] == product_id) {
                        name = key['name']
                      }
                    })
                  }

                  setNameProductVinculado(name)

                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 20,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
