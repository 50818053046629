import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableClientBusiness.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineWhatsApp, AiOutlineMail, AiOutlineBell, AiOutlineShoppingCart } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { Checkbox } from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import 'rsuite/dist/rsuite.min.css'
import pt_BR from 'rsuite/locales/pt_BR'
import { CustomProvider, DateRangePicker } from 'rsuite';
import makeAnimated from 'react-select/animated';
import SelectMulti from 'react-select'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
    style={{ minWidth: 36 }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {

    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'dataUltimaCompra',
    numeric: false,
    disablePadding: false,
    label: 'Data da última compra',
  },
  {
    id: 'valorUltimaCompra',
    numeric: false,
    disablePadding: false,
    label: 'Valor da última compra',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

const headOrdered = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Pedido',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Data do pedido',
  },
  {
    id: 'saleChannel',
    numeric: false,
    disablePadding: false,
    label: 'Canal de venda',
  },
  {
    id: 'payment',
    numeric: false,
    disablePadding: false,
    label: 'Forma de pagamento',
  },
  {
    id: 'vasilhame',
    numeric: false,
    disablePadding: false,
    label: 'Vasilhame',
  },
  {
    id: 'obs',
    numeric: false,
    disablePadding: false,
    label: 'Observação do pedido',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([])
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [historyOrderModal, setHistoryOrderModal] = React.useState(false);
  const [ordered, setOrdered] = React.useState([]);
  const [payment, setPayment] = React.useState([]);
  const [saleChannel, setSaleChannel] = React.useState([]);
  const [notification, setNotification] = React.useState(false)
  const [whatsModal, setWhatsModal] = React.useState(false);
  const [filter, setFilter] = React.useState(true)
  const [filterDate1, setFilterDate1] = React.useState('all');
  const [filteredData, setFilteredData] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState('');

  const [orderedModal, setOrderedModal] = React.useState(false);
  const [pName, setPName] = React.useState();
  const [pValue, setPValue] = React.useState([]);
  const [newName, setNewName] = React.useState("");
  const [newNumber, setNewNumber] = React.useState("");
  const [newBairro, setNewBairro] = React.useState("");
  const [newAddress, setNewAddress] = React.useState("");
  const [physicSelected, setPhysicSelected] = React.useState(false);
  const [businessSelected, setBusinessSelected] = React.useState(false);
  const [product, setProduct] = React.useState([]);
  const [typeClient, setTypeClient] = React.useState();
  const [newId, setNewId] = React.useState("")
  const [clientValue, setClientValue] = React.useState();
  const [paymentValue, setPaymentValue] = React.useState();
  const [channelValue, setChannelValue] = React.useState();
  const [totalValue, setTotalValue] = React.useState();
  const [modalConfirmOrder, setModalConfirmOrder] = React.useState(false);
  const [driver, setDriver] = React.useState([]);
  const [driverSelected, setDriverSelected] = React.useState();
  const [descont, setDescont] = React.useState(0);
  const [obsPayment, setObsPayment] = React.useState();
  const [obsValue, setObsValue] = React.useState();
  const [optionsProducts, setOptionsProducts] = React.useState([]);
  const [saleChanell, setSaleChanell] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let productValue = '';
  let productName = [];
  let clientType = '';
  let typeSearch = '';
  let idSearch = '';
  const date = new Date()
  const today = date.toLocaleString("pt-BR")

  const animatedComponents = makeAnimated();


  let result = ''
  let valorResult = ''

  React.useEffect(() => {
    api
      .get("/TodosProdutos" + '/' + currentUser.db)
      .then((response) => {
        setProduct(response.data);
        setOptionsProducts(response.data.map(key => ({
          value: key['id'],
          label: key['name']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosMotoristas" + '/' + currentUser.db)
      .then((response) => setDriver(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data)
        setRows(response.data.map(key => ({
          id: key['id'], typeClient: "juridico", cliente: key['name'], endereco: key['street'] + ", " + key['number'] + " - " + key['neighborhood'], telefone: key['phone'], dataUltimaCompra: key['date_last_order']
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  let db = 'clubeApi';
  if (currentUser !== undefined || currentUser !== null) {
    db = currentUser.db
  }
  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        setOrdered(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasFormasPagamentos" + '/' + currentUser.db)
      .then((response) => {
        setPayment(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => setSaleChanell(response.data))
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setHistoryOrderModal(false)
    setNotification(false)
    setWhatsModal(false)
    setText("");
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    let phone = data.telefoneBusinessEdit.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    const dados = {
      id: idModal,
      commercial_id: Number(data.idComercioBusinessEdit),
      name: data.nameBusinessEdit,
      razao: data.razaoBusinessEdit,
      cnpj: data.cnpjBusinessEdit,
      street: data.enderecoBusinessEdit,
      number: data.numeroBusinessEdit,
      city: data.cidadeBusinessEdit,
      state: data.estadoBusinessEdit,
      neighborhood: data.bairroBusinessEdit,
      complement: data.complementoBusinessEdit,
      email: data.emailBusinessEdit,
      inscricao_estadual: data.socialBusinessEdit,
      inscricao_municipal: data.municipalBusinessEdit,
      phone: phone,
      birthdate: data.dataNascimentoBusinessEdit,
      obs: data.obsBusinessEdit
    }

    api.put("/AtualizarCadastroJuridico", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarClienteJuridico/' + idModal)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const productHandler = e => {
    var divProducSelected1 = document.getElementById('productSelect1')
    var divProducSelected2 = document.getElementById('productSelect2')
    var divProducSelected3 = document.getElementById('productSelect3')
    var labelProducSelected1 = document.getElementById('labelProductSelect1')
    var labelProducSelected2 = document.getElementById('labelProductSelect2')
    var labelProducSelected3 = document.getElementById('labelProductSelect3')
    var options = e;
    var value = [];
    var name = [];

    for (var i = 0, l = options.length; i < l; i++) {
      value.push(options[i].value);
      name.push(options[i].label)
      if (i == 0) {
        divProducSelected1.style.display = "block"
        divProducSelected2.style.display = "none"
        divProducSelected3.style.display = "none"
        labelProducSelected1.textContent = options[i].label + ":"
      }
      if (i == 1) {
        divProducSelected2.style.display = "block"
        divProducSelected3.style.display = "none"
        labelProducSelected2.textContent = options[i].label + ":"
      }
      if (i == 2) {
        divProducSelected3.style.display = "block"
        labelProducSelected3.textContent = options[i].label + ":"
      }
    }
    if (options.length == 0) {
      divProducSelected1.style.display = "none"
    }

    productValue = value;
    productName = name

    var total = [];
    let sumTotal = 0;
    var spanTotal = document.getElementById('productTotal');
    for (var i = 0, l = productValue.length; i < l; i++) {
      product.map(key => {
        if (key['id'] == productValue[i]) {
          total.push(key['value'])
        }
      })
    }
    for (let i = 0; i < total.length; i++) {
      sumTotal += total[i]
    }

    spanTotal.textContent = sumTotal
  }

  const paymentHandler = e => {
    var divPayment = document.getElementById("obsPayment");
    var inputPayment = document.getElementById("inputPayment");
    let paymentSelected = false

    payment.map(key => {
      if (key['id'] == e.target.value) {
        if (key['name'] == 'Dinheiro') {
          paymentSelected = true;
        }
      }
    })
    if (paymentSelected) {
      divPayment.setAttribute("style", 'display: block')
      // inputPayment.setAttribute('required', 'required')
    } else {
      divPayment.setAttribute("style", 'display: none')
      // inputPayment.removeAttribute('required', 'required')
    }
  }

  const descontoHandler = e => {
    var type = e.target.value;
    var divDesconto = document.getElementById('descontoContainer');

    if (type == 'sim') {
      divDesconto.style.display = "block"
    } else {
      divDesconto.style.display = "none"
    }

  }

  const trocoHandler = e => {
    var troco = e.target.value;
    var divTroco = document.getElementById('divTroco');
    if (troco == 'sim') {
      divTroco.style.display = 'block';
    } else {
      divTroco.style.display = 'none';
    }
  }

  const submitModalConfirm = e => {
    e.preventDefault();
    setOrderedModal(false)
    setPName('')
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    var inputProduct1 = document.getElementById("inputProductSelect1").value
    var inputProduct2 = document.getElementById("inputProductSelect2").value
    var inputProduct3 = document.getElementById("inputProductSelect3").value
    var ulClientPhysic = document.getElementById('ulClientPhysic');
    var ulClientBusiness = document.getElementById('ulClientBusiness');
    var selectQuant = [];
    let resultSelect = '';

    for (var i = 0, l = productName.length; i < l; i++) {
      if (i == 0) {
        selectQuant.push(inputProduct1 + "x" + " " + productName[i]);
      }
      if (i == 1) {
        selectQuant.push(inputProduct2 + "x" + " " + productName[i]);
      }
      if (i == 2) {
        selectQuant.push(inputProduct3 + "x" + " " + productName[i]);
      }
    }
    if (selectQuant.length == 1) {
      resultSelect = selectQuant.join(", ")
    } else {
      resultSelect = selectQuant.join(", ")
    }

    var total = [];
    let sum = 0;
    for (var i = 0, l = productValue.length; i < l; i++) {
      product.map(key => {
        if (key['id'] == productValue[i]) {
          let value = key['value'].split(',');
          let valueFormat = value[0] + '.' + value[1]
          total.push(Number(valueFormat))
        }
      })
    }
    for (let i = 0; i < total.length; i++) {
      if (i == 0) {
        sum += total[i] * Number(inputProduct1)
      }
      if (i == 1) {
        sum += total[i] * Number(inputProduct2)
      }
      if (i == 2) {
        sum += total[i] * Number(inputProduct3)
      }
    }

    setClientValue(idModal);
    setTypeClient('fisico')
    setPhysicSelected(true)
    setBusinessSelected(false)


    if (data.inputDesconto > 0) {
      sum = sum - data.inputDesconto
    }

    setTotalValue(sum);
    setPName(resultSelect);
    setPValue(productValue);
    setPaymentValue(data.payment);
    setChannelValue(data.channel);
    setModalConfirmOrder(true)
    setDriverSelected(data.driverSelect)
    setDescont(data.inputDesconto)
    setObsPayment(data.inputObsPayment)

    let trocoInt = ''
    var trocoSujo = data.obsTroco.split(',')
    if (trocoSujo.length > 1) {
      trocoInt = trocoSujo[0] + "." + trocoSujo[1]
    } else {
      trocoInt = trocoSujo;
    }
    var troco = Number(trocoInt) - Number(sum);
    let trocoValue = '';
    if (data.obsTroco !== "") {
      trocoValue = data.obs + " " + "Levar de troco R$ " + troco;
    } else {
      trocoValue = data.obs;
    }

    setObsValue(trocoValue)
  }

  const submitForm = (e) => {
    var product = String(pValue.join())
    var obs = '';
    if (obsPayment !== "") {
      obs = obsValue
    }
    let res = ''
    let idNew = clientValue;

    if (clientValue == undefined) {
      idNew = newId
    }

    clientBusiness.map(key => {
      if (key['id'] == idModal) {
        res = key['name'] + " / " + key['street'] + ", " + key['number'] + " - " + key['neighborhood'] + " - " + key['city'];
      }
    })

    let phoneDriver = ''
    driver.map(key => {
      if (key['id'] == driverSelected) {
        phoneDriver = key['phone']
      }
    })

    let message = '';
    if (obs == '') {
      message = "*Novo Pedido*" + "%0A%0A" + "*Cliente e Endereço:* " + res + "%0A" + "*Produtos:* " + pName + "%0A" + "*Valor:* R$ " + totalValue;
    } else {
      message = "*Novo Pedido*" + "%0A%0A" + "*Cliente e Endereço:* " + res + "%0A" + "*Produtos:* " + pName + "%0A" + "*Valor:* R$ " + totalValue + "%0A" + "*Observação:* " + obs;
    }


    let whatsPhone = phoneDriver.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    // window.open('https://web.whatsapp.com/send?phone=55' + phoneDriver + '&text=' + message, "minhaJanela", "height=1800,width=1800");

    api.post("/CadastrarPedido", {
      typeClient: 'juridico',
      driver_id: Number(driverSelected),
      product_id: pName,
      products: pName,
      client_id: idModal,
      payment_id: Number(paymentValue),
      sale_channel: Number(channelValue),
      order_date: today,
      obs: obs,
      total: totalValue,
      quick_client: res,
      status: "Entregador notificado",
      obsPayment: obsPayment,
      desconto: descont,
    }).then(response => {
      // console.log(response)
    }).catch(error => {
      // console.log(error)
    });

    setObsValue("")
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 5000);
  }

  function ModalOrdered(props) {
    var ulClientPhysic = document.getElementById('ulClientPhysic');
    var ulClientBusiness = document.getElementById('ulClientBusiness');
    var inputNamePhysic = document.getElementById('nameClientPhysic');
    var inputStreetPhysic = document.getElementById('streetClientPhysic');
    var inputNumPhysic = document.getElementById('numClientPhysic');
    var inputBairroPhysic = document.getElementById('bairroClientPhysic');
    var inputNameBusiness = document.getElementById('nameClientBusiness');
    var inputStreetBusiness = document.getElementById('streetClientBusiness');
    var inputNumBusiness = document.getElementById('numberClientBusiness');
    var inputBairroBusiness = document.getElementById('bairroClientBusiness');
    var imputLastOrderPhysic = document.getElementById('lastOrderedPhysic');
    var imputLastPaymentOrderPhysic = document.getElementById('lastPaymentOrderedPhysic');
    var imputLastProductOrderPhysic = document.getElementById('lastProductOrderedPhysic');
    var imputLastObsOrderPhysic = document.getElementById('lastObsOrderedPhysic');
    var imputLastOrderBusiness = document.getElementById('lastOrderedBusiness');
    var imputLastPaymentOrderBusiness = document.getElementById('lastPaymentOrderedBusiness');
    var imputLastProductOrderBusiness = document.getElementById('lastProductOrderedBusiness');
    var imputLastObsOrderBusiness = document.getElementById('lastObsOrderedBusiness');

    var lastOrderProduct = []
    var lastObsProduct = []

    return (
      <Modal
        show={orderedModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Novo pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <form className="input" onSubmit={submitModalConfirm}>
            <div style={{ display: "flex" }}>
              <div className="divOrderLeft">
                <label className="labelForm">Produtos</label>
                <SelectMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  options={optionsProducts}
                  name='product'
                  onChange={productHandler}
                  required
                />
                <div className="productSelect1" id="productSelect1" style={{ display: 'none' }}>
                  <div className="divProduct">
                    <label className="labelProductSelect1" id="labelProductSelect1"></label>
                    <input
                      type="number"
                      name="productSelect1"
                      id="inputProductSelect1"
                      className="inputCadastro"
                      defaultValue='1'
                    />
                  </div>
                </div>
                <div className="productSelect2" id="productSelect2" style={{ display: 'none' }}>
                  <div className="divProduct">
                    <label className="labelProductSelect2" id="labelProductSelect2">Tipo de cliente</label>
                    <input
                      type="number"
                      name="productSelect2"
                      id="inputProductSelect2"
                      className="inputCadastro"
                      defaultValue='1'
                    />
                  </div>
                </div>
                <div className="productSelect3" id="productSelect3" style={{ display: 'none' }}>
                  <div className="divProduct">
                    <label className="labelProductSelect3" id="labelProductSelect3"></label>
                    <input
                      name="productSelect3"
                      id="inputProductSelect3"
                      className="inputCadastro"
                      defaultValue='1'
                    />
                  </div>
                </div>
                <label className="labelForm">Forma de pagamento</label>
                <Form.Select
                  name='payment'
                  id='inputPayment'
                  onChange={paymentHandler}
                  required
                >
                  <option value=''>Selecione uma forma de pagamento</option>
                  {payment.map((key, value) => {
                    return <option value={key['id']}>{key['name']}</option>
                  })}
                </Form.Select>
                <div className="obsPayment" id='obsPayment' style={{ display: 'none' }}>
                  <div>
                    <label className="labelForm">Valor total: R$ <span className="productTotal" id="productTotal"></span></label>
                  </div>
                  <div>
                    <label className="labelForm">Vai precisar de troco?</label>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      className="radioClient"
                      required
                    >
                      <FormControlLabel value="sim" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Sim" />
                      <FormControlLabel value="nao" control={<Radio onClick={trocoHandler} />} name="radioTroco" label="Não" />
                    </RadioGroup>
                  </div>
                  <div className="divTroco" id="divTroco" style={{ display: 'none' }}>
                    <label className="labelForm" >Troco para</label>
                    <Form.Control
                      placeholder="Troco para"
                      aria-label="troco"
                      name="obsTroco"
                    />
                  </div>
                </div>
                <label className="labelForm">Desconto?</label>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  className="radioClient"
                  required
                >
                  <FormControlLabel value="sim" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Sim" />
                  <FormControlLabel value="nao" control={<Radio onClick={descontoHandler} />} name="radioDesconto" label="Não" />
                </RadioGroup>
                <div className="descontoContainer" id="descontoContainer" style={{ display: 'none' }}>
                  <label className="labelForm">Valor do desconto</label>
                  <input
                    name="inputDesconto"
                    className="inputCadastro"
                    defaultValue={0}
                  />
                </div>
                <label className="labelForm">Canal de venda</label>
                <Form.Select
                  name='channel'
                  required
                >
                  <option value=''>Selecione um canal de venda</option>
                  {saleChanell.map((key, value) => {
                    return <option value={key['id']}>{key['name']}</option>
                  })}
                </Form.Select>

                <label className="labelForm">Selecionar Entregador</label>
                <Form.Select
                  name='driverSelect'
                  id='driverSelect'
                  required
                >
                  <option value=''>Selecione um entregador</option>
                  {driver.map((key, value) => {
                    return <option value={key['id']}>{key['name']}</option>
                  })}
                </Form.Select>

                <label className="labelForm">Observação</label>
                <Form.Control
                  className="textAreaForm"
                  as="textarea"
                  style={{ height: '100px' }}
                  name='obs'
                />
              </div>
              <div className="divOrderRight">
                <div className="selectClientPhysic" id="selectClientPhysic">
                  <label className="labelForm">Cliente Físico</label>
                  <div className="newClientPhysic" id="newClientPhysic" >
                    {clientBusiness.map(key => {
                      if (key['id'] == idModal) {
                        return (
                          <>
                            <label className="labelForm" >Nome do cliente</label>
                            <input
                              name="nameClientPhysic"
                              className="inputCadastro"
                              id="nameClientPhysic"
                              value={key['name']}
                              disabled
                            />
                            <label className="labelForm" >Endereço</label>
                            <input
                              name="streetClientPhysic"
                              className="inputCadastro"
                              id="streetClientPhysic"
                              value={key['street']}
                              disabled
                            />
                            <label className="labelForm">Número</label>
                            <input
                              name="numClientPhysic"
                              className="inputCadastro"
                              id="numClientPhysic"
                              value={key['number']}
                              disabled
                            />
                            <label className="labelForm">Bairro</label>
                            <input
                              name="bairroClientPhysic"
                              className="inputCadastro"
                              id="bairroClientPhysic"
                              value={key['neighborhood']}
                              disabled
                            />
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: '5px', width: "100%" }}>
                                <label className="labelForm" style={{ color: 'red' }}>Última compra</label>
                                <input
                                  name="lastOrderedPhysic"
                                  className="inputCadastro"
                                  id="lastOrderedPhysic"
                                  disabled
                                  value={key['date_last_order']}
                                />
                              </div>
                              <div style={{ marginLeft: '5px', width: "100%" }}>
                                <label className="labelForm" style={{ color: 'red' }}>Último Pagamento</label>
                                <input
                                  name="lastPaymentOrderedPhysic"
                                  className="inputCadastro"
                                  id="lastPaymentOrderedPhysic"
                                  disabled
                                  value={key['value_last_order']}
                                />
                              </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: '5px', width: "100%" }}>
                                <label className="labelForm" style={{ color: 'red' }}>Último Produto</label>
                                {ordered.map((rank, i, row) => {
                                  if (rank['client_id'] == idModal) {
                                    lastOrderProduct.push(rank['products'])
                                    lastObsProduct.push(rank['obs'])
                                  }
                                })}
                                {lastOrderProduct.map((rank, i, arr) => {
                                  if (arr.length - 1 === i) {
                                    return (
                                      <input
                                        name="lastProductOrderedPhysic"
                                        className="inputCadastro"
                                        id="lastProductOrderedPhysic"
                                        disabled
                                        value={rank}
                                      />
                                    )
                                  }
                                })}

                              </div>
                              <div style={{ marginLeft: '5px', width: "100%" }}>
                                <label className="labelForm" style={{ color: 'red' }}>Última Observação do pedido</label>
                                {lastObsProduct.map((rank, i, arr) => {
                                  if (arr.length - 1 === i) {
                                    return (
                                      <input
                                        name="lastObsOrderedPhysic"
                                        className="inputCadastro"
                                        id="lastObsOrderedPhysic"
                                        disabled
                                        value={rank}
                                      />
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}

                  </div>
                </div>
              </div>
            </div>
            <div className="buttonLogin">
              <button className="buttonSecundary" >
                Confirmar Pedido
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalConfirm(props) {
    if (typeof totalValue !== 'undefined') {
      var totalString = totalValue.toString();
      var value = totalString.split('.');
      var valueReal = value[0] + ',' + value[1];
    }
    return (
      <Modal
        show={modalConfirmOrder}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirme o pedido
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Pedido</h4>
          <Table striped bordered hover >
            <tbody>
              <tr>
                <td className="labelConfirm">Produtos</td>
                <td>{pName}</td>

              </tr>
              <tr >
                <td className="labelConfirm">Cliente</td>
                {newName == '' ? (clientBusiness.map((k, v) => {
                  if (k['id'] == clientValue) {
                    return <td>{k['name']}</td>
                  }
                })) : (<td>{newName}</td>)}
              </tr>
              <tr >
                <td className="labelConfirm">Endereço</td>
                {newAddress == '' ? (
                  clientBusiness.map((k, v) => {
                    if (k['id'] == clientValue) {
                      return <td>{k['street'] + ", " + k['number']}</td>
                    }
                  })) : (<td>{newAddress + ", " + newNumber}</td>)}
              </tr>
              <tr>
                <td className="labelConfirm">Entregador</td>
                {driver.map((k, v) => {
                  if (k['id'] == driverSelected) {
                    return <td>{k['name']}</td>
                  }
                })}
              </tr>
              <tr>
                <td className="labelConfirm">Forma de pagamento</td>
                {payment.map((k, v) => {
                  if (k['id'] == paymentValue) {
                    return <td>{k['name']}</td>
                  }
                })}
              </tr>
              <tr>
                <td className="labelConfirm">Canal de venda</td>
                {saleChanell.map((k, v) => {
                  if (k['id'] == channelValue) {
                    return <td>{k['name']}</td>
                  }
                })}
              </tr>
              <tr>
                <td className="labelConfirm">Valor de desconto</td>
                <td>{"R$ " + descont}</td>
              </tr>
              <tr>
                <td className="labelConfirm">Observação</td>
                <td>{obsValue}</td>
              </tr>
              <tr>
                <td className="labelConfirmTotal">Total</td>
                <td >R$ {valueReal}</td>
              </tr>
            </tbody>
          </Table>
          <div className="buttonLogin">
            <button className="buttonSecundary" onClick={submitForm}>
              Confirmar Pedido
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cliente</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cliente</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">CPF</td>
                      <td>
                        <InputMask className='inputMaskPainel' mask="999.999.999-***" defaultValue={key['cpf']} disabled />
                      </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Endereço</td>
                      <td>{key['street']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Número</td>
                      <td>{key['number']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Bairro</td>
                      <td>{key['neighborhood']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Complemento</td>
                      <td>{key['complement']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Ponto de referência</td>
                      <td>{key['reference_point']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Cidade</td>
                      <td>{key['city']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Estado</td>
                      <td>{key['state']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail</td>
                      <td>{key['email']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Celular</td>
                      <td> <InputMask className='inputMaskPainel' mask="(99) 99999-9999" defaultValue={key['phone']} disabled /></td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Data de nascimento</td>
                      <td><InputMask className='inputMaskPainel' mask="99/99/9999" defaultValue={key['birthdate']} disabled /></td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do cliente</h4>
          </div>
          {clientBusiness.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">ID do comércio:</td>
                        <td>
                          <input
                            name="idComercioBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['commercial_id']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Nome do cliente:</td>
                        <td>
                          <input
                            required
                            name="nameBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Razão Social:</td>
                        <td>
                          <input
                            name="razaoBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['razao']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">CNPJ:</td>
                        <td>
                          <InputMask className='inputCadastro' name="cnpjBusinessEdit" mask="999.999.999-**" defaultValue={key['cnpj']} />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Inscrição Social:</td>
                        <td>
                          <input
                            name="socialBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['inscricao_estadual']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Inscrição Municipal:</td>
                        <td>
                          <input
                            name="municipalBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['inscricao_municipal']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Endereço</td>
                        <td>
                          <input
                            required
                            name="enderecoBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['street']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Número</td>
                        <td>
                          <input
                            required
                            name="numeroBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['number']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Bairro</td>
                        <td>
                          <input
                            required
                            name="bairroBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['neighborhood']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Complemento</td>
                        <td>
                          <input
                            name="complementoBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['complement']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Ponto de referência</td>
                        <td>
                          <input
                            name="referenciaBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['reference_point']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Cidade</td>
                        <td>
                          <input
                            name="cidadeBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['city']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Estado</td>
                        <td>
                          <input
                            name="estadoBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['state']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail</td>
                        <td>
                          <input
                            name="emailBusinessEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Telefone</td>
                        <td> <InputMask className='inputCadastro' name="telefoneBusinessEdit" mask="(99) 9999-9999" defaultValue={key['phone']} /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Data de nascimento</td>
                        <td><InputMask className='inputCadastro' name="dataNascimentoBusinessEdit" mask="99/99/9999" defaultValue={key['birthdate']} /></td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Observação</td>
                        <td>
                          <Form.Control
                            className="textAreaForm"
                            as="textarea"
                            style={{ height: '100px' }}
                            name='obsBusinessEdit'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o cliente selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  function ModalHistoryOrder(props) {

    var rowDriver = [];

    ordered.map(key => {
      if (key['client_id'] == idModal) {
        if (key['status'] == 'Finalizado') {
          rowDriver.push({ product: key['products'], desconto: key['desconto'], date_delivery: key['delivery_date'], vasilhame: key['vasilhame'], total: key['total'], payment_id: key['payment_id'], sale_channel: key['sale_channel'], obs: key['obs'] })
        }
      }
    }
    )

    function EnhancedTableHeadDriver(props) {
      const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
      const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };

      return (
        <TableHead>
          <TableRow>
            {headOrdered.map((headDriver) => (
              <TableCell
                key={headDriver.id}
                align={headDriver.numeric ? 'right' : 'left'}
                padding={headDriver.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headDriver.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headDriver.id}
                  direction={orderBy === headDriver.id ? order : 'asc'}
                  onClick={createSortHandler(headDriver.id)}
                  className="colunmLabel"
                >
                  {headDriver.label}
                  {orderBy === headDriver.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }

    EnhancedTableHeadDriver.propTypes = {
      numSelected: PropTypes.number.isRequired,
      onRequestSort: PropTypes.func.isRequired,
      onSelectAllClick: PropTypes.func.isRequired,
      order: PropTypes.oneOf(['asc', 'desc']).isRequired,
      orderBy: PropTypes.string.isRequired,
      rowCount: PropTypes.number.isRequired,
    };

    const handleRequestSortDriver = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClickDriver = (event) => {
      if (event.target.checked) {
        const newSelecteds = rows.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleChangePageDriver = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPageDriver = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const emptyRowsDriver =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowDriver.length) : 0;
    let descontoDriver = 0
    let totalDriver = 0
    let total = 0
    let desconto = 'Não teve desconto'
    return (
      <Modal
        show={historyOrderModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Histórico de compra
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfileHistory' style={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
            <h4>Histórico</h4>
            <Button variant="primary" className="me-2 button" style={{ color: 'white' }} onClick={() => {
              setHistoryOrderModal(false)
              setOrderedModal(true)
            }}>
              Novo pedido
            </Button>
          </div>
          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHeadDriver
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClickDriver}
                    onRequestSort={handleRequestSortDriver}
                    rowCount={rowDriver.length}
                  />
                  <TableBody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                     rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(rowDriver, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              align="left"
                              className="clientTable"
                            >
                              {
                                row['product']
                              }
                            </TableCell>

                            <TableCell align="left">
                              {
                                "R$ " + row['total']
                              }
                            </TableCell>

                            <TableCell align="left">
                              {
                                row['date_delivery']
                              }
                            </TableCell>

                            <TableCell align="left">
                              {
                                saleChannel.map(sale => {
                                  if (sale['id'] == row['sale_channel']) {
                                    return sale['name']
                                  }
                                })

                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                payment.map(pay => {
                                  if (pay['id'] == row['payment_id']) {
                                    return pay['name']
                                  }
                                })
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                row['vasilhame']
                              }
                            </TableCell>
                            <TableCell align="left">
                              {
                                row['obs']
                              }
                            </TableCell>

                          </TableRow>
                        );
                      })}
                    {emptyRowsDriver > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRowsDriver,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={rowDriver.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePageDriver}
              // onRowsPerPageChange={handleChangeRowsPerPageDriver}
              />
            </Paper>
          </Box>
        </Modal.Body>
      </Modal >
    )
  }

  const submitNotification = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageNotification
    let player_id = ''

    clientBusiness.map(key => {
      if (key['id'] == idModal) {
        player_id = key['player_id']
      }
    })

    api.post("/Notificar", {
      app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
      contents: {
        en: message
      },
      include_player_ids: [player_id]
    }).then(response => {
      // console.log(response)
    }).catch(err => {
      console.log(err)
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalNotification(props) {
    return (
      <Modal
        show={notification}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Notificação
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a notificação para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitNotification}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageNotification'
              id='mesageNotification'
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const submitMessageWhats = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageWhats
    let whats = ''

    clientBusiness.map(key => {
      if (key['id'] == idModal) {
        whats = key['phone']
      }
    })

    let whatsPhone = whats.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    // window.open('https://wa.me/send?phone=55' + whatsPhone + '&text=' + message, "minhaJanela", "height=1800,width=1800");

    const INSTANCE_ID = '';
    const INSTANCE_TOKEN = '';
    axios
      .post('https://api.gzappy.com/v1/message/send-message',
        {
          instance_id: INSTANCE_ID,
          instance_token: INSTANCE_TOKEN,
          message: [message],
          phone: "55" + whatsPhone
        }, {
        headers: {
          'Content-Type': 'application/json',
          'user_token_id': '3dbc680a-5a22-4e75-86b0-2db57f60a949'
        }
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err)
      })

    setWhatsModal(false)
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalWhats(props) {
    return (
      <Modal
        show={whatsModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Mensagem whatsapp
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a mensagem para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitMessageWhats}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageWhats'
              id='mesageWhats'
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "ID", field: "id" },
    { title: "Cliente", field: "cliente" },
    { title: "Endereço", field: 'endereco' },
    {
      title: "Telefone", field: 'telefone', render: (rowData) => {
        let length = 11;
        if (rowData.telefone !== null) {
          length = rowData.telefone.length
        }
        return (
          <InputMask className='inputMaskPainel' mask={length == 10 ? "(99) 9999-9999" : length == 11 ? "(99) 9 9999-9999" : ""} defaultValue={rowData.telefone} />
        )
      }
    },
    { title: "Data da última compra", field: 'dataUltimaCompra' },
    { title: "Dias sem comprar", field: 'diasDaUltimaCompra' }
  ]
  const handleChange = () => {
    setFilter(!filter)
  }

  rows.forEach(item => {
    if (item.dataUltimaCompra == null) {
      item.dataUltimaCompra = 'Ainda não fez pedido';
    }
    rows.map(key => {
      if (key['id'] == item.id) {
        if (key['date_last_order'] !== undefined) {
          item.dataUltimaCompra = key['date_last_order']
          let dateLastOrder = key['date_last_order'].split(' ');
          let splitDate = dateLastOrder[0].split('/');
          let formatDateLastOrder = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
          let now = new Date(); // Data de hoje
          let past = new Date(formatDateLastOrder); // Outra data no passado
          let diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
          let days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
          item.diasDaUltimaCompra = days + ' dias sem comprar';
        }
      }
    })
  })

  React.useEffect(() => {
    if (dateFilter == "" && filterDate1 == 'all') {
      setFilteredData(rows)
    }

  })

  React.useEffect(() => {
    let dateNow = new Date();
    var filter = [];
    // let dateArray = dateFilter.split("/");
    let dateStart = new Date(dateFilter[0]).getTime()
    let dateEnd = new Date(dateFilter[1]).getTime()
    if (dateFilter) {
      rows.filter(f => {
        if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
          if (f.dataUltimaCompra.length == 19) {
            let dataCompleteString = f.dataUltimaCompra.split(" ");
            let dataString = dataCompleteString[0].split("/")
            let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
            f.dataUltimaCompra = new Date(dataFormated).getTime();
          }
          if (dateStart == dateEnd) {
            let dateStartFormat = new Date(dateStart).toLocaleString()
            let dateEndFormat = new Date(dateEnd).toLocaleString()
            let dateStartSplit = dateStartFormat.split(" ");
            let dateEndSplit = dateEndFormat.split(" ");
            let dateStartSplited = dateStartSplit[0].split("/");
            let dateEndSplited = dateEndSplit[0].split("/");
            let dateStartNew = new Date(dateStartSplited[2] + "-" + dateStartSplited[1] + "-" + dateStartSplited[0] + " " + "00:00:01").getTime()
            let dateEndNew = new Date(dateEndSplited[2] + "-" + dateEndSplited[1] + "-" + dateEndSplited[0] + " " + "23:59:59").getTime()

            dateStart = dateStartNew
            dateEnd = dateEndNew
          }
          if (dateStart <= f.dataUltimaCompra && dateEnd >= f.dataUltimaCompra) {

            if (filterDate1 == 'semana') {
              dateNow.setDate(dateNow.getDate() - 7);
              let dateFormat = dateNow.getTime();
              if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
                if (f.dataUltimaCompra.length >= 19) {
                  let dataCompleteString = f.dataUltimaCompra.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.dataUltimaCompra = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.dataUltimaCompra) {

                  filter.push(f);

                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'mes') {
              dateNow.setDate(dateNow.getDate() - 31);
              let dateFormat = dateNow.getTime();
              if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
                if (f.dataUltimaCompra.length >= 19) {
                  let dataCompleteString = f.dataUltimaCompra.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.dataUltimaCompra = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.dataUltimaCompra) {

                  filter.push(f);

                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'trimestre') {
              dateNow.setDate(dateNow.getDate() - 90);
              let dateFormat = dateNow.getTime();
              if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
                if (f.dataUltimaCompra.length >= 19) {
                  let dataCompleteString = f.dataUltimaCompra.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.dataUltimaCompra = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.dataUltimaCompra) {

                  filter.push(f);

                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'semestre') {
              dateNow.setDate(dateNow.getDate() - 365);
              let dateFormat = dateNow.getTime();
              if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
                if (f.dataUltimaCompra.length >= 19) {
                  let dataCompleteString = f.dataUltimaCompra.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.dataUltimaCompra = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.dataUltimaCompra) {

                  filter.push(f);

                }
              }
              setFilteredData(filter)
            } else if (filterDate1 == 'ano') {
              dateNow.setDate(dateNow.getDate() - 365);
              let dateFormat = dateNow.getTime();
              if (f.dataUltimaCompra !== 'Ainda não fez pedido') {
                if (f.dataUltimaCompra.length >= 19) {
                  let dataCompleteString = f.dataUltimaCompra.split(" ");
                  let dataString = dataCompleteString[0].split("/")
                  let dataFormated = dataString[2] + "-" + dataString[1] + "-" + dataString[0] + " " + dataCompleteString[1]
                  f.dataUltimaCompra = new Date(dataFormated).getTime();
                }
                if (dateFormat < f.dataUltimaCompra) {

                  filter.push(f);

                }
              }
              setFilteredData(filter)
            } else {

              filter.push(f)

            }
          }
        }
      })
      setFilteredData(filter)
      document.getElementById("helperFilterDate").setAttribute('style', 'color: rgba(0, 0, 0, 0.6)')
    }

    if (dateFilter == "") {
      if (filterDate1 == 'semana') {
        dateNow.setDate(dateNow.getDate() - 7);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra !== undefined) {
            let splitDias = f.diasDaUltimaCompra.split(' dias sem comprar')
            let dias = parseInt(splitDias[0]);
            if (dias >= 7) {
              filter.push(f);
            }
          }

        })
        setFilteredData(filter)
      } else if (filterDate1 == 'mes') {
        dateNow.setDate(dateNow.getDate() - 31);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra !== undefined) {
            let splitDias = f.diasDaUltimaCompra.split(' dias sem comprar')
            let dias = parseInt(splitDias[0]);
            if (dias >= 30) {
              filter.push(f);
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'trimestre') {
        dateNow.setDate(dateNow.getDate() - 90);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra !== undefined) {
            let splitDias = f.diasDaUltimaCompra.split(' dias sem comprar')
            let dias = parseInt(splitDias[0]);
            if (dias >= 90) {
              filter.push(f);
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'semestre') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra !== undefined) {
            let splitDias = f.diasDaUltimaCompra.split(' dias sem comprar')
            let dias = parseInt(splitDias[0]);
            if (dias >= 180) {
              filter.push(f);
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'ano') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra !== undefined) {
            let splitDias = f.diasDaUltimaCompra.split(' dias sem comprar')
            let dias = parseInt(splitDias[0]);
            if (dias >= 365) {
              filter.push(f);
            }
          }
        })
        setFilteredData(filter)
      } else if (filterDate1 == 'nunca') {
        dateNow.setDate(dateNow.getDate() - 365);
        let dateFormat = dateNow.getTime();
        rows.filter(f => {
          if (f.diasDaUltimaCompra == undefined) {

            filter.push(f);

          }
        })
        setFilteredData(filter)
      } else {
        // console.log(rows);
      }
    }

  }, [filterDate1, dateFilter])

  React.useEffect(() => {
    document.body.style.removeProperty('padding-right')
    document.body.style.removeProperty('overflow')
  }, [painelModal, editModal, modalCancel, historyOrderModal, notification, whatsModal])

  return (
    <>
      <Loading />
      <ModalOrdered />
      <ModalConfirm />
      <ModalHistoryOrder />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <ModalNotification />
      <ModalWhats />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Clientes Jurídicos"
            columns={columns}
            data={filteredData}
            actions={[
              {
                icon: (props) => <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    style={{ borderRadius: 20, width: 20, minWidth: 36, background: 'var(--primaryColor)', color: 'var(--secundaryColor)' }}
                  >
                    <MoreHorizIcon />
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                  >
                    <MenuItem >
                      <AiOutlineUser style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Perfil
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineEdit style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Editar
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineShoppingCart style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Compras
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineWhatsApp style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Whatsapp
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineBell style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Notificar
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineMail className='perfilActionDisable' style={{ marginRight: 5 }} />
                      E-mail
                    </MenuItem>
                    <MenuItem >
                      <FiPhoneCall className='perfilActionDisable' style={{ marginRight: 5 }} />
                      Ligar
                    </MenuItem>
                    <MenuItem >
                      <AiOutlineDelete style={{ marginRight: 5, color: "#3e5cb8", fontSize: 18 }} />
                      Deletar
                    </MenuItem>
                  </StyledMenu>
                </div>,
                onClick: (event, rowData) => {
                  var buttonPress = event.target.innerText
                  if (buttonPress == undefined || buttonPress == '') {
                    setIdModal(rowData.id)
                  }
                  if (buttonPress == 'Perfil') {
                    setPainelModal(true)
                    setAnchorEl(false)
                  }
                  if (buttonPress == 'Editar') {
                    setEditModal(true)
                    setAnchorEl(false)
                  }
                  if (buttonPress == 'Compras') {
                    setHistoryOrderModal(true)
                    setAnchorEl(false)
                  }
                  if (buttonPress == 'Whatsapp') {
                    setWhatsModal(true)
                    setAnchorEl(false)
                  }
                  if (buttonPress == 'Notificar') {
                    setNotification(true)
                    setAnchorEl(false)
                  }
                  if (buttonPress == 'E-mail') {

                  }
                  if (buttonPress == 'Ligar') {

                  }
                  if (buttonPress == 'Deletar') {
                    setModalCancel(true)
                    setAnchorEl(false)
                  }
                }
              },
              {
                icon: () => <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <CustomProvider locale={pt_BR}>
                    <DateRangePicker
                      placeholder="Selecione as datas"
                      format="dd/MM/yyyy"
                      size="lg"
                      onChange={(e) => {
                        if (e == null) {
                          setDateFilter("")
                        } else {
                          setDateFilter(e)
                        }
                      }
                      }
                    />
                  </CustomProvider>
                  <FormHelperText id="helperFilterDate">Filtre por data</FormHelperText>
                </FormControl>,
                isFreeAction: true
              },
              {
                icon: () =>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      labelId="filtro-periudo"
                      id="filtro-periudo"
                      style={{ width: 200 }}
                      value={filterDate1}
                      onChange={(e) => setFilterDate1(e.target.value)}
                    >
                      <MenuItem value={'all'}>Todos</MenuItem>
                      <MenuItem value={'semana'}>1 semana</MenuItem>
                      <MenuItem value={'mes'}>1 mês</MenuItem>
                      <MenuItem value={'trimestre'}>1 trimestre</MenuItem>
                      <MenuItem value={'semestre'}>1 semestre</MenuItem>
                      <MenuItem value={'ano'}>1 ano</MenuItem>
                      <MenuItem value={'nunca'}>Não comprou</MenuItem>
                    </Select>
                    <FormHelperText>Filtre por período especifico</FormHelperText>
                  </FormControl>,
                isFreeAction: true
              }
            ]}
            options={{
              filtering: filter,
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
              exportButton: true,
              showTitle: false,
              toolbarButtonAlignment: "left",
              exportAllData: true
            }} rows
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper >
      </Box >
    </>
  );
}
