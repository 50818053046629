import { useState } from "react";
import React from 'react'
import './drivers.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableDrivers from '../TableDrivers'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from 'react-input-mask';
import InputLabel from '@mui/material/InputLabel';
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import Modal2 from '@mui/material/Modal';
import warning from '../../image/warning.png'
import trimestral from '../../image/trimestral.png'
import anual from '../../image/anual_.png'
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';

const Driver = () => {
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState(false);
    const [modalAvisoPlan, setModalAvisoPlan] = React.useState(false)
    const [typePlan, setTypePlan] = React.useState('free')
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);

    const handleClose = () => {
        setShow(false)
        setNotification(false)
    };

    const navigate = useNavigate();

    React.useEffect(() => {
        api
            .get("/TodosUsuariosRevenda")
            .then((response) => {
                response.data.map(key => {
                    if (key['db_name'] == currentUser.db) {
                        setTypePlan(key['module'])
                    }
                })
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const handleShow = () => {
        if (typePlan !== 'free') {
            setShow(true)
        } else {
            setModalAvisoPlan(true)
        }
    };

    const submitForm = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        let phone = data.celularDriver.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        api.post("/CadastrarMotorista", {
            name: data.nameDriver,
            cpf: data.cpfDriver,
            password: data.passwordDriver,
            street: data.enderecoDriver == '' ? 'Não Informado' : data.enderecoDriver,
            number: data.numeroDriver == '' ? 0 : Number(data.numeroDriver),
            city: data.cidadeDriver == '' ? 'Não Informado' : data.cidadeDriver,
            state: data.estadoDriver == '' ? 'Não Informado' : data.estadoDriver,
            neighborhood: data.bairroDriver == '' ? 'Não Informado' : data.bairroDriver,
            complement: data.complementoDriver,
            reference_point: data.referenciaDriver == '' ? 'Não Informado' : data.referenciaDriver,
            email: data.emailDriver,
            phone: phone,
            birthdate: data.dataNascimentoDriver == '' ? 'Não Informado' : data.dataNascimentoDriver,
            obs: data.obsDriver,
            reseller_db: currentUser.db,
            db: currentUser.db
        });

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }


    const submitNotification = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const message = data.mesageNotification

        api.post("/NotificarTodosMotoristas", {
            app_id: "2791db33-df64-44ca-917f-bea5142e9d05",
            contents: {
                en: message
            },
            included_segments: ["Subscribed Users"]
        }).then(response => {
            // console.log(response)
        }).catch(err => {
            console.log(err)
        })

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function ModalNotification(props) {
        return (
            <Modal
                show={notification}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Notificação
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <h4>Escreva a notificação para todos entregadores</h4>
                    <form className="inputNotificationMessage" onSubmit={submitNotification}>
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px', width: '100%' }}
                            name='mesageNotification'
                            id='mesageNotification'
                        />
                        <button className="buttonSecundary">
                            Enviar
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }

    const handleMask = (e) => {
        let inputPhone = document.getElementById('celularDriver');
        let phoneMask = e.target.value
        phoneMask = phoneMask.replace(/\D/g, "")
        phoneMask = phoneMask.replace(/^(\d\d)(\d)/g, "($1) $2")
        phoneMask = phoneMask.replace(/(\d{5})(\d)/, "$1-$2")

        inputPhone.value = phoneMask
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <ModalNotification />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo entregador'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo entregador</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Nome *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="nameDriver"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">CPF *</InputLabel>
                                <InputMask className="inputCadastro" mask="999.999.999-**" name="cpfDriver" />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">E-mail *</InputLabel>
                                <input
                                    required
                                    id="formatted-text-mask-input"
                                    name="emailDriver"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Senha *</InputLabel>
                                <input
                                    required
                                    type='password'
                                    id="formatted-text-mask-input"
                                    name="passwordDriver"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Celular *</InputLabel>
                                <input
                                    label="Telefone de contato"
                                    className='inputCadastro'
                                    name="celularDriver"
                                    id="celularDriver"
                                    onChange={handleMask}
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Data de Nascimento</InputLabel>
                                <InputMask className='inputCadastro' mask="99/99/9999" name="dataNascimentoDriver" />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Endereço</InputLabel>
                                <input
                                    name="enderecoDriver"
                                    id="formatted-text-mask-input"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Número</InputLabel>
                                    <input
                                        name="numeroDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Bairro</InputLabel>
                                    <input
                                        name="bairroDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Cidade</InputLabel>
                                    <input
                                        name="cidadeDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Estado</InputLabel>
                                    <input
                                        name="estadoDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"

                                    />
                                </div>
                            </div>

                            <div className="container2itens">
                                <div className="itemDireita">
                                    <InputLabel htmlFor="formatted-text-mask-input">Complemento</InputLabel>
                                    <input
                                        name="complementoDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="itemEsquerda">
                                    <InputLabel htmlFor="formatted-text-mask-input">Ponto de referência</InputLabel>
                                    <input
                                        name="referenciaDriver"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="formatted-text-mask-input">Observação</InputLabel>
                                <Form.Control
                                    className="textAreaForm"
                                    as="textarea"
                                    style={{ height: '100px' }}
                                    name='obsDriver'
                                />
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Cadastrar
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    const style = {
        position: 'relative',
        top: '50%',
        left: '50%',
        width: 607,
        maxHeight: 660,
        borderRadius: "39px !important",
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
    };

    function ModalAvisoPlan(props) {
        return (
            <Modal2
                open={modalAvisoPlan}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='boxModalRaioX'>
                    <div style={{ position: 'absolute', zIndex: 9, right: '-17px', top: '-10px' }}>
                        <button style={{ position: 'relative', background: 'red', color: 'white', fontWeight: 900, borderRadius: 25, width: 35, height: 35 }} onClick={() => setModalAvisoPlan(false)}>X</button>
                    </div>
                    <img src={warning} className='imgModalValidatorPage' />
                    <div className='contectModalValidatorPage'>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 32, color: '#25368C', fontWeight: 900, lineHeight: 1 }}>
                            Essa função não está disponível no plano gratuito
                        </span>
                        <span className='textContectModalValidatorPage' style={{ fontSize: 28, fontWeight: 600, color: 'black', width: '98%', lineHeight: 1, marginTop: 10 }}>
                            Escolha um plano e desbloqueie diversas funções da plataforma
                        </span>
                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end', flexDirection: 'row', marginTop: 10, width: '83%' }}>
                            <img src={trimestral} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/trimestral")} />
                            <img src={anual} style={{ width: '45%', cursor: 'pointer' }} onClick={() => navigate("/atualizar-plano/anual")} />
                        </div>
                    </div>
                </Box>
            </Modal2>
        )
    }

    return (
        <>
            <ModalAvisoPlan />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Entregadores</h5>
                    </div>
                    <div>
                        <Link className="me-2 button" to="/historico-entregadores">Histórico de caixa</Link>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainer tableDriver" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableDrivers />
                </Card>
            </div >
        </>
    )
}

export default Driver