import { useState, useEffect } from "react";
import React from 'react'
import './clients.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import Form from 'react-bootstrap/Form';
import TableClients from '../TableClients'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';

const Clients = () => {
    const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
    const [show, setShow] = useState(false);
    const [typeBusiness, setTypeBusiness] = useState([])
    const [notification, setNotification] = useState(false);
    let address = '';

    const handleClose = () => {
        setShow(false)
        setNotification(false)
    };
    const handleShow = () => {
        setShow(true)
    };

    useEffect(() => {
        api
            .get("/TodasCategoriasComerciais" + '/' + currentUser.db)
            .then((response) => {
                setTypeBusiness(response.data)
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);


    const submitModalConfirm = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let phone = data.celularPhysic.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        let bussinesPhone = data.telefoneBusiness.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        let bussinesCellphone = data.celularBusiness.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        if (data.formClientType == 'fisico') {
            api.post("/CadastrarClienteFisico", {
                name: data.namePhysic,
                number: data.numeroPhysic,
                cep: data.cepPhysic,
                street: data.enderecoPhysic,
                city: data.cidadePhysic,
                state: data.estadoPhysic,
                neighborhood: data.bairroPhysic,
                complement: data.complementoPhysic,
                reference_point: data.referenciaPhysic,
                cpf: data.cpfPhysic,
                email: data.emailPhysic,
                phone: phone,
                birthdate: data.dataNascimentoPhysic,
                db: currentUser.db
            });
            setOpen(true)
            setTimeout(function () {
                window.location.reload(1);
            }, 3000);
        } else {
            api.post("/CadastrarClienteJuridico", {
                commercial_type: Number(data.businessType),
                commercial_id: Number(data.idComercio),
                name: data.nameBusiness,
                razao: data.razaoSocial,
                cnpj: data.cnpj,
                cep: data.cepBusiness,
                street: data.enderecoBusiness,
                number: data.numeroBusiness,
                city: data.cidadeBusiness,
                state: data.estadoBusiness,
                neighborhood: data.bairroBusiness,
                complement: data.complementoBusiness,
                reference_point: data.referenciaBusiness,
                email: data.emailBusiness,
                inscricao_estadual: data.inscricaoSocial,
                inscricao_municipal: data.inscricaoMunicipal,
                phone: bussinesPhone,
                cellphone: bussinesCellphone,
                birthdate: data.dataNascimentoBusiness,
                address: data.enderecoBusiness,
                obs: data.obsBusiness,
                db: currentUser.db
            });
            setOpen(true)
            setTimeout(function () {
                window.location.reload(1);
            }, 3000);
        }
    }


    const clientTypeHandler = e => {
        var type = e.target.value;
        var divPhysic = document.getElementById("formClientPhysic");
        var divBusiness = document.getElementById("formClientBusiness");

        if (type == 'fisico') {
            divPhysic.style.display = "block"
            divBusiness.style.display = "none"

        }
        if (type == 'juridico') {
            divPhysic.style.display = "none"
            divBusiness.style.display = "block"
        }

    }

    const submitNotification = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const message = data.mesageNotification

        api.post("/NotificarTodosClientes", {
            app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
            contents: {
                en: message
            },
            included_segments: ["Subscribed Users"],
            db: currentUser.db
        }).then(response => {
            // console.log(response)
        }).catch(err => {
            console.log(err)
        })

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function ModalNotification(props) {
        return (
            <Modal
                show={notification}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={handleClose}
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Notificação
                    </Modal.Title>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                </Modal.Header>
                <Modal.Body className="cancelBody">
                    <h4>Escreva a notificação para o cliente</h4>
                    <form className="inputNotificationMessage" onSubmit={submitNotification}>
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px', width: '100%' }}
                            name='mesageNotification'
                            id='mesageNotification'
                        />
                        <button className="buttonSecundary">
                            Enviar
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const checkCEPPhysic = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                let estadoApi = '';
                if (data.uf == "AC") {
                    estadoApi = "Acre";
                } else if (data.uf == "AL") {
                    estadoApi = "Alagoas";
                } else if (data.uf == "AP") {
                    estadoApi = "Amapá";
                } else if (data.uf == "AM") {
                    estadoApi = "Amazonas";
                } else if (data.uf == "BA") {
                    estadoApi = "Bahia";
                } else if (data.uf == "CE") {
                    estadoApi = "Ceará";
                } else if (data.uf == "DF") {
                    estadoApi = "Destrito Federal";
                } else if (data.uf == "ES") {
                    estadoApi = "Espírito Santo";
                } else if (data.uf == "GO") {
                    estadoApi = "Goiás";
                } else if (data.uf == "MA") {
                    estadoApi = "Maranhão";
                } else if (data.uf == "MT") {
                    estadoApi = "Mato Grosso";
                } else if (data.uf == "MS") {
                    estadoApi = "Mato Grosso do Sul";
                } else if (data.uf == "MG") {
                    estadoApi = "Minas Gerais";
                } else if (data.uf == "PA") {
                    estadoApi = "Pará";
                } else if (data.uf == "PB") {
                    estadoApi = "Paraíba";
                } else if (data.uf == "PR") {
                    estadoApi = "Paraná";
                } else if (data.uf == "PE") {
                    estadoApi = "Pernambuco";
                } else if (data.uf == "PI") {
                    estadoApi = "Piauí";
                } else if (data.uf == "RJ") {
                    estadoApi = "Rio de Janeiro";
                } else if (data.uf == "RN") {
                    estadoApi = "Rio Grande do Norte";
                } else if (data.uf == "RS") {
                    estadoApi = "Rio Grande do Sul";
                } else if (data.uf == "RO") {
                    estadoApi = "Rondônia";
                } else if (data.uf == "RR") {
                    estadoApi = "Roraima";
                } else if (data.uf == "SC") {
                    estadoApi = "Santa Catarina";
                } else if (data.uf == "SP") {
                    estadoApi = "São Paulo";
                } else if (data.uf == "SE") {
                    estadoApi = "Sergipe";
                } else if (data.uf == "TO") {
                    estadoApi = "Tocantins";
                }

                document.getElementById("enderecoPhysic").value = data.logradouro
                document.getElementById("bairroPhysic").value = data.bairro
                document.getElementById("cidadePhysic").value = data.localidade
                document.getElementById("estadoPhysic").value = estadoApi
            })
    }

    const checkCEPBusiness = (e) => {
        const cep = e.target.value.replace(/\D/g, '');
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then(data => {
                let estadoApi = '';
                if (data.uf == "AC") {
                    estadoApi = "Acre";
                } else if (data.uf == "AL") {
                    estadoApi = "Alagoas";
                } else if (data.uf == "AP") {
                    estadoApi = "Amapá";
                } else if (data.uf == "AM") {
                    estadoApi = "Amazonas";
                } else if (data.uf == "BA") {
                    estadoApi = "Bahia";
                } else if (data.uf == "CE") {
                    estadoApi = "Ceará";
                } else if (data.uf == "DF") {
                    estadoApi = "Destrito Federal";
                } else if (data.uf == "ES") {
                    estadoApi = "Espírito Santo";
                } else if (data.uf == "GO") {
                    estadoApi = "Goiás";
                } else if (data.uf == "MA") {
                    estadoApi = "Maranhão";
                } else if (data.uf == "MT") {
                    estadoApi = "Mato Grosso";
                } else if (data.uf == "MS") {
                    estadoApi = "Mato Grosso do Sul";
                } else if (data.uf == "MG") {
                    estadoApi = "Minas Gerais";
                } else if (data.uf == "PA") {
                    estadoApi = "Pará";
                } else if (data.uf == "PB") {
                    estadoApi = "Paraíba";
                } else if (data.uf == "PR") {
                    estadoApi = "Paraná";
                } else if (data.uf == "PE") {
                    estadoApi = "Pernambuco";
                } else if (data.uf == "PI") {
                    estadoApi = "Piauí";
                } else if (data.uf == "RJ") {
                    estadoApi = "Rio de Janeiro";
                } else if (data.uf == "RN") {
                    estadoApi = "Rio Grande do Norte";
                } else if (data.uf == "RS") {
                    estadoApi = "Rio Grande do Sul";
                } else if (data.uf == "RO") {
                    estadoApi = "Rondônia";
                } else if (data.uf == "RR") {
                    estadoApi = "Roraima";
                } else if (data.uf == "SC") {
                    estadoApi = "Santa Catarina";
                } else if (data.uf == "SP") {
                    estadoApi = "São Paulo";
                } else if (data.uf == "SE") {
                    estadoApi = "Sergipe";
                } else if (data.uf == "TO") {
                    estadoApi = "Tocantins";
                }

                document.getElementsByName("enderecoBusiness")[0].value = data.logradouro
                document.getElementsByName("bairroBusiness")[0].value = data.bairro
                document.getElementsByName("cidadeBusiness")[0].value = data.localidade
                document.getElementsByName("estadoBusiness")[0].value = estadoApi
            })
    }
    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <ModalNotification />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo cliente'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo Cliente</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitModalConfirm}>
                            <label className="labelForm">Tipo de cliente</label>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                className="radioClient"
                                required
                            >
                                <FormControlLabel value="fisico" control={<Radio onClick={clientTypeHandler} />} name="formClientType" label="Cliente Físico" />
                                <FormControlLabel value="juridico" control={<Radio onClick={clientTypeHandler} />} name="formClientType" label="Cliente Jurídico" />
                            </RadioGroup>

                            {/* Cadastro do cliente fisico */}
                            <div className="formClientPhysic" id="formClientPhysic" style={{ display: 'none' }}>
                                <label className="labelForm">Dados do cliente físico</label>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="namePhysic">Nome *</InputLabel>
                                    <input
                                        id="namePhysic"
                                        name="namePhysic"
                                        className="inputCadastro"
                                    />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="celularPhysic">Celular *</InputLabel>
                                    <InputMask className='inputCadastro' id="celularPhysic" mask="(99) 99999-9999" name="celularPhysic" />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="cpfPhysic">CPF</InputLabel>
                                    <InputMask className="inputCadastro" id="cpfPhysic" mask="999.999.999-**" name="cpfPhysic" />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="enderecoPhysic">CEP</InputLabel>
                                    <InputMask className="inputCadastro" id="cepPhysic" mask="99999-999" name="cepPhysic" onBlur={checkCEPPhysic} />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="enderecoPhysic">Endereço *</InputLabel>
                                    <input
                                        name="enderecoPhysic"
                                        id="enderecoPhysic"
                                        className="inputCadastro"
                                    />
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="numeroPhysic">Número *</InputLabel>
                                        <input
                                            name="numeroPhysic"
                                            id="numeroPhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="bairroPhysic">Bairro *</InputLabel>
                                        <input
                                            name="bairroPhysic"
                                            id="bairroPhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="cidadePhysic">Cidade</InputLabel>
                                        <input
                                            name="cidadePhysic"
                                            id="cidadePhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="estadoPhysic">Estado</InputLabel>
                                        <input
                                            name="estadoPhysic"
                                            id="estadoPhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="complementoPhysic">Complemento</InputLabel>
                                        <input
                                            name="complementoPhysic"
                                            id="complementoPhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="referenciaPhysic">Ponto de referência</InputLabel>
                                        <input
                                            name="referenciaPhysic"
                                            id="referenciaPhysic"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="emailPhysic">E-mail</InputLabel>
                                    <input
                                        name="emailPhysic"
                                        id="emailPhysic"
                                        className="inputCadastro"
                                    />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="dataNascimentoPhysic">Data de Nascimento</InputLabel>
                                    <InputMask className='inputCadastro' id="dataNascimentoPhysic" mask="99/99/9999" name="dataNascimentoPhysic" />
                                </div>

                            </div>

                            {/* Cadastro do cliente fisico */}
                            <div className="formClientBusiness" id="formClientBusiness" style={{ display: 'none' }}>
                                <label className="labelForm">Dados do cliente jurídico</label>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">ID do comércio</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="idComercio"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Nome *</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="nameBusiness"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Razão Social</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="razaoSocial"
                                        className="inputCadastro"
                                    />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Telefone *</InputLabel>
                                    <InputMask className='inputCadastro' mask="(99) 9999-9999" name="telefoneBusiness" />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="celularBusiness">Celular</InputLabel>
                                    <InputMask className='inputCadastro' id="celularBusiness" mask="(99) 99999-9999" name="celularBusiness" />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">CNPJ</InputLabel>
                                    <InputMask className="inputCadastro" mask="99.999.999/9999-99" name="cnpj" />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">E-mail *</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="emailBusiness"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Inscrição Social</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="inscricaoSocial"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Inscrição Municipal</InputLabel>
                                    <input
                                        id="formatted-text-mask-input"
                                        name="inscricaoMunicipal"
                                        className="inputCadastro"
                                    />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Tipo de comércio *</InputLabel>
                                    <Form.Select
                                        name='businessType'
                                    >
                                        <option value=''>Selecione um tipo de comércio</option>
                                        {typeBusiness.map((key, value) => {
                                            return <option value={key['id']}>{key['name']}</option>
                                        })}
                                    </Form.Select>
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Data de Nascimento</InputLabel>
                                    <InputMask className='inputCadastro' mask="99/99/9999" name="dataNascimentoBusiness" />
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">CEP</InputLabel>
                                    <InputMask className="inputCadastro" mask="99999-999" name="cepBusiness" onBlur={checkCEPBusiness} />
                                </div>
                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Endereço *</InputLabel>
                                    <input
                                        name="enderecoBusiness"
                                        id="formatted-text-mask-input"
                                        className="inputCadastro"
                                    />
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="formatted-text-mask-input">Número *</InputLabel>
                                        <input
                                            name="numeroBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="formatted-text-mask-input">Bairro *</InputLabel>
                                        <input
                                            name="bairroBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="formatted-text-mask-input">Cidade</InputLabel>
                                        <input
                                            name="cidadeBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"

                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="formatted-text-mask-input">Estado</InputLabel>
                                        <input
                                            name="estadoBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="container2itens">
                                    <div className="itemDireita">
                                        <InputLabel htmlFor="formatted-text-mask-input">Complemento</InputLabel>
                                        <input
                                            name="complementoBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"
                                        />
                                    </div>
                                    <div className="itemEsquerda">
                                        <InputLabel htmlFor="formatted-text-mask-input">Ponto de referência</InputLabel>
                                        <input
                                            name="referenciaBusiness"
                                            id="formatted-text-mask-input"
                                            className="inputCadastro"
                                        />
                                    </div>
                                </div>

                                <div className="divCadastro">
                                    <InputLabel htmlFor="formatted-text-mask-input">Observação</InputLabel>
                                    <Form.Control
                                        className="textAreaForm"
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        name='obsBusiness'
                                    />
                                </div>
                            </div>


                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Cadastrar
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h5>Todos clientes</h5>
                    </div>
                    <div className="divButtonToTitle">
                        {/* <Button variant="primary" onClick={() => { setNotification(true) }} className="me-2 button">
                            Notificar clientes
                        </Button> */}
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div>
                </div>
                <Card className="cardContainerClient" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableClients />
                </Card>
            </div >
        </>
    )
}

export default Clients