import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableClientePhysic.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineWhatsApp, AiOutlineMail, AiOutlineBell, AiOutlineShoppingCart } from 'react-icons/ai'
import { FiPhoneCall } from 'react-icons/fi'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { Checkbox, Select, MenuItem } from '@material-ui/core'
import TableFilter from '../financeiro'
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'dataUltimaCompra',
    numeric: false,
    disablePadding: false,
    label: 'Data da última compra',
  },
  {
    id: 'valorUltimaCompra',
    numeric: false,
    disablePadding: false,
    label: 'Valor da última compra',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

const headOrdered = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Pedido',
  },
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Data do pedido',
  },
  {
    id: 'saleChannel',
    numeric: false,
    disablePadding: false,
    label: 'Canal de venda',
  },
  {
    id: 'payment',
    numeric: false,
    disablePadding: false,
    label: 'Forma de pagamento',
  },
  {
    id: 'vasilhame',
    numeric: false,
    disablePadding: false,
    label: 'Vasilhame',
  },
  {
    id: 'obs',
    numeric: false,
    disablePadding: false,
    label: 'Observação do pedido',
  }
];

function EnhancedTableHeadDriver(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headOrdered.map((headDriver) => (
          <TableCell
            key={headDriver.id}
            align={headDriver.numeric ? 'right' : 'left'}
            padding={headDriver.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headDriver.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headDriver.id}
              direction={orderBy === headDriver.id ? order : 'asc'}
              onClick={createSortHandler(headDriver.id)}
              className="colunmLabel"
            >
              {headDriver.label}
              {orderBy === headDriver.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadDriver.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [clientPhysic, setClientPhysic] = React.useState([]);
  const [clientBusiness, setClientBusiness] = React.useState([]);
  const [physicRows, setPhysicRows] = React.useState([]);
  const [businessRows, setBusinessRows] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [text, setText] = React.useState();
  const [modalCancel, setModalCancel] = React.useState(false);
  const [type, setType] = React.useState();
  const [arrayClient, setArrayClient] = React.useState([]);
  const [historyOrderModal, setHistoryOrderModal] = React.useState(false);
  const [ordered, setOrdered] = React.useState([]);
  const [payment, setPayment] = React.useState([]);
  const [saleChannel, setSaleChannel] = React.useState([]);
  const [notification, setNotification] = React.useState(false);
  const [whatsModal, setWhatsModal] = React.useState(false)
  const [filter, setFilter] = React.useState(true)
  const { currentUser } = useSelector(rootReducer => rootReducer.userReducer);
  let result = ''
  let valorResult = ''

  React.useEffect(() => {
    api
      .get("/TodosClientesFisicos" + '/' + currentUser.db)
      .then((response) => {
        setClientPhysic(response.data);
        setPhysicRows(response.data.map(key => ({
          id: key['id'], cliente: key['name'], birthdate: key['birthdate'], typeClient: 'fisico'
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosClientesJuridicos" + '/' + currentUser.db)
      .then((response) => {
        setClientBusiness(response.data)
        setBusinessRows(response.data.map(key => ({
          id: key['id'], cliente: key['name'], birthdate: key['birthdate'], typeClient: 'juridico'
        })))
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  let db = 'clubeApi';
  if (currentUser !== undefined || currentUser !== null) {
    db = currentUser.db
  }

  React.useEffect(() => {
    api
      .get("/TodosPedidos2" + '/' + currentUser.db)
      .then((response) => {
        setOrdered(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodasFormasPagamentos" + '/' + currentUser.db)
      .then((response) => {
        setPayment(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  React.useEffect(() => {
    api
      .get("/TodosCanaisVendas" + '/' + currentUser.db)
      .then((response) => {
        setSaleChannel(response.data)
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
    setHistoryOrderModal(false)
    setNotification(false)
    setWhatsModal(false)
    setText("");
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);
    let phone = data.celularPhysicEdit.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    const dados = {
      id: idModal,
      name: data.namePhysicEdit,
      number: data.numeroPhysicEdit,
      street: data.enderecoPhysicEdit,
      city: data.cidadePhysicEdit,
      state: data.estadoPhysicEdit,
      neighborhood: data.bairroPhysicEdit,
      complement: data.complementoPhysicEdit,
      reference_point: data.referenciaPhysicEdit,
      cpf: data.cpfPhysicEdit,
      email: data.emailPhysicEdit,
      phone: phone,
      birthdate: data.dataNascimentoPhysicEdit,
      db: currentUser.db
    }

    api.put("/AtualizarCadastroFisico", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const submitNotification = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageNotification
    let player_id = ''

    if (type == 'fisico') {
      clientPhysic.map(key => {
        if (key['id'] == idModal) {
          player_id = key['player_id']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (key['id'] == idModal) {
          player_id = key['player_id']
        }
      })
    }

    api.post("/Notificar", {
      app_id: "0eab63cd-71a3-48dc-b40c-7b1849c44f74",
      contents: {
        en: message
      },
      include_player_ids: [player_id],
      db: currentUser.db
    }).then(response => {
      // console.log(response)
    }).catch(err => {
      console.log(err)
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalNotification(props) {
    return (
      <Modal
        show={notification}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Notificação
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a notificação para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitNotification}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageNotification'
              id='mesageNotification'
              value="Parabéns a nossa equipe deseja muitas felicidades nessa data especial 🥳🥳"
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const submitMessageWhats = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const message = data.mesageWhats
    let whats = ''

    if (type == 'fisico') {
      clientPhysic.map(key => {
        if (key['id'] == idModal) {
          whats = key['phone']
        }
      })
    } else {
      clientBusiness.map(key => {
        if (key['id'] == idModal) {
          whats = key['phone']
        }
      })
    }

    let whatsPhone = whats.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");

    axios
      .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
        {
          number: "55" + whatsPhone + "@s.whatsapp.net",
          textMessage: {
            text: message
          },
          delay: 1200
        }, {
        headers: {
          'Content-Type': 'application/json',
          'apikey': 'B6D711FCDE4D4FD5936544120E713976'
        }
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        let ddd = String(whatsPhone[0]) + String(whatsPhone[1]);
        whatsPhone = whatsPhone.slice(3);

        axios
          .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
            {
              number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
              textMessage: {
                text: message
              },
              delay: 1200
            }, {
            headers: {
              'Content-Type': 'application/json',
              'apikey': 'B6D711FCDE4D4FD5936544120E713976'
            }
          })
          .then((response) => {
            // console.log(response);
          })
          .catch((err) => {
            let ddd = String(whatsPhone[0]) + String(whatsPhone[1]);
            whatsPhone = whatsPhone.slice(3);

            axios
              .post(`https://evolution.clubedorevendedordegas.com.br/message/sendText/${currentUser.db}`,
                {
                  number: "55" + ddd + whatsPhone + "@s.whatsapp.net",
                  textMessage: {
                    text: message
                  },
                  delay: 1200
                }, {
                headers: {
                  'Content-Type': 'application/json',
                  'apikey': 'B6D711FCDE4D4FD5936544120E713976'
                }
              })
              .then((response) => {
                // console.log(response);
              })
              .catch((err) => {
                console.log(err);
              })
          })
      })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalWhats(props) {
    return (
      <Modal
        show={whatsModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Mensagem whatsapp
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Escreva a mensagem para o cliente</h4>
          <form className="inputNotificationMessage" onSubmit={submitMessageWhats}>
            <Form.Control
              as="textarea"
              style={{ height: '100px', width: '100%' }}
              name='mesageWhats'
              id='mesageWhats'
              value="Parabéns a nossa equipe deseja muitas felicidades nessa data especial 🥳🥳"
            />
            <button className="buttonSecundary">
              Enviar
            </button>
          </form>
        </Modal.Body>
      </Modal>
    )
  }

  const handleCancelOrdered = async (e) => {
    if (type == 'fisico') {
      api.delete('/DeletarClienteFisico/' + idModal + '/' + currentUser.db)
    } else {
      api.delete('/DeletarClienteJuridico/' + idModal + '/' + currentUser.db)
    }

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Cliente", field: "cliente" },
    { title: "Data de aniversário", field: "birthdate" },
  ]
  const handleChange = () => {
    setFilter(!filter)
  }

  var rows = [];

  physicRows.map(key => {
    if (key['birthdate'] !== null) {
      var date = key['birthdate'].split('/');
      var dateNow = new Date();
      var birthdayMonth = date[1];
      var monthNow = dateNow.getMonth() + 1;

      if (monthNow == birthdayMonth) {
        rows.push({ id: key['id'], cliente: key['cliente'], birthdate: key['birthdate'], typeClient: key['typeClient'] });
      }
    }
  })

  businessRows.map(key => {
    if (key['birthdate'] !== null) {
      var date = key['birthdate'].split('/');
      var dateNow = new Date();
      var birthdayMonth = date[1];
      var monthNow = dateNow.getMonth() + 1;

      if (monthNow == birthdayMonth) {
        rows.push({ id: key['id'], cliente: key['cliente'], birthdate: key['birthdate'], typeClient: key['typeClient'] });
      }
    }
  })

  rows.forEach(item => {
    if (item.birthdate !== null) {
      var date = item.birthdate.split('/');
      item.birthdate = date['0'] + "/" + date['1'];
    }
    if (item.typeClient == 'fisico') {
      clientPhysic.map(key => {
        if (key['id'] == item.id) {
          if (key['date_last_order'] !== null) {
            item.dataUltimaCompra = key['date_last_order']
          }
        }
      })
    } else {
      clientBusiness.map(key => {
        if (key['id'] == item.id) {
          if (key['date_last_order'] !== null) {
            item.dataUltimaCompra = key['date_last_order']
          }
        }
      })
    }
  })
  return (
    <>
      <Loading />
      <ModalNotification />
      <ModalWhats />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title=""
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineWhatsApp className='perfilAction' />,
                tooltip: 'Whatsapp',
                onClick: (event, rowData) => {
                  setWhatsModal(true)
                  setIdModal(rowData.id)
                  setType(rowData.typeClient)
                }
              },
              {
                icon: () => <AiOutlineBell className='perfilAction' />,
                tooltip: 'Notificar',
                onClick: (event, rowData) => {
                  setNotification(true)
                  setIdModal(rowData.id)
                  setType(rowData.typeClient)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }} rows
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />

        </Paper>
      </Box>
    </>
  );
}
